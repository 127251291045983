<template>
  <b-card class="mt-1">
    <b-card-title class="b-card-title mb-1">{{ title }}</b-card-title>
    <div>
      <b-table
        :items="docList"
        :fields="fields"
        table-variant="light"
        hover
        small
        thead-class="d-none"
        table-class="no-bottom-margin"
      >
        <template #cell(docName)="data">
          <div>
            {{ formatDocumentTitle(data.item) }}
          </div>
        </template>
        <template #cell(documentGuid)="data">
         <b-button variant="link" class="shadow-none" @click="showApplication(data.item.documentGuid)">View</b-button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import documentFormatting from "./DocumentFormatMixin";
import {mapGetters, mapMutations } from "vuex";

export default {
  name: "DocumentsPersonalProfileCard",
  mixins: [documentFormatting],
  props: {
    title: {
      type: String,
      required: true,
    },
    personalProfileDocList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("applicant", ['applications', 'intentions','miscDocumentRules']),
  },
  data() {
    return {
      fields: [
        {
          key: "docName",
          label: "Name",
        },
        {
          key: "documentGuid",
          label: "View",
          tdClass: "view",
        },
      ],
      docList: [],
    };
  },
  mounted() {
    this.docList = this.personalProfileDocList;
  },
  methods: {
    ...mapMutations("personalProfile", {
      setSelectedPersonalProfileDocType: "SET_SELECTED_PERSONALPROFILE_DOCTYPE",
    }),

    showApplication(documentGuid) {
      let type = this.getPersonalProfileModalType(documentGuid);
      if(type === 'ADMS') {
        this.$store.dispatch("applicant/selectPersonalProfileModalType", "ADMS-PROFILE");
      } else if (type === 'EDUC') {
        this.$store.dispatch("applicant/selectPersonalProfileModalType", "EDUC-PROFILE");
      }
      this.$bvModal.show("PersonalProfileModal");
    },
    getPersonalProfileModalType(docGuid) {
      let personalProfileDoc = this.personalProfileDocList.find((ppDoc) => ppDoc.documentGuid === docGuid);
      this.setSelectedPersonalProfileDocType(personalProfileDoc.miscDocType);
      return personalProfileDoc?.applicationType;
    },
    
  },
};
</script>

<style scoped>
.b-card-title {
  font-size: large;
  font-weight: bold;
}
</style>