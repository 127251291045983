<template>
  <div>
    <div>
      <b-navbar toggleable type="dark" variant="dark" class="navbar-static-top bottom-marg" data-test="app-header">
        <b-navbar-brand>
          <img src="../assets/ubc-header-logo.png" class="responsive-image">
        </b-navbar-brand>
      </b-navbar>
    </div>
    <div :style="myStyle">
      <label class="text-white">Applicant Service Center</label>
    </div>
    <div class="w-50">
      <label label-size="lg"> You have secured your spot at UBC!</label>
    </div>
    <b-card class="text-left border border-dark">
<!--      FIXME-->
      <div class=" text-black">
        Thank you for choosing the [<b>insert program name</b>] at UBC [<b>insert location</b>].
        <p></p>
        You have successfully accepted your offer of admission and paid your
        [<b>insert $ amount</b>] acceptance deposit to secure your spot at UBC. Please
        note the CMB ID number below for future reference.
        <p></p>
        To learn more about the next steps on your journey to UBC, visit the
        Undergraduate Programs and Admissions website at you.ubc.ca/admitted.
      </div>
      <p></p>

      <div align="left">
        <b>Here are your transaction details :</b>
        <p>
          CMB ID : {{ this.cmbid }}
          <br/>
          Message: {{ this.message }}
          <br/>
          Status Code: <b>{{ this.statusCode }}</b>
        </p>
      </div>
    </b-card>
    <p></p>
    <table class="w-100">
      <tr>
        <td class="w-50" valign="top"><b-button varient="secondary" class="btn-size" @click="returnToASC">Return to ASC</b-button></td>
        <td class="w-50" valign="top"><b-card class="text-left border border-dark">
          <b>Questions?</b><br/>
          <ContactQA/>
        </b-card></td>
      </tr>
    </table>
  </div>

</template>

<script>
import ContactQA from './ContactQA.vue';

export default {
  name: "ReAdmissionConfirmation",
  components: {
    ContactQA
  },

  data() {
    return {
      message: "Approved",
      cmbid: "1957XXXX",
      statusCode: "0",
      myStyle: {
        backgroundColor: "#002145"
      }
    }
  },
  methods: {
    returnToASC() {
    },
  }
}
</script>

<style lang="css" scoped>
.navbar.navbar-dark.bg-dark {
  background-color: #002145 !important;
}

.responsive-image {
  max-width: 565px;
  width: 100%;
  height: auto;
}

.bottom-marg {
  margin-bottom: 20px;
}
</style>