<template>
<div>
  <b-container fluid="true">
    <page-load-overlay :show="showOverlay">
      <h6>Payment Summary</h6>
      <div>
        <b-table
            striped
            bordered
            hover
            :items="feeItems"
            :fields="feeFields"
        >
        </b-table>
        <table class="fee_total">
          <tr>
            <td><b>Total:</b></td>
            <td>{{ totalAmountDue }}</td>
          </tr>
        </table>
      </div>
    </page-load-overlay>
  </b-container>
  <PaymentRequestToMultipleGLAccountForm 
    ref="reAdmissionPaymentRequestForm"
    v-if="getReAdmissionPaymentRequest.dppPaymentRequest"
    :paymentRequestForm="getReAdmissionPaymentRequest.dppPaymentRequest"       
    :reAdmissionApplicationFeePayReq="getReAdmissionPaymentRequest.reAdmissionApplicationFeePayReq"
    :personalProfileFeePayReq="getReAdmissionPaymentRequest.personalProfileFeePayReq"
    :reAdmissionProgramFeePayReq="getReAdmissionPaymentRequest.reAdmissionProgramFeePayReq"
    :paymentUrl="paymentUrl"
    />
</div>
</template>

<script>
import {mapGetters} from 'vuex';
import useVuelidate from '@vuelidate/core';
import PaymentRequestToMultipleGLAccountForm from './PaymentRequestToMultipleGLAccountForm.vue';
import PageLoadOverlay from './PageLoadOverlay.vue';

export default {
  name: "ReAdmissionPaymentSummary", 
  components: {
    PaymentRequestToMultipleGLAccountForm,
    PageLoadOverlay,
   },
  data() {
    return {
      errors: null,
      paymentUrl: "#",
    };
  },
  computed: {
    ...mapGetters("payment", ['getReAdmissionPaymentRequest', 'isLoading']),
    ...mapGetters("reAdmission", ['isBusy', 'readmissionFee', 'currentApplication', 'readmissionProgramFees']),
    ...mapGetters("personalProfile", ['personalProfileFees']),
    isError() {
      return this.savingError != "";
    },
    showOverlay() {
      return this.isBusy;
    },
    feeFields() {
      return [
        {key: "item", label: "Item"},
        {key: "amount_due", label: "Amount Due"}
      ];
    },
    feeItems() {
      let results = [];
      if (this.personalProfileFees?.toPayment){
        this.personalProfileFees.fees.forEach((el, i) => {
          results.push({
            item: el.label,
            amount_due: this.formatAmount(el.amount),
          });
        });
      }
      if (this.readmissionProgramFees?.length > 0) {
        this.readmissionProgramFees.forEach((el, i) => {
          results.push({
            item: el.label,
            amount_due: this.formatAmount(el.amount),
          });
        });
      }
      if (this.readmissionFee) {
        results.push({
          item: this.readmissionFee.label,
          amount_due: this.formatAmount(this.readmissionFee.amount),
        });
      }
      return results;
    },
    totalAmountDue() {
      let totalAmount = 0;
      for (let i = 0; i < this.feeItems.length; i++) {
        totalAmount = totalAmount + parseFloat(this.feeItems[i].amount_due);
      }
      return this.formatAmount(totalAmount);
    },
    reAdmissionApplicationFeeAmount() {      
      if(this.readmissionFee.amount) {
        return this.formatAmount(this.readmissionFee.amount)
      }
      return 0;
    },
    personalProfileFeeAmount() {      
      let results = [];      
      if (this.personalProfileFees?.toPayment) {
        this.personalProfileFees.fees.forEach((el, i) => {
          results.push(this.formatAmount(el.amount));
        });
        return results.reduce((partialSum, a) => partialSum + a);
      }
      return 0;
    },
    reAdmissionProgramFeeAmount() {
      let results = [];
      if (this.readmissionProgramFees?.length > 0) {
        this.readmissionProgramFees.forEach((el, i) => {
          results.push(this.formatAmount(el.amount));
        });
        return results.reduce((partialSum, a) => partialSum + a);
      }
      return 0;
    }
  },
  created() {
    this.$store.dispatch("reAdmission/loadReadmissionFee" );
    this.$store
        .dispatch("reAdmission/loadReadmissionProgramFees", {
          procArea: this.currentApplication?.admissProcArea,
          applEnteredDate: this.currentApplication?.applEnteredDate
        });

    this.getUpayPaymentUrl();
  },
  setup: () => ({v$: useVuelidate()}),
  methods: {
    formValidation() {
      return true;
    },
    formatAmount(a){
      return parseFloat(a).toFixed(2);
    },
    toPayment() {      
      this.$store
          .dispatch("payment/reAdmissionPayment", {                  
            applEnteredDate: this.currentApplication?.applEnteredDate,
            procArea: this.currentApplication?.admissProcArea,
          })
          .then(() => {
            if(this.getReAdmissionPaymentRequest?.dppPaymentRequest?.paymentReqNo) {
              this.$refs.reAdmissionPaymentRequestForm.submit();
              this.close();
             }
          });
    },
    async getUpayPaymentUrl() {
      await this.$store.dispatch('applicant/getExternalLinkPromise', {
        linkType: 'UPAY'
      })
          .then((response) => {
            const url = response;
            if (url) {
              this.paymentUrl = url;
            }
          });
    },
  },

}
</script>

<style scoped>

>>> .fee_total {
  width: 65%;
  margin-right: 0px;
  margin-left: auto;
}
>>> table.fee_total td {
  border:solid 1px;
  text-align: right;
  padding-right: 5px;
}

</style>