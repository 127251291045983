<template>
  <div style="text-align:left;margin-left:20px;margin-bottom:30px">
    <div v-if="displayPaid === true">
      <span style="font-size:24px;font-weight:bold">
        You've secured your spot at UBC!
      </span>
    </div>
    <div v-if="displayPaid === false">
      <span style="font-size:24px;font-weight:bold">
        Unable to process your payment
      </span>
    </div>
    <div v-if="displayOffer" style="margin-top:20px">
      Thank you for choosing the {{displayOffer.degreeProgram}} program at {{displayOffer.campus}}!
    </div>
    <div v-if="displayPaid === true" style="margin-top:20px">
      <div>
        You have successfully accepted your offer of admission to secure your spot at UBC.
      </div>
    </div>
    <div v-if="displayPaid === true" style="margin-top:20px">
      To learn more about the next steps on your journey to UBC, visit the Undergraduate
      Programs and Admissions website at 
      <a target="_blank" href="https://you.ubc.ca/admitted"><b>you.ubc.ca/admitted</b></a>
    </div>
    <div v-if="displayPaid === false" style="margin-top:20px">
      Unfortunately we were not able to process your acceptance deposit.
      Please note the error message below, check your payment method and payment provider,
      and try again.
    </div>
  </div>
</template>
<script>
import HaveQuestionsWidget from './HaveQuestionsWidget.vue';
export default {
  name: 'ConfirmDeclineOfferWidget',
  components: {
    HaveQuestionsWidget
  },
  props: {
    offer: Object,
    paid: Boolean,
    studentID: String,
  },
  data () {
    return {
      displayOffer: Object,
      displayPaid: true
    }
  },
  created() {
    this.displayOffer = this.offer;
    this.displayPaid = this.paid;
  },
  mounted() {
    this.displayOffer = this.offer;
    this.displayPaid = this.paid;
    this.$emit('updateIsProcessing', false);
  },
  watch: {
    offer: function(newOffer) {
      this.displayOffer = newOffer;
      this.displayPaid = newOffer.paid;
    }
  },
  methods: {
    close() {
      this.$emit('completed');
    }
  },
}
</script>