import AdmissionsConstants from "./AdmissionsConstants"

class PersonalProfileCriteria {
    #admsIntentionCriteria;
    #educIntentionCriteria;
    #admsApplEnteredDate;
    #educApplEnteredDate;

    constructor(intentions = [], documentSummaries = [], miscDocumentRules = []) {
        this.#admsIntentionCriteria = [];
        this.#educIntentionCriteria = [];
        documentSummaries.filter(doc => doc.miscDocument)
            .forEach(doc => {
                intentions.forEach(intention => {

                    let miscRule = miscDocumentRules.find(rule => {
                        return rule.degreeProgramCd === intention.degreeId
                            && rule.documentType === doc.miscDocument
                            && rule.applicationType === intention.applicationType
                            && rule.processingArea === intention.processingArea
                    });
                    if (miscRule) {

                        if (intention && intention.processingArea === AdmissionsConstants.PROCESSING_AREA_ADMISSIONS) {
                            this.#admsIntentionCriteria.push(new IntentionCriteria(intention, miscRule.documentType));
                            this.#admsApplEnteredDate = intention.applEnteredDate;
                        } else if (intention && intention.processingArea === AdmissionsConstants.PROCESSING_AREA_EDUCATION) {
                            this.#educIntentionCriteria.push(new IntentionCriteria(intention, miscRule.documentType));
                            this.#educApplEnteredDate = intention.applEnteredDate;
                        }
                    }
                })
            });
    }

    toADMSQueryParams(selectedPersonalProfileDocType) {
        return {
            processingArea: AdmissionsConstants.PROCESSING_AREA_ADMISSIONS,
            applEnteredDate: this.#admsApplEnteredDate,
            selectedPersonalProfileDocType: selectedPersonalProfileDocType,
            ...this.#addIntentionsToParams(this.#admsIntentionCriteria)
        };
    }

    toEDUCQueryParams() {
        return {
            processingArea: AdmissionsConstants.PROCESSING_AREA_EDUCATION,
            applEnteredDate: this.#educApplEnteredDate,
            ...this.#addIntentionsToParams(this.#educIntentionCriteria)
        }
    }

    #addIntentionsToParams(intentionCriteria) {
        let intention1 = {}, intention2 = {};
        if (intentionCriteria.length > 0) {
            intention1 = intentionCriteria[0].toQueryParam("intention1");
        }
        if (intentionCriteria.length > 1) {
            intention2 = intentionCriteria[1].toQueryParam("intention2");
        }
        return {...intention1, ...intention2};
    }

    applEnteredDate(processingArea) {
        if (processingArea === AdmissionsConstants.PROCESSING_AREA_ADMISSIONS) {
            return this.#admsApplEnteredDate;
        }
        if (processingArea === AdmissionsConstants.PROCESSING_AREA_EDUCATION) {
            return this.#educApplEnteredDate;
        }
        return null;
    }
}

class IntentionCriteria {

    constructor(intention, documentType) {
        this.degreeProgramCode = intention.degreeId;
        this.applicationType = intention.applicationType;
        this.basisOfAdmission = intention.basisOfAdmission;
        this.processingArea = intention.processingArea;
        this.applEnteredDate = intention.applEnteredDate;
        this.session = intention.session;
        this.documentType = documentType;
    }

    toQueryParam(prefix) {
        const obj = {}
        obj[prefix + ".degreeProgramCode"] = this.degreeProgramCode;
        obj[prefix + ".applicationType"] = this.applicationType;
        obj[prefix + ".basisOfAdmission"] = this.basisOfAdmission;
        obj[prefix + ".documentType"] = this.documentType;
        obj[prefix + ".session"] = this.session;
        return obj;
    }

}

export default PersonalProfileCriteria;
