import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import Application from '@/components/Application'
import Documents from '@/components/Documents'
import Apply from '@/components/Apply'
import MainPageContainer from '@/components/MainPageContainer.vue'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: process.env.BASE_URL, component: MainPageContainer, meta: { publicPage: false } },
        { path: '/application', component: Application, meta: { publicPage: false } },
        { path: '/documents', component: Documents, meta: { publicPage: false } },
        { path: '/apply', component: Apply, meta: { publicPage: true } },
        { path: '*', redirect: process.env.BASE_URL, meta: { publicPage: false } }
    ]
});

export default router;