<template>
  <section>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle="'program-' + index" block>
        <ChevronDown class="when-opened" aria-hidden="true"/>
        <ChevronRight class="when-closed" aria-hidden="true"/>
        <span>{{index == 0 ? 'First Program Selection' : 'Second Program Selection'}}</span>
      </b-card-header>

      <b-collapse :id="'program-' + index" :visible="true">
        <b-card-body>
          <b-row class="listRow">
            <b-col cols="3">Campus <span class="text-danger">*</span> </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="campusOptions"
                  :value="choice.campus"
                  @input="(value) => inputHandler(value, 'campus')"
                  :class="{ 'is-invalid': v$.$dirty && v$.choice.campus.$error }"
              ></b-form-select>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.choice.campus.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="listRow" v-if="choice.campus === 'UBC'">
            <b-col cols="3">Program <span class="text-danger">*</span> </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="ubcDegreeListings"
                  :value="choice.degreeChoiceCode"
                  @input="(value) => inputHandler(value, 'degreeChoiceCode')"
                  :class="{ 'is-invalid': v$.$dirty && v$.choice.degreeChoiceCode.$error }"
              ></b-form-select>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.choice.degreeChoiceCode.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>
          <b-row class="listRow" v-else-if="choice.campus === 'UBCO'">
            <b-col cols="3">Program <span class="text-danger">*</span> </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="okanDegreeListings"
                  :value="choice.degreeChoiceCode"
                  @input="(value) => inputHandler(value, 'degreeChoiceCode')"
                  :class="{ 'is-invalid': v$.$dirty && v$.choice.degreeChoiceCode.$error }"
              ></b-form-select>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.choice.degreeChoiceCode.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>
          <b-row class="listRow" v-show="showSpecializationDropdown">
            <b-col cols="3">Specialization </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="degreeSpecializations"
                  :value="choice.degreeOptionChoiceCode"
                  @input="(value) => inputHandler(value, 'degreeOptionChoiceCode')"
              ></b-form-select>
            </b-col>
          </b-row>

          <b-row class="listRow" v-show="showSpecializationDropdown">
            <b-col cols="3">Specialization 2 </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="degreeSpecializations"
                  :value="choice.degreeOptionChoiceCode2"
                  @input="(value) => inputHandler(value, 'degreeOptionChoiceCode2')"
              ></b-form-select>
            </b-col>
          </b-row>

          <b-row class="listRow" v-show="showVersionDropdown()">
            <b-col cols="3">Version </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="programVersions"
                  :value="choice.programVersion"
                  @input="(value) => inputHandler(value, 'programVersion')"
              ></b-form-select>
            </b-col>
          </b-row>

          <b-row class="listRow">
            <b-col cols="3">Start Date <span class="text-danger">*</span> </b-col>
            <b-col cols="6">
              <b-form-select
                  :options="sessions"
                  :value="choice.session"
                  @input="(value) => inputHandler(value, 'session')"
                  :class="{ 'is-invalid': v$.$dirty && v$.choice.session.$error }"
              ></b-form-select>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.choice.session.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>

    </b-card>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import _ from "lodash";
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: "ReAdmissionProgramSelectionForm",
  props: ["value", "index", "title"],
  components: {
    ChevronRight,
    ChevronDown,
  },
  data() {
    return {
      campusOptions: [
        { value: "", text: "- Please Choose -"},
        { value: "UBC", text: "UBC Vancouver" },
        { value: "UBCO", text: "UBC Okanagan" },
      ],
      degreeSpecializations: [],
      programVersions: [],
      choice: this.value,
      showSpecializationDropdown: false,
    };
  },
  computed: {
    ...mapGetters("reAdmission", ['programSelection']),
    ubcDegreeListings(){
      return this.getDegreeListings("UBC");
    },
    okanDegreeListings() {
      return this.getDegreeListings("UBCO");
    },
    sessions() {
      return this.getSessions();
    },
  },
  beforeMount() {
    this.restoreFormOptions();
  },
  validations: {
    choice: {
      campus: {required},
      degreeChoiceCode: {required},
      session: {required},
    }
  },
  setup: () => ({v$: useVuelidate()}),
  watch: {
    value: {
      handler: function(newValue) {
        this.choice = newValue;
        this.restoreFormOptions();
      },
      deep: true,
    }
  },
  methods: {
    initProgramSelectionData() {
      this.$store.dispatch("reAdmission/loadProgramSelectionData" );
    },
    getDegreeListings(campus) {
      if (!this.programSelection.degreePrograms) {
        return [];
      }
      let all = this.programSelection.degreePrograms;
      if (this.index === "0") {
        all = all.filter((it) => it.showFirstProg === true);
      }
      if (this.index === "1") {
        all = all.filter((it) => it.showSecondProg === true);
      }
      let campusRows = all.filter( (it) => it.campusLocationCode.trim() === campus );
      if (campusRows.length === 0){
        return [];
      }
      let result = [];
      result.push({value: "", text: "- Please Choose -"});
      for (const obj of campusRows) {
        let row = {};
        row.value = obj.id;
        row.text = obj.officialCredentialName.trim();
        result.push(row);
      }
      let sortedResult = _.sortBy( result, "text" );

      return sortedResult;
    },
    getSessions() {
      let result = [];
      result.push({value: "", text: "- Please Choose -"});
      if (!this.programSelection.sessions){
        return result;
      }
      let raw = this.programSelection.sessions;
      for (const obj of raw) {
        let row = {};
        row.value = obj.id.year + obj.id.code + obj.id.term;
        row.text = obj.description.trim();
        result.push(row);
      }
      let sortedResult = _.sortBy( result, "text" );
      return sortedResult;
    },
    setDegreeSpecializations(degreeChoiceCode) {
      let result = [];
      result.push({value: "", text: "- Please Choose -"});
      let raw = this.programSelection.degreeSpecializations;
      if (!raw || !degreeChoiceCode){
        return result;
      }
      for (const obj of raw) {
        let row = {};
        if (degreeChoiceCode.trim() == obj.degreeCode.trim()){
          row.value = obj.id;
          row.text = obj.transcriptSpecText.trim();
          result.push(row);
        }
      }
      let sortedResult = _.sortBy( result, "text" );
      this.degreeSpecializations = sortedResult;
      this.showSpecializationDropdown = sortedResult.length > 1;
    },
    setProgramVersions(degreeChoiceCode) {
      let result = [];
      result.push({value: "", text: "- Please Choose -"});
      let raw = this.programSelection.programVersions;
      if (!raw || !degreeChoiceCode) {
        return result;
      }
      for (const obj of raw) {
        if (degreeChoiceCode.trim() == obj.degreeProgram.trim()){
          result.push({ value: obj.version, text: obj.description.trim()});
        }
      }
      let sortedResult = _.sortBy( result, "text" );
      this.programVersions = sortedResult;
    },
    restoreFormOptions(){
      this.setDegreeSpecializations(this.choice.degreeChoiceCode);
      this.setProgramVersions(this.choice.degreeChoiceCode);
    },
    showVersionDropdown() {
      return (this.choice.campus && this.choice.degreeChoiceCode && this.programVersions.length > 1);
    },
    inputHandler(value, key) {
      if (key === 'degreeChoiceCode') {
        this.setDegreeSpecializations(value);
        this.setProgramVersions(value);
      }
      this.$emit("input", {id: this.index, key: key, val: value});
    },
  },

}
</script>

<style scoped>
>>> .listRow {
  margin-top: 10px;
  margin-bottom: 6px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>