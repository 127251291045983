<template>
  <b-modal
    id="AdmissionLetterModal"
    title="Admission Letters"
    centered
    cancel-title="Close"
    cancel-variant="outline-secondary"
    scrollable
  >
    <p>
      Download your most recent admission letter to find details of your offer
      and your acceptance deadline.
    </p>

    <b-table
      class="custom-table"
      :items="admissionLetters"
      :fields="tableFields"
      bordered
    >
      <template #cell(offerLetterPath)="data">
        <div>
          <a
            :href="downloadAdmissionLetter(data.item.offerLetterPath)"
            target="_blank"
            >Download Letter (PDF)</a
          >
        </div>
      </template>
    </b-table>
    <template #modal-footer="supplementalFooter"> </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdmissionLetterModal",

  data() {
    return {
      tableFields: [
        {
          key: "letterPrintedDate",
          label: "Printed Date"
        },
        {
          key: "offerLetterPath",
          label: "Letter",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("applicant", ["intentions"]),
    admissionLetters() {
      const letter = this.intentions?.find(i => i.offerLetterPath);
      return [letter];
    },
  },
  methods: {
    downloadAdmissionLetter(offerLetterPath) {
      return process.env.BASE_URL  + offerLetterPath;
    },
  },
};
</script>