<template>
  <b-modal
      title="Payment Receipt screen"
      id="PaymentReceiptScreen"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="onClose"
  >
    <div>
      <b>
        <template v-if="payment === 'success'">
          Your payment was successful!
        </template>
        <template v-else-if="payment === 'error'">
          Your payment was unsuccessful!
        </template>
        <template v-else-if="payment === 'cancel'">
          Your payment was cancelled!
        </template>
      </b>
    </div>

    <template #modal-footer>
      <b-button
          class="btn-size"
          variant="outline-secondary"
          @click="hideModal"
      >
        Close
      </b-button>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: "PaymentReceiptScreen",
  props: ["payment"],
  methods: {
    onClose() {
      this.$emit('closeModal');
      this.$store.dispatch("applicant/loadDepositPaymentResponseDelayLists");          
    },
    hideModal() {
      this.$bvModal.hide("PaymentReceiptScreen");
    },
  },
}
</script>
