var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":"true"}},[_c('page-load-overlay',{attrs:{"show":_vm.showOverlay}},[_c('h6',[_vm._v("Biographic Information")]),_c('p',[_vm._v(" Please answer all of the below questions, submit your application, and pay your application fee prior to the application deadline. ")]),_c('div',[_c('p',[_vm._v(" Your name details will be used in official and non-official UBC communications. For information on how your name will be used please visit https://equity.ubc.ca/resources/gender-diversity/names/ ")]),_c('p'),_c('b-alert',{attrs:{"show":_vm.isError,"variant":"danger","dismissible":""}},[_vm._v(" The following Errors have occurred: "),_c('br'),_vm._v(" "+_vm._s(this.savingError)+" ")]),_c('b-form-group',{attrs:{"label":"Preferred name","label-cols":"4"}},[_c('b-form-input',{model:{value:(_vm.formData.preferredName),callback:function ($$v) {_vm.$set(_vm.formData, "preferredName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.preferredName"}})],1),_c('b-form-group',{attrs:{"label-for":"input-first-name","label-cols":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.$dirty && _vm.v$.formData.legalFirstName.$error,
          },attrs:{"disabled":_vm.isFirstNameDisabled,"id":"input-first-name"},model:{value:(_vm.formData.legalFirstName),callback:function ($$v) {_vm.$set(_vm.formData, "legalFirstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.legalFirstName"}}),_vm._l((_vm.v$.formData.legalFirstName.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-for":"input-last-name","label-cols":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.$dirty && _vm.v$.formData.surname.$error,
          },attrs:{"disabled":_vm.isLastNameDisabled,"id":"input-last-name"},model:{value:(_vm.formData.surname),callback:function ($$v) {_vm.$set(_vm.formData, "surname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.surname"}}),_vm._l((_vm.v$.formData.surname.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-for":"input-dob","label-cols":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Date of birth "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-datepicker',{class:{
            'is-invalid': _vm.v$.$dirty && _vm.v$.formData.dateOfBirth.$error,
          },attrs:{"disabled":_vm.isDateOfBirthDisabled,"reset-button":"","id":"input-dob"},model:{value:(_vm.formData.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.formData, "dateOfBirth", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.dateOfBirth"}}),_vm._l((_vm.v$.formData.visaTypeCode.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-for":"input-visa-code","label-cols":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Visa status "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{class:{
            'is-invalid': _vm.v$.$dirty && _vm.v$.formData.visaTypeCode.$error,
          },attrs:{"options":_vm.allVisas,"text-field":"description","value-field":"visaCode","disabled":_vm.isVisaTypeDisabled,"id":"input-visa-code"},model:{value:(_vm.formData.visaTypeCode),callback:function ($$v) {_vm.$set(_vm.formData, "visaTypeCode", $$v)},expression:"formData.visaTypeCode"}}),_vm._l((_vm.v$.formData.visaTypeCode.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-for":"input-citizenship","label-cols":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Citizenship "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{class:{
            'is-invalid':
              _vm.v$.$dirty && _vm.v$.formData.countryOfCitizenship.$error,
          },attrs:{"options":_vm.countries,"text-field":"description","value-field":"countryCode","disabled":_vm.isCitizenshipDisabled,"id":"input-citizenship"},model:{value:(_vm.formData.countryOfCitizenship),callback:function ($$v) {_vm.$set(_vm.formData, "countryOfCitizenship", $$v)},expression:"formData.countryOfCitizenship"}}),_vm._l((_vm.v$.formData.countryOfCitizenship.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-for":"input-email","label-cols":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.$dirty && _vm.v$.formData.email.$error,
          },attrs:{"disabled":_vm.isEmailDisabled,"id":"input-email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.email"}}),_vm._l((_vm.v$.formData.email.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('p',[_vm._v(" If there have been changes to your biographic information, disability status, or youth in care status since your last attendance at UBC, please contact the admissions office with your student number via the online form at https://account.you.ubc.ca/s/ask-ubc ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }