<template>
  <b-container fluid="true">
    <b-modal
        size="lg"
        scrollable
        v-model="showModal"
        title="Third Party Authorization"
        id="thirdPartyAuthModal"
        class="modal-dialog modal-xl"
        cancel-title="Close"
        cancel-variant="outline-secondary"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        @shown="getThirdPartyContacts"
        :hide-header-close="isCommunicating"
    >

      <p>
        Information about you is held by numerous units across UBC including Enrolment Services, your Faculty,
        and Advisors with whom you have interacted.
        This information will only be released to third parties with your express consent.
        If you wish to give permission to a third party (eg. parent, spouse, etc.) to access your information,
        please complete the following. By designating a third party, you are authorizing UBC to release specified
        information from your student record to that third party.
        Your designated third party will not be able to make changes to your record.
      </p>

      <b-table @row-selected="selectRow"
               :items="contacts"
               :fields="tableFields"
               hover
               small
               responsive="true"
               show-empty>
        <template #cell(telephone)="row">
          {{row.item.countryCodePrimary}}-{{ row.item.primPhone }} <br/>
          {{row.item.countryCodeAlternate}}-{{ row.item.secondPhone }}
        </template>
        <template #cell(actions)="row">
          <div class="noWrap">
            <b-button variant="light">
              <b-icon-pencil title="Update" shift-h="0em" @click="selectRow(row.index)"/>
            </b-button>
            <b-button variant="light">
              <b-icon-trash title="Delete" @click="selectRow(row.index); openConfirmDialog();"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="text-center textRed" v-if="contacts.length == 0">
        You have no Contacts of this type at this time.
      </div>

      <b-container fluid>
        <b-card class="mt-3">

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Title</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                  type="text"
                  v-model.trim="v$.title.$model"
                  :class="{ 'is-invalid': v$.title.$error }"
              />
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Given Name <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                  type="text"
                  v-model.trim="v$.givenName.$model"
                  :class="{ 'is-invalid': v$.givenName.$error }"
              />
              <div class="invalid-feedback" v-for="error of v$.givenName.$errors" :key="error.$uid">
                <span v-if="givenName.length == 0 "> Given name is a mandatory field.</span>
                <span v-else-if="!v$.givenName.maxLength.max">{{ error.$message }}</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Last Name <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                  type="text"
                  v-model.trim="v$.lastName.$model"
                  :class="{
                  'is-invalid': v$.lastName.$error,
                }"
              />
              <div class="invalid-feedback" v-for="error of v$.lastName.$errors" :key="error.$uid">
                <span v-if="lastName.length == 0 "> Last name is a mandatory field.</span>
                <span v-else-if="!v$.lastName.maxLength.max">{{ error.$message }}</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Authorization Code <span class="text-danger">*</span> &nbsp;
                <b-icon-info-square title="Your authorized third party must provide this secret code before information can be released.
                       (25 Character Limit)"></b-icon-info-square>
              </label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                  type="text"
                  v-model.trim="v$.authCode.$model"
                  :class="{
                  'is-invalid': v$.authCode.$error,
                }"
              />
              <div class="invalid-feedback" v-for="error of v$.authCode.$errors" :key="error.$uid">
                <span v-if="authCode.length == 0 "> Authorization Code is a mandatory field.</span>
                <span v-else-if="!v$.authCode.maxLength.max">{{ error.$message }}</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Primary Telephone <span class="text-danger">*</span> &nbsp;
                <b-icon-info-square
                    title="Please note: for numbers outside of Canada, add country code"></b-icon-info-square>
              </label>
            </b-col>
            <b-col sm="7">
              <b-form-select
                  v-model="v$.countryCodePrimary.$model"
                  :options="countries"
                  value-field="phoneCode"
                  text-field="countryPhone"
                  size="sm"
              ></b-form-select>
              <b-form-input
                  type="text"
                  v-model.trim="v$.primPhone.$model"
                  :class="{
                  'is-invalid': v$.primPhone.$error,
                  'is-valid': !v$.primPhone.$invalid,
                }"
              />
              <div class="invalid-feedback" v-for="error of v$.primPhone.$errors" :key="error.$uid">
                <span v-if="primPhone.length == 0 && error.$message != ('INVALID_INPUT')"> Primary phone number is a mandatory field.</span>
                <span v-else-if="error.$params.min">Primary phone number must be 10 numbers.</span>
                <span v-else-if="error.$params.max">Primary phone number is limited to 30 numbers.</span>
                <span v-else-if="primPhone.length > 0 && error.$message == ('INVALID_INPUT')"> Only numbers and dash (-) are allowed</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Secondary Telephone &nbsp;
                <b-icon-info-square
                    title="Please note: for numbers outside of Canada, add country code"></b-icon-info-square>
              </label>
            </b-col>
            <b-col sm="7">
              <b-form-select
                  v-model="v$.countryCodeAlternate.$model"
                  :options="countries"
                  value-field="phoneCode"
                  text-field="countryPhone"
                  size="sm"
              ></b-form-select>
              <b-form-input
                  type="text"
                  v-model.trim="v$.secondPhone.$model"
                  :class="{
                  'is-invalid': secondPhone.length > 0 ? v$.secondPhone.$error : '',
                  'is-valid': secondPhone.length > 0 ? !v$.secondPhone.$invalid : '',
                }"
              />
              <div class="invalid-feedback" v-for="error of v$.secondPhone.$errors" :key="error.$uid">
                <span v-if="error.$params.min">Secondary phone number must be 10 numbers.</span>
                <span v-else-if="error.$params.max">Secondary phone number is limited to 30 numbers.</span>
                <span v-else-if="secondPhone.length > 0 && error.$message == ('INVALID_INPUT')"> Only numbers and dash (-) are allowed</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Email Address</label> &nbsp;
            </b-col>
            <b-col sm="7">
              <b-form-input
                  type="email"
                  v-model.trim="v$.emailId.$model"
                  :class="{
                  'is-invalid': v$.emailId.$error,
                }"
              />
              <div class="invalid-feedback" v-for="error of v$.emailId.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4" class="text-right">
              <label>Relationship</label>
            </b-col>
            <b-col sm="7">
              <b-form-select v-model="relationship" :options="options">
              </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <p class="ml-5 mt-4"> Please indicate the unit for which you are consenting to disclose your
              information to your authorized third party.</p>
          </b-row>
        </b-card>
      </b-container>
      <p></p>

      <b-table-simple width="100%" hover
                      small
                      responsive="true">
        <b-tr>
          <b-th>&nbsp;</b-th>
          <b-th>Area</b-th>
          <b-th>Explanation</b-th>
        </b-tr>
        <b-tr>
          <b-td class="cell-with-border">
            <b-form-checkbox
                v-model="admInfoAccepted"
            />
          </b-td>
          <b-td class="cell-with-border">
            Admission, Financial Assistance & Award Information
          </b-td>
          <b-td class="cell-with-border">
            I consent to the release of information related to my application for
            admission to UBC, financial assistance and award status, including but not limited to application content,
            application status,
            documentation, transcripts, document pickup, student loans, bursaries, scholarships and financial need. As
            related to this status, registration, grades and fees may also be released.
          </b-td>
        </b-tr>
      </b-table-simple>

      <div>
        <b-alert :show="v$.admInfoAccepted.$error" variant="danger">
          "Permission" checkbox must be checked to save contact information!
        </b-alert>
      </div>
      <b-table-simple borderless>
        <b-tr>
          <b-td colspan="2">
            <h6>Third Party Authorization</h6>
            <p>
              By designating a third party and specifying the categories of information that may be released,
              you are consenting to the release of the information by UBC to that third party.
              You may withdraw or amend this consent at any time. The University takes no responsibility for the
              third party's use of any information released pursuant to your consent.
            </p>
            <p>
              Notwithstanding the above, the University may disclose your information without your consent under
              limited circumstances specified in section 33 of the Freedom of Information and Protection of
              Privacy Act (FIPPA). For example, your information may be disclosed if there are compelling
              circumstances that affect anyone's health or safety.
            </p>
            The University collects your personal information under the authority of section 26 of the FIPPA.
            For more information about the FIPPA,
            please visit: <a
              href="https://universitycounsel.ubc.ca/subject-areas/access-and-privacy-general/access-to-information/about-fippa/"
              target="_blank">
            https://universitycounsel.ubc.ca/subject-areas/access-and-privacy-general/access-to-information/about-fippa/.</a>
            UBC uses your personal information for its operating programs and activities,
            including but not limited to the following:
            >
            <ul>
              <li>Providing information and advice on your course and program needs</li>
              <li>Providing ongoing service and assistance to understand your post-secondary educational needs</li>
              <li>Statistical purposes</li>
              <li>Authenticating your access to UBC systems</li>
            </ul>
            <p>
              I acknowledge that I understand and agree to the above Terms of Use and Agreement,
              and that I am consenting to my designated third party receiving access to my information as
              indicated above.
            </p>

          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="width70Ptg">
            <p class="textAlignLeft">Please indicate your acceptance of these terms by clicking this box:</p>
          </b-td>
          <b-td>
            <b-form-checkbox
                v-model="ackChecked"
                size="lg"/>
          </b-td>
        </b-tr>
      </b-table-simple>
      <b-alert :show="v$.ackChecked.$error" variant="danger">
        "Terms of Use and Agreement" checkbox must be checked to save contact information!
      </b-alert>

      <template #modal-footer>
        <b-alert
            class="center-alert"
            variant="success"
            :show="dismissCountDown"
            fade
            @dismiss-count-down="countDownChanged">
          {{ alertMessage }}
        </b-alert>
        <b-alert
            class="center-alert"
            variant="danger"
            :show="dismissErrCountDown"
            fade
            @dismiss-count-down="errCountDownChanged">
          {{ errorMessage }}
        </b-alert>
        <b-button :disabled="isCommunicating" @click="close" variant="outline-secondary">Close</b-button>
        <b-button :disabled="isCommunicating" @click="saveChanges" variant="primary">
          <b-spinner small class="align-middle" v-if="isCommunicating"></b-spinner>
          <span v-else>Save</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
        v-model="openDeleteDialog"
        no-close-on-backdrop
        hide-header-close
        :hide-header="true"
    >
      <p class="my-4">Are you sure you want to delete this contact? </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()" :disabled="isCommunicating" variant="outline-secondary">
          Cancel
        </b-button>
        <b-button variant="danger" :disabled="isCommunicating" @click="deleteThirdPartyContact()">
          <b-spinner small class="align-middle" v-if="isCommunicating"></b-spinner>
          <span v-else>Delete</span>
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {mapGetters} from "vuex";
import {email, helpers, maxLength, minLength, required} from "@vuelidate/validators";
import moment from "moment";
import { cloneDeep } from "lodash/lang";

const dateFormat = "MMMM DD, YYYY";

const phoneInputValidator = (value) => {
  if (value.length > 0) {
    return new RegExp(/^[0-9 \-]*$/).test(value);
  }
  return true;
}

const admValidator = (value) => {
  return value;
}

const ackValidator = (value) => {
  return value;
}

export default {
  name: "ThirdPartyAuthFormModal",
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      showModal: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      dismissErrCountDown: 0,
      emailId: '',
      title: '',
      givenName: '',
      lastName: '',
      authCode: '',
      primPhone: '',
      secondPhone: '',
      countryCodePrimary:'1',
      countryCodeAlternate:'1',
      updatedOn: '',
      admInfoAccepted: false,
      ackChecked: false,
      relationship: 'AGNT',
      admInfo: '',
      contacts: [],
      selectedRowIdx: '-1',
      selectMode: 'single',
      assocUbcId: null,
      alertMessage: "",
      errorMessage: "",
      openDeleteDialog: false,
      options: [
        {value: 'AGNT', text: 'Agent'},
        {value: 'FATH', text: 'Father'},
        {value: 'FRIE', text: 'Friend'},
        {value: 'GUAR', text: 'Guardian'},
        {value: 'MOTH', text: 'Mother'},
        {value: 'OTHR', text: 'Other'},
        {value: 'SIBL', text: 'Sibling (brother/sister)'},
        {value: 'SPON', text: 'Sponsorship Billing'},
        {value: 'SPOU', text: 'Spouse'}
      ],
      tableFields: [
        {
          key: 'name', thClass: "normalHeader",
          formatter: (value, key, contact) => {
            return contact.givenName + " " + contact.lastName;
          }
        },
        {key: 'telephone', thClass: "normalHeader",},
        {
          key: 'submitted_on', thClass: "normalHeader",
          formatter: (value, key, contact) => {
            return this.formatUpdateDate(contact.updatedOn);
          }
        },
        {
          key: 'relationship', thClass: "normalHeader",
          formatter: (value, key, contact) => {
            const found = this.options.find(element => element.value == contact.relationship);
            return found?.text;
          }
        },
        {key: 'emailId', label: 'Email', thClass: "normalHeader",},
        {key: 'actions', thClass: "normalHeader",},
      ],
    };
  },

  validations: {
    title: {
      maxLength: maxLength(4),
    },
    givenName: {
      required,
      maxLength: maxLength(25),
    },
    lastName: {
      required,
      maxLength: maxLength(25),
    },
    authCode: {
      required,
      maxLength: maxLength(25),
    },
    primPhone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(30),
      phoneInputValidator: helpers.withMessage("INVALID_INPUT", phoneInputValidator),
    },
    secondPhone: {
      minLength: minLength(10),
      maxLength: maxLength(30),
      phoneInputValidator: helpers.withMessage("INVALID_INPUT", phoneInputValidator),
    },
    countryCodePrimary: {
      maxLength: maxLength(5),
    },
    countryCodeAlternate: {
      maxLength: maxLength(5),
    },
    emailId: {
      email
    },
    admInfoAccepted: {
      required,
      admValidator: helpers.withMessage("ADM_UNCHCKED", admValidator)
    },
    ackChecked: {
      required,
      ackValidator: helpers.withMessage("ACK_UNCHCKED", ackValidator),
    },
    assocUbcId: {},
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("applicant", ['countries']),
    ...mapGetters("thirdPartyAuthorizations", ['getAuthorizations', 'isSaving', 'isLoading', 'isDeleting']),
    isCommunicating() {
      return this.isSaving || this.isLoading || this.isDeleting;
    },
  },
  methods: {
    init(){
      if (this.countries) {
        for (const country of this.countries) {
          if (!country.phoneCode) {
            country.phoneCode = "";
            country.countryPhone = country.description;
          } else {
            country.countryPhone = country.description + "-" + country.phoneCode;
          }
        }
      }
    },
    showAlert(message) {
      this.alertMessage = message;
      this.dismissCountDown = this.dismissSecs
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.dismissErrCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    errCountDownChanged(dismissCountDown) {
      this.dismissErrCountDown = dismissCountDown
    },
    getThirdPartyContacts() {
      this.$store.dispatch("thirdPartyAuthorizations/loadThirdPartyAuthorizations");
    },
    reset() {
      this.v$.$reset();
      this.title = '';
      this.givenName = '';
      this.lastName = '';
      this.primPhone = '';
      this.secondPhone = '';
      this.countryCodePrimary ='1';
      this.countryCodeAlternate = '1';
      this.emailId = '';
      this.relationship = 'AGNT';
      this.selectedRowIdx = -1;
      this.authCode = '';
      this.assocUbcId = null;
      this.admInfoAccepted = false;
      this.ackChecked = false;
      this.errorMessage = null;
    },
    show() {
      this.showModal = true;
      this.reset();
    },
    close() {
      this.showModal = false;
      this.v$.$reset();
      this.v$.$errors.clear;
      this.$emit('closeModal');
    },
    formatUpdateDate(date) {
      return moment.utc(date).format(dateFormat);
    },
    saveChanges() {

      if( this.contacts.length >= 10 && this.assocUbcId === null) {
        this.showErrorMessage( 'Students can add a maximum of 10 Third Party Authorizations');
        return;
      }

      this.v$.$touch();
      if (this.v$.$pending || this.v$.$error) return;
      let cntct = {};
      cntct.associatedUbcId = this.assocUbcId;
      cntct.title = this.title;
      cntct.givenName = this.givenName;
      cntct.lastName = this.lastName;
      cntct.primPhone = this.primPhone;
      cntct.countryCodePrimary = this.countryCodePrimary;
      cntct.countryCodeAlternate = this.countryCodeAlternate;
      cntct.secondPhone = this.secondPhone;
      cntct.emailId = this.emailId;
      cntct.relationship = this.relationship;
      cntct.authCode = this.authCode;
      cntct.updatedOn = this.updatedOn;
      this.$store.dispatch('thirdPartyAuthorizations/saveThirdPartyAuthorization',  cntct);
    },

    selectRow: function (index) {
      this.title = this.contacts[index].title;
      this.givenName = this.contacts[index].givenName;
      this.lastName = this.contacts[index].lastName;
      this.primPhone = this.contacts[index].primPhone;
      this.secondPhone = this.contacts[index].secondPhone;
      this.countryCodePrimary = this.contacts[index].countryCodePrimary;
      this.countryCodeAlternate = this.contacts[index].countryCodeAlternate;
      this.emailId = this.contacts[index].emailId;
      this.agent = this.contacts[index].agent;
      this.relationship = this.contacts[index].relationship;
      this.authCode = this.contacts[index].authCode;
      this.assocUbcId = this.contacts[index].associatedUbcId;
      this.admInfoAccepted = this.contacts[index].admInfoAccepted;
      this.updatedOn = this.contacts[index].updatedOn;
      if (this.admInfoAccepted === 'ADMS' || this.admInfoAccepted === 'admAccepted') {
        this.admInfoAccepted = true;
      }
      this.selectedRowIdx = index;
    },

    openConfirmDialog: function () {
      this.openDeleteDialog = true;
    },
    deleteThirdPartyContact: function () {
      this.$store.dispatch("thirdPartyAuthorizations/deleteThirdPartyAuthorization", this.contacts[this.selectedRowIdx].associatedUbcId);
    },
  },
  watch: {
    getAuthorizations: function (val) {
      this.contacts = cloneDeep(val);
    },
    isDeleting: {
      deep: true,
      handler (newValue, oldValue) {
        if (oldValue === true && newValue === false) { // Done deleting
          this.reset();
          this.showAlert("Deleted");
          this.openDeleteDialog = false;
        }
      }
    },
    isSaving: {
      deep: true,
      handler (newValue, oldValue) {
        if (oldValue === true && newValue === false) { // Done saving
          this.reset();
          this.showAlert("Saved");
        }
      }
    },
  },
}
</script>

<style lang="css" scoped>
.noWrap {
  white-space: nowrap;
}

.width70Ptg {
  width: 70%;
}

.center-alert {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}

.textAlignLeft {
  text-align: left;
}

.textRed {
  color: #ff4b01;
}

div >>> .normalHeader {
  font-weight: normal;
}

th {
  font-weight: normal;
}
</style>
