import api from "../../api/backend-api";

const state = {
    transferCredits: null,
    isLoading: false,
};

const getters = {
    transferCredits(state) {
        return state.transferCredits
    },
};

const actions = {
    loadTransferCredits({commit}) {
        if (state.transferCredits?.sessionEnrolments || state.isLoading) {
            return;
        }
        commit('setIsLoading', true);
        api.getTransferCredits()
            .then(response => {
                commit('setTransferCredits', response.data);
            })
            .catch(error => {
                console.log('Error caught in loadTransferCredits: ' + JSON.stringify(error));
            })
            .finally(() => {
                commit('setIsLoading', false);
            });
    },
};

const mutations = {
    setTransferCredits(state, payload) {
        state.transferCredits = payload;
    },
    setIsLoading(state, value) {
        state.isLoading = value;
    },
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};