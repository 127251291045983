<template>
  <div>
    <h3>Documents</h3>
    <p>If need to provide additional documents, please upload below.</p>

    <b-container>
      <b-row class="row" v-for="document in applicant.documents" :key="document.documentGuid">
        <b-col>{{ document.documentType }}</b-col>
        <b-col>
          <div v-if="document.uploadedDocument != null">
            <a :href="process.env.BASE_URL + `/api/document/${document.documentGuid}`" target="_blank">{{ document.uploadedDocument.fileName }}</a>
          </div>
        </b-col>
        <b-col>
          <div v-if="document.uploadedDocument != null">
            {{ document.uploadedDocument.documentFileStatus }}
          </div>
          </b-col>
        <b-col>
          <div class="inputs" v-if="document.uploadedDocument != null && document.uploadedDocument.documentFileStatus !== 'FINALISED'">
            <button class="delete-btn" @click="deleteDocument(document.documentGuid)">Delete</button>
            <button class="finalise-btn" @click="finaliseDocument(document.documentGuid)">Finalise</button>
          </div>
          <div class="inputs" v-else-if="document.uploadedDocument == null">
            <input type="file" name="uploadedFile" @change="selectedFile($event)">
            <button class="upload-btn" @click="upload(document.documentGuid)">Upload</button>
          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'documents',
    computed: {
      ...mapGetters(['applicant'])
    },
    data() {
      return {
        file: null
      }
    },
    methods: {
      ...mapActions("applicant", ['getDeleteDocumentPromise', 'getFinaliseDocumentPromise','getUploadFilePromise']),
      selectedFile(e) {
        this.file = event.target.files[0];
      },
      upload(documentGuid) {
        let formData = new FormData();
        formData.append('uploadedFile', this.file);
        this.$store.dispatch('applicant/getUploadFilePromise', {
          documentGuid, formData
        });
        this.file = null;
      }
    }
  }

</script>


<style scoped>
 .row  {
   border: 1px #ddd solid;
 }
 .row:hover  {
   background-color: #eee;
 }
 .delete-btn {
   background-color: #f66;
 }
 .finalise-btn {
   background-color: #6f6;
 }
 .upload-btn {
   background-color: #ff6;
 }
 .inputs {
   display:flex;
   flex-direction: row;
   justify-content: right;
   align-items: right;
 }
</style>
