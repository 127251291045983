<template>

  <b-modal
      id="IdleTimeOutModal"
      title="Session Expired"
      centered
      no-close-on-backdrop
      no-close-on-esc
     hide-footer
  >
    <p>Your session is about to expire due to inactivity.</p>
    <p>Timer: {{ ~~(timeToLogOutAfterWarning/(60*1000)) }} minute
      {{ ~~((timeToLogOutAfterWarning/1000)%60) }} second </p>
  </b-modal>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';
import api from "@/api/backend-api";

export default {
  name: "IdleTimeOutModal",

  data() {
    return {
      logoutWarning: 5*30*1000,
      timeToLogOutAfterWarning: 5*30*1000,
      timeToRefreshServerSession: 20*60*1000,
      timerId:null,
      previousTimeInMilliseconds:0,
    }
  },
  computed: {
    ...mapGetters("session", {
      loggedIn: "loggedIn",
    }),
  },
  watch: {
    isAppIdle(newValue, oldValue) {
     // axiosClient.get('/authenticate/status');
      if (( (newValue && !oldValue) ||
          (document.hasFocus() && this.timeToLogOutAfterWarning > this.logoutWarning))
          && this.loggedIn) {
        //user idle
        this.$bvModal.show("IdleTimeOutModal");
        this.resetLogOutTimer();
        this.triggerLogoutTimer();
      } else if (!newValue && oldValue) {
        this.$bvModal.hide("IdleTimeOutModal");
      }
    },
    loggedIn: function (newLoggedIn, oldLoggedIn) {
      if (!newLoggedIn && oldLoggedIn) {
        if(document?.getElementById('ascLogoutForm')) {
          document.getElementById('ascLogoutForm').submit();
        }
        if(this?.timerId){
          clearInterval(timerId);
        }
      }
      if (newLoggedIn) {
        this.timerId = setInterval(() => {
          this.keepServerAlive();
        }, this.timeToRefreshServerSession);
      }
    },
  },
    mounted() {
      this.resetLogOutTimer();

    },
    destroyed() {
      this.resetLogOutTimer();
    },

    methods: {
      ...mapMutations("session", {notifyExpired: "notifyExpired"}),
      onClose() {
        this.resetLogOutTimer()
        this.$emit("closeModal");
      },
      resetLogOutTimer() {
        this.timeToLogOutAfterWarning = this.logoutWarning;
      },

      triggerLogoutTimer() {
        this.timerId = setInterval(this.logoutTimer, 1000);
      },

      logoutTimer() {
        let warningInterval = 1000;
        let currentTime = (new Date()).getTime();

        if( this.previousTimeInMilliseconds > 0 ) {
          warningInterval = ((currentTime- this.previousTimeInMilliseconds)  );
        }

        if( document.hasFocus()) {
          warningInterval = 1000;
        }

        this.previousTimeInMilliseconds = currentTime;

        this.timeToLogOutAfterWarning = this.timeToLogOutAfterWarning - warningInterval;

          if (!this.isAppIdle) {
            clearInterval(this.timerId);
          }

          if (this.timeToLogOutAfterWarning < 1) {
            clearInterval(this.timerId);
            // Call for session logout
            this.notifyExpired();
          }
      },
      keepServerAlive(){
        return new Promise((resolve, reject) => {
          api.keepServerAlive()
              .then(response => {
                resolve(response.data);
              })
        });
      },
    },

  }

</script>