<template>
  <b-modal
      title="Transfer Credit"
      centered
      scrollable
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      v-model="showModal"
      @hidden="onClose"
      ref="transfer-credit-modal"
  >
    <div class="listTitle">Transfer Credits: &nbsp; &nbsp;
      <b-icon-info-square title="If you have taken courses at another university, at a college or in IB or
AP courses, you may be eligible to receive credit at UBC.

The maximum allowable credit is 50% of the required program, up to
a maximum of 60 credits.">
      </b-icon-info-square>

    </div>
    <b-row class="listRow">
      <b-col cols="3">Campus Location:</b-col>
      <b-col cols="3">
         <b-form-select
           :options="locationOptions"
           v-model="selectedCampus"
           ></b-form-select>
      </b-col>
      <b-col cols="6">
         To view your transfer credits, select a campus location from the drop-down menu.
      </b-col>
    </b-row>

    <div v-if="selectedCampus === 'UBC' && transferCreditsUBC.length > 0">
      <b-table 
        bordered
        head-variant="light"
        hover 
        small 
        :items="transferCreditsUBC" 
        :fields="fieldsTransferCredits">
        <!-- A custom formatted header cell for field 'standing' -->
        <template #head(standing)="data">
          {{ data.label }}
          <b-icon-info-square title="Exemption
  The waiving of a prerequisite or required
  course for students who have proven they
  have comparable learning. The student may
  be required to replace the exempted course
  with an alternate.

Preclusion
  A designation indicating that the course
  completed is similar to a UBC course but
  specific transfer credit cannot be assigned.
  Students cannot therefore subsequently
  receive credit for the precluded course
  at UBC.">
          </b-icon-info-square>
        </template>
      </b-table>

      <div class="listTitle">Transfer Credit Notes:</div>
        <b-table 
          borderless
          thead-class="d-none" 
          no-border-collapse
          small 
          :items="comments"
          :fields="fieldsComments">
        </b-table>
    </div>

    <div v-else-if="selectedCampus === 'UBCO' && transferCreditsUBCO.length > 0">
      <b-table 
        bordered
        head-variant="light"
        hover 
        small 
        :items="transferCreditsUBCO" 
        :fields="fieldsTransferCredits">
      </b-table>
      <div class="listTitle">Transfer Credit Notes:</div>
        <b-table 
          borderless
          thead-class="d-none" 
          no-border-collapse
          small 
          :items="comments"
          :fields="fieldsComments">
        </b-table>
    </div>
    <div v-else-if="selectedCampus !== null">
      There is no transfer credit for this campus. <br /><br />
    </div>
    <div class="listTitle topSpace">Previous Institution Courses:</div>
    <b-table 
      bordered
      head-variant="light"
      hover 
      small 
      :items="nonUbcCourses"
      :fields="fieldsNonUbcCourses">
    </b-table>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          class="float-right"
          variant="outline-secondary"
          @click="hideModal"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TransferCreditModal',
  props: {
    transferCreditsData: Object,
    intentionSession: String,
  },
  data() {
    return {
      showModal: true,
      selectedCampus: null,
      fieldsTransferCredits: [
        { key: "campus", label: "Campus" },
        { key: "course", label: "Course" },
        { key: "standing", label: "Standing" },
        { key: "credit", label: "Credit" },
        { key: "session", label: "Session" },
        { key: "updated", label: "Updated" },
      ],
      fieldsNonUbcCourses: [
        { key: "institution", label: "Institution" },
        { key: "course", label: "Course" },
      ],
      fieldsComments: [
        { key: "comment", tdClass: "lessCellPadding"},
      ],
      locationOptions: [
        { value: null, text: "Select a campus" },
        { value: "UBC", text: "UBC Vancouver" },
        { value: "UBCO", text: "UBC Okanagan" },
      ]
    };
  },
  computed: {
    transferCreditsUBC() {
      return this.generateTransferCredits("UBC");
    },
    transferCreditsUBCO() {
      return this.generateTransferCredits("UBCO");
    },
    nonUbcCourses() {
      let nonUBCCourses = this.transferCreditsData.nonUbcCourses;
      let result = [];
      for (const obj of nonUBCCourses) {
        if (obj?.institutionDescription) {
          let row = {};
          row.institution = obj.institutionDescription;
          row.course = obj.departmentCode + " " + obj.courseNumber;
          result.push(row);
        }
      }
      return result;
    },
    comments() {
      let allComments = this.transferCreditsData.commentsForTransferCredits;
      let ids = [];
      if(allComments) {
        allComments.forEach(item => {
          if (item) {
            ids.push(item.id);
          }
        });
      }
      let allTexts = this.transferCreditsData.commentTextsForTransferCredits;
      let result = [];
      for (const id of ids) {
        delete id.printCode;

        var filtered = allTexts.filter(function(v, i) {
          return (_.isEqual(v["id"], id));
        })
        var temp = filtered.map(a => a.textLine);
        result.push(...temp);
      }
      return result.map(x => {return {comment: x};});
    },
  },
  methods: {
    onClose() {
      this.$emit('closeModal');
    },
    hideModal() {
      this.$refs['transfer-credit-modal'].hide();
    },
    groupByTcId(arr){
      let grouped = arr.reduce(function (r, a) {
        r[a.tcId] = r[a.tcId] || [];
        r[a.tcId].push(a);
        return r;
      }, Object.create(null));
      return grouped;
    },
    groupNonUBCCoursesByTcId(arr){
      let grouped = arr.reduce(function (r, a) {
        r[a.tcId] = r[a.tcId] || [];
        if(a?.institutionDescription) {
          r[a.tcId].push(a.departmentCode + " " + a.courseNumber + " (" + a.institutionDescription + ")");
        }
        return r;
      }, Object.create(null));
      return grouped;
    },
    generateTransferCredits(campus) {
      let all = this.transferCreditsData.transferCredits;
      if (this.intentionSession && all.length > 0) {
        all = all.filter((tc) => tc.sessionYear + "-" + tc.sessionCode === this.intentionSession);
      }
      let campusRows = all.filter((it) => it.campusCode.trim() === campus);
      if (campusRows.length === 0){
        return [];
      }
      let grouped = this.groupByTcId(campusRows);
      let keys = Object.keys(grouped);
      let result = [];
      keys.forEach(tcId => {
        let items = grouped[tcId];
        for (const obj of items) {
          let row = {};
          row.campus = obj.campusCode;
          row.course = obj.departmentCode + " " + obj.courseNumber;
          row.standing = obj.standingCode?.trim() === "EX" ? "Exemption" : obj.standingCode;
          row.credit = obj.creditAmount;
          row.session = obj.sessionYear + obj.sessionCode;
          row.updated = obj.updateDate;
          row.tcId = obj.tcId;
          result.push(row);
        }
      })
      let sortedResult = _.sortBy( result, "course" );
      // remove duplicated rows

      // insert non-ubc rows
      let nonUBCCourses = this.transferCreditsData.nonUbcCourses;
      let groupedNonUBCCourses = this.groupNonUBCCoursesByTcId(nonUBCCourses);
      for(let i = 0; i < sortedResult.length; i++) {
        let row = sortedResult[i];

        //skipping non-UBC course rows
        if(row.campus === ""){
          continue;
        }

        let nonUbcCourses = groupedNonUBCCourses[row.tcId];
        if(nonUbcCourses){
          for(let el of nonUbcCourses){
            sortedResult.splice(i + 1, 0, {
              campus: "",
              course: el,
              standing: "",
              credit: "",
              session: "",
              updated: "",
              tcId: row
            });
          }
        }
      }
      return sortedResult;
    },
  },
};
</script>

<style scoped>
  .listTitle {
    padding-top: 12px;
    font-weight: bold;
  }

  .listRow {
    margin-bottom: 20px;
  }

  .topSpace{
    padding-top: 25px;
  }
</style>



