<template>
  <b-card>
    <b-card-header>
        <h4>{{title}}</h4>
    </b-card-header>
    <b-card-body class="custom-bcard-body-margin">
      <div  style="text-align:left">
        <span v-html="link"></span>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>

export default {
  name: "LinkCardWidget",
  props: {
    title: String,
    link: String
  }
}
</script>

<style lang="css" scoped>
.card-body {
  padding: 0px!important;
}
.custom-bcard-body-margin {
  margin: 20px;
}</style>