import moment from "moment";
import store from '../store/index.js';

const supplementalDocCodes = store.getters.supplementalDocCodes;
const personalProfileDocTypeCodes = store.getters.personalProfileDocTypeCodes;

export function filterIntentionsWithOffers(intentions) {
    return intentions.filter(i => i.hasOffer);
}

export function filterIntentionsWithOffersAndNode(intentions) {
    return intentions.filter(i => i.hasOffer || (i.decisionStatus === "NODE") );
    //need to return decision pending intentions as well
}

export function isOfferExpired(intention) {
    return intention?.offerDeadline && intention?.offerDeadLineExpired;
}

export function isPEN(document) {
    return document.miscDocType?.startsWith("PEN");
}

export function isOUAC(document) {
    return document.miscDocType?.startsWith("OUAC");
}

export function isSupplementalDoc(document) {
    return (document.miscDocType && supplementalDocCodes?.includes(document.miscDocType));
}

export function isINTR(document) {
    return(document.documentType === "INTR");
}

export function isPP(document) {
    return (document.miscDocType && personalProfileDocTypeCodes?.includes(document.miscDocType));
}

export function eligibleForUpload(document) {
    return !document.isDocArrived && !document.isDocReceived && document.transcriptUpload && document.transcriptUpload === 'MISS';
}

export function docUploaded(document) {
    return document.uploadedDocument?.documentFileStatus === "FINALISED";
}

function filterDocuments(documents) {
    return documents
        .filter(doc => !isSupplementalDoc(doc) && !isPEN(doc) && !isOUAC(doc) && !isPP(doc) && !isINTR(doc) && doc.uploadAllowed)
        .filter(doc => eligibleForUpload(doc) && !docUploaded(doc));
}

export function filterUploadableDocs(documents, applications, intentions, uploadStatuses) {
    for (let application of applications) {
        for (let intention of intentions) {

            const map = new Map(Object.entries(uploadStatuses));

            if (map.has(intention.decisionReason) && (map.get(intention.decisionReason) === intention.decisionStatus)) {
                return filterDocuments(documents.filter(doc => doc.applicationId === application.id));
            }
        }
    }
    return [];
}

export function isDirectAcceptOffer(processingArea) {
    let directAccepts = ['REAL', 'MEDI', 'EXCH', 'RITS', 'MRES', 'DRES'];
    return directAccepts.includes(processingArea.trim());
}
