import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'
import axiosClient from "./axios-client";
import "./vee-validate";
import IdleVue from "idle-vue";
import setup from "./api/interceptors.js";
const eventsHub = new Vue();

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.prototype.$http = axiosClient;
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 25*60*1000, // in milliseconds (waiting time for warning pop-up)
    startAtIdle: false,
    keepTracking: true,
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

setup();