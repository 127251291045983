<template>
  <div>
    <div v-if="showUploadDialog">
      <div class="mt-3 custom-bottom-margin">Browse for a document to add or drag and drop it below</div>
      <b-form-file
          v-model="file1"
          :state="Boolean(file1 != null)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept=".jpg, .jpeg, .doc, .docx, .pdf, .tiff, .tif"
      />
      <div v-if="this.isErrorUpload">
        {{ this.errorMessage }}
      </div>
      <b-button
          @click="close"
          variant="outline-secondary"
          class="custom-top-left-margin mx-1">
        Cancel
      </b-button>
      <b-button
          id="fileUploadButton"
          @click="startUpload()"
          variant="primary"
          :disabled="isDisabled"
          class="custom-top-margin mx-1">
        Upload File
      </b-button>
    </div>
    <div v-if="showUploadMessage">
      <span class="mt-3 custom-bottom-margin">{{ uploadingMessage }}</span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {ButtonPlugin, FormFilePlugin} from 'bootstrap-vue'

Vue.use(FormFilePlugin)
Vue.use(ButtonPlugin)

export default {
  name: "FileUploadWidget",
  props: {
    uploadingMessage: String,
    docGuid: String
  },
  data() {
    return {
      file1: null,
      showUploadDialog: true,
      showUploadMessage: false,
      isErrorUpload: false,
      errorMessage: "",
    }
  },
  computed: {
    isDisabled() {
      if (this.file1 == null) {
        return true;
      }
      return this.file1.length > 0;
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    async startUpload() {
      if (this.file1 == null) return;
      var size = parseFloat(this.file1.size / (1024 * 1024)).toFixed(2);
      if (size > 2) {
        this.showUploadMessage = true;
        this.uploadingMessage = "Uploaded files must be less than 2MB in size";
        return;
      }
      this.showUploadDialog = false;
      this.showUploadMessage = true;
      const formData = new FormData();
      formData.append("uploadedFile", this.file1);
      this.save(formData);
    },
    save(formData) {
      const self = this;

      // Retrieve UploadFilePromise
      this.$store.dispatch('applicant/getUploadFilePromise', {
        documentGuid: this.docGuid, uploadedFile: formData
      })
          .then(uploadedFileInfo => {
            self.reset();
            self.$emit('closeUpload');
          })
          .catch(err => {
            self.reset();
            this.isErrorUpload = true;
            if (err.response) {
              this.errorMessage = err.response.data.message;
            }
          });
    },
    reset() {
      this.file1 = null;
      this.showUploadDialog = true;
      this.showUploadMessage = false;
      this.isErrorUpload = false;
    },
    close() {
      this.reset();
      this.$emit('closeUpload');
    }
  }
};

</script>
<style lang="css" scoped>
@import '../assets/file-upload-widget.css';
</style>