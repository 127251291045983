import Vue from 'vue';
import Vuex from 'vuex';
import payment from './modules/payment';
import applicant from './modules/applicant';
import transferCredit from './modules/transfercredit';
import * as personalProfile from './modules/personalProfile';
import * as session from './modules/session';
import thirdPartyAuthorizations from './modules/thirdpartyauthorizations';
import reAdmission from './modules/reAdmission';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: {
        session,
        payment,
        applicant,
        transferCredit,
        personalProfile,
        reAdmission,
        thirdPartyAuthorizations,
    }
});
