<template>
  <div>
    <p>Congratulations on your successful admission to UBC!</p>
    <p>
      Please complete the form below to accept or decline your offer. If you
      have received more than one offer of admission, you must choose only one
      to accept.
    </p>
    <div ref="errDiv">
      <b-alert :show="isError" variant="danger">
        {{ error }}
      </b-alert>
    </div>
    <p v-if="admittedOffers">
      <b> You have an offer(s) of admission to the following program(s): </b>
    </p>
    <b-card
      v-for="(offer, index) in admittedOffers"
      v-bind:key="offer.id"
      class="mt-2"
    >

      <ProgramDetails :offer="offer"/>

      <b-card-body class="card-body">
        <div style="margin: 20px">
          <div>
            <b>Please tell us if you will accept this offer:</b>
          </div>

          <b-form-radio
            v-model="admittedDecisions[index].admissionOfferCode"
            value="ACPT"
            :disabled="isOfferExpired(offer) || isOfferLateDeclined(admittedDecisions[index].admissionOfferCode)"
            @change="declineOtherOffers(admittedDecisions[index].id)"
          >
            Yes, I accept this offer
          </b-form-radio>
          <b-form-radio
            v-if="!isOfferLateDeclined(admittedDecisions[index].admissionOfferCode)"
            v-model="admittedDecisions[index].admissionOfferCode"
            value="DECL"
            @change="decline(admittedDecisions[index].id)"
          >
            No, I do not accept this offer
          </b-form-radio>
          <b-form-radio
            v-if="isOfferLateDeclined(admittedDecisions[index].admissionOfferCode)"
            v-model="admittedDecisions[index].admissionOfferCode"
            value="LDCL"
            :disabled="isOfferLateDeclined(admittedDecisions[index].admissionOfferCode)"            
          >
            No, I do not accept this offer
          </b-form-radio>
        </div>
      </b-card-body>
    </b-card>
    <div v-if="hasRequiredDecision">
      <div
        v-if="nonAdmittedOffers && nonAdmittedOffers.length >= 1"
        style="margin-top: 20px"
      >
        <span class="label-bold">
          You are waiting for our decision on your application for the following
          program(s):
        </span>
      </div>
      <b-card
        v-for="(offer, index) in nonAdmittedOffers"
        v-bind:key="offer.id"
        class="mt-2"
      >
        <span v-if="!notRequiredDecisionCodes.includes(offer.decisionReason)">
          <ProgramDetails :offer="offer"/>
          <b-card-body class="card-body">
            <div style="margin: 20px">
              <div>
                <b>Please tell us if you still wish to be considered:</b>
              </div>
              <b-form-radio
                v-model="nonAdmittedDecisions[index].admissionOfferCode"
                value="PEND"
                @change="
                  setPendingStatus(nonAdmittedDecisions[index].id, 'PEND')
                "
              >
                Yes, I still want UBC to consider my application for this
                program
              </b-form-radio>
              <b-form-radio
                v-model="nonAdmittedDecisions[index].admissionOfferCode"
                value="CNCL"
                @change="
                  setPendingStatus(nonAdmittedDecisions[index].id, 'CNCL')
                "
              >
                No, I want UBC to cancel my application to this program
              </b-form-radio>
            </div>
          </b-card-body>
        </span>
      </b-card>
    </div>
    <div style="margin-top: 20px; text-align: left">
      If you applied to another degree, check the status of your application in
      the "Your application" section.
    </div>
    <div style="margin-top: 20px">
      Click the "Next" button to review a summary of your decision before
      submitting it to UBC.
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {filterIntentionsWithOffersAndNode,isOfferExpired} from "@/api/filters";
import ProgramDetails from "@/components/ProgramDetails.vue";

export default {
  name: "OfferDetailWidget",
  props: {
    offerStatuses: Array,
  },
  components: {
    ProgramDetails,
  },
  computed: {
    ...mapGetters("applicant", ["selectedIntentionGroup"]),
    intentionsWithOffers() {
      return filterIntentionsWithOffersAndNode(this.selectedIntentionGroup);
    },
    isError() {
      return this.error != null;
    },
    hasRequiredDecision() {
      return (
        this.intentionsWithOffers.filter(
          (offer) =>
            offer.decisionStatus === "NODE" &&
            !this.notRequiredDecisionCodes.includes(offer.decisionReason)
        ).length > 0
      );
    },
    filteredNonAdmittedDecisions() {
      // Filter intentions with offers that have not-required decisions
      const notRequiredDecisions = this.intentionsWithOffers.filter(offer =>
      offer.decisionStatus === "NODE" &&
      this.notRequiredDecisionCodes.includes(offer.decisionReason)
      );

      // Filter non-admitted decisions based on whether their IDs exist in notRequiredDecisions
      return this.nonAdmittedDecisions.filter(nonAdmittedDecision =>
      !notRequiredDecisions.some(notRequiredDecision =>
        notRequiredDecision.id === nonAdmittedDecision.id
      )
      );
    },
  },
  data() {
    return {
      nonAdmittedOffers: [],
      nonAdmittedDecisions: [],
      admittedOffers: [],
      admittedDecisions: [],
      acceptedOffer: null,
      error: null,
      notRequiredDecisionCodes: ["ALTR", "CANC", "WITH", "NSUP"],
    };
  },
  mounted() {
    this.reset();
    this.sortOffersAndSetAcceptedOffer();
    if (this.intentionsWithOffers) {
      // If the offerStatuses property is populated set accept/decline based on offerStatus
      if (this.offerStatuses?.length) {
        this.admittedDecisions = [];

        if (this.nonAdmittedDecisions?.length) {
          this.nonAdmittedDecisions = [];
        }

        this.offerStatuses.forEach((status) => {
          let pendingOffer = this.nonAdmittedOffers?.find(
            (pending) => pending.id == status.id
          );
          if (pendingOffer != null) {
            this.nonAdmittedDecisions.push(status);
          } else {
            this.admittedDecisions.push(status);
          }
        });
      }
    }
  },
  methods: {
    reset() {
      this.admittedDecisions = [];
      this.nonAdmittedDecisions = [];
    },
    getFormattedDeadLine(deadLineDate) {
      if (deadLineDate)
        return moment(String(deadLineDate)).format("MMMM DD, YYYY");
      return "Not Specified";
    },
    isAllOfferSelected() {
      this.error = null;
      if (
        this.hasUndecidedDecision(this.admittedDecisions, "NRSP") ||
        (this.hasUndecidedDecision(this.filteredNonAdmittedDecisions, "OTH") &&
          this.hasRequiredDecision)
      ) {
        this.error =
          "Please review the program options, one or more program need your response";
        this.$emit("updateIsProcessing", false);
      }
      return this.error;
    },
    hasUndecidedDecision(decisions, code) {
      return decisions.some(
        (dec) =>
          dec.admissionOfferCode == null || dec.admissionOfferCode === code
      );
    },
    async decisionMade() {
      this.$emit("updateIsProcessing", true);
      this.isAllOfferSelected();
      if (this.error != null) {
        this.$refs.errDiv.scrollIntoView({ behavior: "smooth" });
        return;
      }

      let newOfferStatuses = [];

      if (this.admittedDecisions?.length > 0) {
        this.admittedDecisions.forEach((decision) =>
          newOfferStatuses.push(decision)
        );
      }

      if (this.nonAdmittedDecisions?.length && this.hasRequiredDecision) {
        newOfferStatuses = newOfferStatuses.concat(this.filteredNonAdmittedDecisions);
      }

      let acceptedOfferId = this.admittedDecisions.find(
        (decisionObj) => decisionObj.admissionOfferCode == "ACPT"
      )?.id;
      this.acceptedOffer = this.admittedOffers.find(
        (admittedOffer) => admittedOffer.id == acceptedOfferId
      );
      this.$emit("updateIsProcessing", false);
      this.$emit("acceptOrDeclineOffer", this.acceptedOffer, newOfferStatuses);
    },
    declineOtherOffers(id) {
      // Decline the other offers if they exist      
      if (this.admittedDecisions?.length > 1) {
        this.admittedDecisions.map((decisionObj) => {
          if (decisionObj.id != id) {
            if (!this.isOfferLateDeclined(decisionObj.admissionOfferCode)) {
              decisionObj.admissionOfferCode = "DECL";
            } 
          }
        });
      }
    },
    setPendingStatus(id, status) {
      this.nonAdmittedDecisions.map((decisionObj) => {
        if (decisionObj.id == id) decisionObj.admissionOfferCode = status;
      });
    },
    decline(id) {
      this.acceptedOffer = null;
      if (this.admittedDecisions?.length > 1) {
        this.admittedDecisions.map((decisionObj) => {
          if (decisionObj.id == id) decisionObj.admissionOfferCode = "DECL";
        });
      }
    },
    isOfferLateDeclined(admissionOfferCode) {
      return admissionOfferCode === 'LDCL';
    },
    sortOffersAndSetAcceptedOffer() {
      this.nonAdmittedOffers = [];
      this.admittedOffers = [];
      this.acceptedOffer = undefined;
      if (this.intentionsWithOffers) {
        this.intentionsWithOffers.forEach((offer) => {
          let offerStatus = {};
          offerStatus.id = offer.id;
          offerStatus.admissionOfferCode = offer.admissionOfferCode;
          if (
            offer.decisionStatus !== "NODE" &&
            offer.admissionOfferCode !== "CNCL"
          ) {
            this.admittedOffers.push(offer);
            if (offer.admissionOfferCode === "ACPT") {
              this.admittedDecisions.push(offerStatus);
              this.acceptedOffer = offer;
            } else if (
              offer.admissionOfferCode === "DECL" ||
              offer.admissionOfferCode === "LDCL" ||
              offer.admissionOfferCode === "NRSP"
            ) {
              this.admittedDecisions.push(offerStatus);
            }
          } else if (offer.decisionStatus === "NODE") {
            this.nonAdmittedOffers.push(offer);
            this.nonAdmittedDecisions.push(offerStatus);
          }
        });
      }
    },
    isOfferExpired(offer) {
      return isOfferExpired(offer);
    },
  },
};
</script>
<style lang="css" scoped>
.card-body {
  padding: 0px !important;
}

.label-bold {
  font-weight: bold;
}
</style>