<template>
<div>
  <b-modal
      id="AcceptOfferModal"
      title="Accept or Decline Your Offers of Admission"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      :hide-header-close="isProcessing"
  >
        <div v-if="showPaymentDelayMessage">
          <p>
          The payment for your accept offer fees is still processing. If you see this message for more than 24 hours, please contact ASC support.
          </p>
        </div>
        <ErrorMessageWidget
          :error="error"
          :source="ERROR_SOURCE.Accept"
          v-else-if="showErrorMessageWidget"
          @closeErrorMessage="closeErrorMessage" />

        <OfferListWidget
          @close="close"
          v-else-if="showOfferList"
          @showOfferDetails="showOfferDetails"
          @updateIsProcessing="updateIsProcessing"/>


        <OfferDetailWidget
          v-else-if="showOfferDetail"
          :offerStatuses="offerStatuses"
          @close="close"
          ref="offerDetailWidget"
          @acceptOrDeclineOffer="acceptOrDeclineOffer"
          @updateIsProcessing="updateIsProcessing"/>

        <ConfirmAcceptOfferWidget
            v-else-if="showOfferAccept"
          :offer="selectedOffer"
          :offerStatuses="offerStatuses"
          :studentID="studentID"
          @backToAcceptDecline="backToAcceptDecline"
          @close="close"
          ref="confirmAcceptOfferWidget"
          @offerAccepted="offerAccepted"
          @updateIsProcessing="updateIsProcessing"/>

        <ConfirmDeclineOfferWidget
            v-else-if="showOfferDecline"
          :offerStatuses="offerStatuses"
          @backToAcceptDecline="backToAcceptDecline"
          ref="confirmDeclineOfferWidget"
          @close="close"
          @offerDeclined="offerDeclined"
          @updateIsProcessing="updateIsProcessing"/>


        <OfferCompleteWidget
            v-else-if="showAcceptedAndPaid"
          :offer="selectedOffer"
          :paid="paid"
          @completed="completed"
          @updateIsProcessing="updateIsProcessing"
          />

        <OfferCompleteWidget
            v-else-if="showPaymentFailed"
          :offer="selectedOffer"
          :paid="paid"
          :studentID="studentID"
          @completed="completed"
          @updateIsProcessing="updateIsProcessing"
          />

    <template #modal-footer="acceptDeclineFooter">
      <b-button variant="outline-secondary" :disabled="isProcessing || isLoading" @click="close">Close</b-button>
      <template v-if="!showPaymentDelayMessage">
        <b-button name="offerListContinue" variant="primary" v-if="showOfferList" :disabled="isProcessing" @click="showOfferDetails" >Change</b-button>
        <b-button name="offerDetailContinue" variant="primary" v-if="showOfferDetail"  :disabled="isProcessing" @click="$refs.offerDetailWidget.decisionMade()">
          <b-spinner small class="align-middle" v-if="isProcessing"/>
          Next
        </b-button>
        <b-button name="submitDecision" variant="primary" v-if="showSubmitButton"  :disabled="isProcessing"
                @click=" (showOfferAccept == true) ? $refs.confirmAcceptOfferWidget.submit() : $refs.confirmDeclineOfferWidget.decline()">
          <b-spinner small class="align-middle" v-if="isProcessing"/>
          {{nextOrSubmitDecision}}
        </b-button>
        <b-button name="changeDecision" variant="primary" v-if="showOfferAccept || showOfferDecline"  :disabled="isProcessing || isLoading" @click="backToAcceptDecline">
          Change
        </b-button>
        <b-button variant="primary" v-if="showPaymentButton" @click="onClickToPayment" :disabled="isLoading">
          <b-spinner small class="align-middle" v-if="isLoading"></b-spinner>
          <span v-else>To Payment</span>
        </b-button>
      </template>
    </template>
  </b-modal>
   <PaymentRequestForm 
    ref="acceptOfferPaymentRequestForm"
    v-if="getAcceptOfferPaymentRequestInfo"
    :paymentRequestForm="getAcceptOfferPaymentRequestInfo"
    />
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { enums } from "./ErrorSourceMixin";
import ConfirmAcceptOfferWidget from './ConfirmAcceptOfferWidget.vue';
import ConfirmDeclineOfferWidget from './ConfirmDeclineOfferWidget.vue';
import ErrorMessageWidget from './ErrorMessageWidget.vue';
import OfferCompleteWidget from './OfferCompleteWidget.vue';
import OfferDetailWidget from './OfferDetailWidget.vue';
import OfferListWidget from './OfferListWidget.vue';
import PaymentRequestForm from './PaymentRequestForm.vue';

export default {
  name: 'AcceptOfferModal',
  mixins: [enums],
  components: {
    ConfirmAcceptOfferWidget,
    ConfirmDeclineOfferWidget,
    ErrorMessageWidget,
    OfferCompleteWidget,
    OfferDetailWidget,
    OfferListWidget,
    PaymentRequestForm
  },
  computed: {
    ...mapGetters("applicant", ['showOfferPostPayment', 'selectedIntentionGroup', 'acceptedOfferFeesPaymentResponseDelay']),
    ...mapGetters("payment", ['getAcceptOfferPaymentRequestInfo', 'isLoading']),
    nextOrSubmitDecision () {
      return this.depositAmount > 0 ? 'Next' : 'Submit Decision'
    },
    showSubmitButton()  {
      return (this.showOfferAccept || this.showOfferDecline) && this.depositAmount <= 0;
    },
    showPaymentButton() {
      return (this.showOfferAccept || this.showOfferDecline) && this.depositAmount > 0;
    },
    showPaymentDelayMessage() {
      if (this.selectedIntentionGroup !== null) {
        for (const item of this.selectedIntentionGroup) {
          let id = item.id;
          if (this.acceptedOfferFeesPaymentResponseDelay.indexOf(id) !== -1){
            return true;
          }
        }
      }
      return false;
    },
  },
  data() {
    return {
      isProcessing: false,
      admittedOffers: [],
      continueURL: "",
      declinedPendingOffers: [],
      error: null,
      offerStatuses: [],
      depositAmount : 0,
      paid: true,
      paymentResult: null,
      pendingOffers: [],
      selectedOffer: null,
      showAcceptedAndPaid: false,
      showErrorMessageWidget: false,
      showOfferAccept: false,
      showOfferDecline: false,
      showOfferDetail: false,
      showOfferList: true,
      showPaymentFailed: false,
      studentID: "",
    }
  },
  methods: {
    updateIsProcessing(isProcessing){
      this.isProcessing = isProcessing;
    },
    acceptOrDeclineOffer(acceptedOffer, offerStatuses) {
      this.showOfferDetail = false;
      this.selectedOffer = acceptedOffer;
      this.offerStatuses = offerStatuses;
      if (acceptedOffer) {
        this.depositAmount = acceptedOffer.depositOwing;
        this.showOfferAccept = true;
        this.showOfferDecline = false;
      }
      else {
        this.depositAmount = 0;
        this.showOfferDecline = true;
        this.showOfferAccept = false;
      }
    },
    backToAcceptDecline() {
      this.showOfferAccept = false;
      this.showOfferDecline = false;
      this.showOfferDetail = true;
    },
    submit(e) {
      e.preventDefault();
      if (this.offer?.depositOwing > 0) {
        this.$refs.form.submit();
      }
      else {
        // Persist the statuses to the API
        if (this.offerStatuses.length > 0) {
          var self=this;
          this.$store.dispatch('applicant/getUpdateOfferStatusesPromise', {
            offerStatuses: this.offerStatuses
          })
              .then(() => {
                this.offerAccepted();
              })
              .catch((err) => {
                self.error = err;
              });
        }
      }
    },
    close() {
      this.error = null;
      this.selectedOffer = null;
      this.showAcceptedAndPaid = false;
      this.showErrorMessageWidget = false;
      this.showOfferAccept = false;
      this.showOfferDecline = false;
      this.showOfferDetail = false;
      this.showOfferList = true;
      this.showPaymentFailed = false;
      this.offerStatuses = [];
      this.$bvModal.hide("AcceptOfferModal");
    },
    completed() {
      this.$store.commit('applicant/setShowOfferPostPayment', false);
      this.close();
    },
    offerAccepted() {
      this.showOfferAccept = false;
      this.showAcceptedAndPaid = true;
    },
    offerDeclined() {
      // Get the offers again because you just declined one
      this.completed();
    },
    showOfferDetails() {
      this.selectedOffer = null;
      this.showOfferList = false;
      this.showOfferDetail = true;
    },
    ...mapMutations(['setOffers']),    
    onClickToPayment() {          
        this.$store
        .dispatch("payment/acceptOrDeclineOffers", {
          offerStatuses: this.offerStatuses,                
        })
        .then(() => {                                                 
          if(this.getAcceptOfferPaymentRequestInfo?.paymentReqNo) {                           
            this.$refs.acceptOfferPaymentRequestForm.submit();   
            this.close();                           
          }       
        })
        .catch((error) => {
        });
    }
  },
}
</script>
<style lang="css" scoped>
.left-margin {
  margin-left: 20px;
}
</style>