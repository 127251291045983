<template>
  <div>
    <div class="h3 label-bold text-center" ref="titleDiv">
      Accept My Offer - Confirm
    </div>
    <b-alert :show="isError" variant="danger">
      <li v-for="(error, idx) in errors" :key="idx">
        {{ error }}
      </li>
    </b-alert>
    <b-container>
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
              <div>
                Please review the information below. Click the "{{buttonDisplayText}}" button to confirm your decision or click the "Change" button to modify your answer.
              </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
              <div class="text-center">
                "I am accepting my offer of admission to the following program:"
              </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-card>
      <b-container>
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="offer"/>
        </b-card>
      </b-container>
    </b-card>

    <b-container v-if="acceptedPendingOffers.length > 0" class="mt-4">
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div class="text-center">
              "I still want to be considered for the following program(s):"
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-card v-for="acceptedPendingOffer in acceptedPendingOffers" v-bind:key="acceptedPendingOffer.id" class="mt-2">
      <b-container >
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="acceptedPendingOffer"/>
        </b-card>
      </b-container>
    </b-card>

    <b-container v-if="declinedPendingOffers.length > 0" class="mt-3">
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div class="text-center">
              "I no longer want to be considered for the following program(s):"
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-card v-for="declinedPendingOffer in declinedPendingOffers" v-bind:key="declinedPendingOffer.id" class="mt-2">
      <b-container >
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="declinedPendingOffer"/>
        </b-card>
      </b-container>
    </b-card>

    <b-container class="mt-4" v-if="hasDepositOwing">
      <b-row>
        <b-col>
          <div class="text-justify" >
            The final step in accepting this offer is to pay your <b>${{offer.depositOwing}}</b> acceptance deposit. This non-refundable deposit will be applied
            to your tuition/fees and is required to hold onto your space in this program.
          </div>
          <br/>
          <div class="text-justify">
            If you are later admitted to a different UBC program and want to accept that offer, you will not need to pay this deposit again.
          </div>
          <br/>
          <div class="text-justify">
            For more information regarding UBC's deposit policies, visit the links below: <br/>
            <a href="https://www.calendar.ubc.ca/vancouver/index.cfm?tree=14,265,0,0#17933" target="_blank">
              Vancouver campus deposit policy
            </a>
            <br/>
            <a href="https://www.calendar.ubc.ca/okanagan/index.cfm?tree=14,338,0,0#15281" target="_blank">
              Okanagan campus deposit policy
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import moment from 'moment';
import {mapGetters} from "vuex";
import {filterIntentionsWithOffers} from "@/api/filters";
import ProgramDetails from "@/components/ProgramDetails.vue";

export default {
  name: 'ConfirmAcceptOfferWidget',
  props: {
    offer: Object,
    offerStatuses: Array,
    studentID: "",
  },
  components: {
    ProgramDetails,
  },
  data() {
    return {
      acceptedPendingOffers: [],
      declinedPendingOffers: [],
      errors: [],
      error: null,
    }
  },
  computed: {
    ...mapGetters("applicant", ['selectedIntentionGroup']),
    intentionsWithOffers() {
      return filterIntentionsWithOffers(this.selectedIntentionGroup);
    },
    isError() {
      return this.errors?.length > 0;
    },
    buttonDisplayText() {
      return this.hasDepositOwing ? 'Next' : 'Submit Decision';
    },
    hasDepositOwing() {
      return this.offer.depositOwing > 0;
    }
  },
  mounted() {
    if( this.intentionsWithOffers) {
      this.offerStatuses.forEach(
          offerStatus =>{
            let offer = this.intentionsWithOffers.find( offerObj => offerObj.id == offerStatus.id );
            if(offerStatus.admissionOfferCode === 'PEND'){
              this.acceptedPendingOffers.push(offer);
            }
            else if(offerStatus.admissionOfferCode === 'CNCL'){
              this.declinedPendingOffers.push(offer);
            }
          })
    }
  },
  methods: {
    formatDate(value) {
      return moment(String(value)).format('MMMM DD, YYYY')
    },

    async submit() {
      this.$emit('updateIsProcessing', true);
        // Persist the statuses to the API
        if (this.offerStatuses.length > 0) {
          var self=this;
          await this.$store.dispatch('applicant/getUpdateOfferStatusesPromise', {
            offerStatuses: this.offerStatuses,
          })
          .then(() => {
            this.$emit('offerAccepted');
          })
          .catch((err) => {
            this.showErrorMessage(err);
            self.error = err;
            this.$emit('updateIsProcessing', false);
          })
      }
      this.$emit('updateIsProcessing', false);
    },

    showErrorMessage(error) {
      if (error.response && error.response.data.status === 400) {
        // This means it's a data error
        if (error.response.data.message === "Validation error") {
          // This means it's a validation error
          this.errors = [... error.response.data.details];
          this.$refs.titleDiv.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // If it's a communication / server error go to the error widget
        this.error = error.message;
        this.$bvModal.show('ErrorMessageWidget');
      }
    },

  }
}
</script>
<style lang="css" scoped>
.left-margin {
  margin-left: 20px;
}
.label-bold {
  font-weight:bold;
}
</style>