var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"EditContactSummary","title":"Contact Summary","centered":"","scrollable":"","no-close-on-backdrop":"","no-close-on-esc":"","cancel-title":"Close","cancel-variant":"outline-secondary","size":"lg","hide-header-close":_vm.isLoading,"busy":_vm.isLoading,"dialog-class":{ busy: _vm.isLoading }},on:{"shown":_vm.loadLatestPerson,"hidden":_vm.hide,"ok":_vm.handleOk},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.isLoading)?_c('b-spinner',{staticClass:"align-middle",attrs:{"small":""}}):_c('span',[_vm._v("Save")])]},proxy:true}])},[_c('b-container',{attrs:{"fluid":""}},[_c('b-form-group',{attrs:{"label":"Legal given name","label-cols":"6"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.personToEdit.personName.legalFirstName),callback:function ($$v) {_vm.$set(_vm.personToEdit.personName, "legalFirstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"personToEdit.personName.legalFirstName"}})],1),_c('b-form-group',{attrs:{"label":"Legal family name","label-cols":"6"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.personToEdit.personName.surname),callback:function ($$v) {_vm.$set(_vm.personToEdit.personName, "surname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"personToEdit.personName.surname"}})],1),_c('b-form-group',{attrs:{"label":"Preferred name","label-cols":"6"}},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personName.preferredName.$error,
          },model:{value:(_vm.v$.personToEdit.personName.preferredName.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personName.preferredName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personName.preferredName.$model"}}),_vm._l((_vm.v$.personToEdit.personName.preferredName.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label":"Date of birth","label-cols":"6"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.birthdate),callback:function ($$v) {_vm.birthdate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"birthdate"}})],1),_c('b-form-group',{attrs:{"label":"Email address","label-cols":"6"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.personToEdit.personEmail),callback:function ($$v) {_vm.$set(_vm.personToEdit, "personEmail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"personToEdit.personEmail"}})],1),_c('b-form-group',{attrs:{"label-cols":"6"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Address Line 1"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personAddress.streetLine1.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.streetLine1.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.streetLine1, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.streetLine1.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.streetLine1.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label":"Address Line 2","label-cols":"6"}},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personAddress.streetLine2.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.streetLine2.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.streetLine2, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.streetLine2.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.streetLine2.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-cols":"6"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personAddress.city.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.city.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.city, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.city.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.city.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),(
          _vm.selectedCountry != null &&
          _vm.selectedCountry.provinces &&
          _vm.provStateList &&
          _vm.provStateList.length > 0
        )?_c('b-form-group',{attrs:{"label-cols":"6"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.selectedCountry.countryCode == 'CANA')?_c('span',[_vm._v("Province/Territory")]):_vm._e(),(_vm.selectedCountry.countryCode == 'USA')?_c('span',[_vm._v("State")]):_vm._e(),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,885821287)},[_c('b-form-select',{class:{
            'is-invalid': _vm.v$.selectedProvince.$error,
          },model:{value:(_vm.v$.selectedProvince.$model),callback:function ($$v) {_vm.$set(_vm.v$.selectedProvince, "$model", $$v)},expression:"v$.selectedProvince.$model"}},_vm._l((_vm.provStateList),function(provState){return _c('b-form-select-option',{key:provState.provinceCode,attrs:{"value":provState}},[_vm._v(" "+_vm._s(provState.description)+" ")])}),1),_vm._l((_vm.v$.selectedProvince.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2):_vm._e(),_c('b-form-group',{attrs:{"label":"Country","label-cols":"6"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Country"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{class:{
            'is-invalid': _vm.v$.selectedCountry.$error,
          },on:{"change":function($event){return _vm.countryChanged()}},model:{value:(_vm.v$.selectedCountry.$model),callback:function ($$v) {_vm.$set(_vm.v$.selectedCountry, "$model", $$v)},expression:"v$.selectedCountry.$model"}},_vm._l((_vm.countries),function(country){return _c('b-form-select-option',{key:country.countryCode,attrs:{"value":country}},[_vm._v(" "+_vm._s(country.description)+" ")])}),1),_vm._l((_vm.v$.selectedCountry.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label-cols":"6"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
              _vm.selectedCountry &&
              _vm.selectedCountry.countryCode &&
              _vm.selectedCountry.countryCode == 'CANA'
            )?_c('span',[_vm._v("Postal code")]):(
              _vm.selectedCountry &&
              _vm.selectedCountry.countryCode &&
              _vm.selectedCountry.countryCode == 'USA'
            )?_c('span',[_vm._v("Zip code")]):_c('span',[_vm._v("Postal/Zip code")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personAddress.postalCode.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.postalCode.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.postalCode, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.postalCode.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.postalCode.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label":"Primary phone number","label-cols":"6"}},[_c('b-form-select',{attrs:{"options":_vm.countries,"value-field":"phoneCode","text-field":"countryPhone","size":"sm"},model:{value:(_vm.v$.personToEdit.personAddress.countryCodePrimary.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.countryCodePrimary, "$model", $$v)},expression:"v$.personToEdit.personAddress.countryCodePrimary.$model"}}),_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personAddress.telephoneDay.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.telephoneDay.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.telephoneDay, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.telephoneDay.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.telephoneDay.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label":"International phone number","label-cols":"6"}},[_c('b-form-select',{attrs:{"options":_vm.countries,"value-field":"phoneCode","text-field":"countryPhone","size":"sm"},model:{value:(_vm.v$.personToEdit.personAddress.countryCodeIntlDay.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.countryCodeIntlDay, "$model", $$v)},expression:"v$.personToEdit.personAddress.countryCodeIntlDay.$model"}}),_c('b-form-input',{class:{
            'is-invalid':
              _vm.v$.personToEdit.personAddress.internationalPhoneDay.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.internationalPhoneDay.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.internationalPhoneDay, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.internationalPhoneDay.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.internationalPhoneDay
            .$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),_c('b-form-group',{attrs:{"label":"Cellular","label-cols":"6"}},[_c('b-form-select',{attrs:{"options":_vm.countries,"value-field":"phoneCode","text-field":"countryPhone","size":"sm"},model:{value:(_vm.v$.personToEdit.personAddress.countryCodeCell.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.countryCodeCell, "$model", $$v)},expression:"v$.personToEdit.personAddress.countryCodeCell.$model"}}),_c('b-form-input',{class:{
            'is-invalid': _vm.v$.personToEdit.personAddress.cellphone.$error,
          },model:{value:(_vm.v$.personToEdit.personAddress.cellphone.$model),callback:function ($$v) {_vm.$set(_vm.v$.personToEdit.personAddress.cellphone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v$.personToEdit.personAddress.cellphone.$model"}}),_vm._l((_vm.v$.personToEdit.personAddress.cellphone.$errors),function(error){return _c('div',{key:error.$uid,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error.$message)+" ")])})],2),(_vm.person.personBiographic.ouacIdentifier != null)?_c('b-form-group',{attrs:{"label":"OUAC #","label-cols":"6"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.person.personBiographic.ouacIdentifier),callback:function ($$v) {_vm.$set(_vm.person.personBiographic, "ouacIdentifier", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"person.personBiographic.ouacIdentifier"}})],1):_vm._e(),(_vm.person.personBiographic.bcPersonalEducationNumber != null)?_c('b-form-group',{attrs:{"label":"PEN #","label-cols":"6"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.person.personBiographic.bcPersonalEducationNumber),callback:function ($$v) {_vm.$set(_vm.person.personBiographic, "bcPersonalEducationNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"person.personBiographic.bcPersonalEducationNumber"}})],1):_vm._e()],1),_c('b-alert',{attrs:{"show":_vm.isError,"variant":"danger"}},_vm._l((_vm.errors),function(error,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(error)+" ")])}),0),_c('b-alert',{attrs:{"variant":"success","show":_vm.dismissCountDown,"dismissible":""},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_vm._v(" Contact summary updated successfully ")]),_c('div',{ref:"info"},[_vm._v(" If you wish to update information in your profile that cannot be edited here, please contact us: ")]),_c('ContactQA')],1),_c('ErrorMessageWidget',{attrs:{"error":_vm.error}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }