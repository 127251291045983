<template>
  <b-modal
      id="PersonalProfileModal"
      title="Personal Profile"
      centered
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      size="xl"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      @shown="init"
      :dialog-class="{ busy: isLoading }"
  >
    <template #modal-header>
      <slot name="header">
        <h5 v-if="submitted">Submission Confirmation</h5>
        <h5 v-else>Personal Profile</h5>
      </slot>
      <button v-if="submitted" type="button" class="close" aria-label="Close" @click.prevent="closeAndReset">
        <span aria-hidden="true">&times;</span>
      </button>
      <button v-else type="button" class="close" aria-label="Close" @click.prevent="close"
              :disabled="isLoading">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <PersonalProfileForm
        :isReadmission="false"
        :isReadOnly="isReadOnly"
        :selectedDegreeProgramCode="selectedDegreeProgramCode()"
        v-on:submittedSuccesfully="submittedSuccessfully"
        @submitButtonBusy="handleSubmitButtonBusy($event)"
        ref="personalProfileForm"
    />
    <template #modal-footer>
      <b-button v-if="submitted" @click="closeAndReset" :disabled="isLoading" variant="outline-secondary">Return to ASC</b-button>
      <b-button v-if="!submitted" @click="close" :disabled="isSaving || isLoading" variant="outline-secondary">Close</b-button>
      <template v-if="!isPersonalProfilePaymentResponseDelay && !isReadOnly">
        <b-button v-if="!submitted && !displayPayment" :disabled="isSaving || isLoading || loadingError" variant="outline-secondary" @click="onSave">
          <b-spinner small class="align-middle" v-if="saveButtonBusy"></b-spinner>
          <span v-else>Save</span>
        </b-button>
        <b-button v-if="displayPayment" ref="toPaymentBtn" variant="primary" :disabled="isSaving || isLoading || loadingError"
                @click="toPayment">
          <b-spinner small class="align-middle" v-if="toPaymentButtonBusy"></b-spinner>
          <span v-else>To Payment</span>
        </b-button>
        <b-button v-else-if="!isLoading && !loadingError && !submitted" ref="submitBtn" variant="primary" :disabled="isSaving || isLoading || loadingError"
                @click="onSubmit">
          <b-spinner small class="align-middle" v-if="submitButtonBusy"></b-spinner>
          <span v-else>{{nextOrSubmitButton}}</span>
        </b-button>
      </template>
    </template>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PersonalProfileForm from "@/components/PersonalProfileForm";
import AdmissionsConstants from "@/api/AdmissionsConstants";
import moment from 'moment';

export default {
  name: 'personalProfileModal',
  components: {
    PersonalProfileForm
  },
  data() {
    return {
      submitted: false,
      saveButtonBusy: false,
      submitButtonBusy: false,
      toPaymentButtonBusy: false,
      isReadOnly: true,
    }
  },
  methods: {
    ...mapActions("personalProfile", ['load', 'save', 'submit', 'hide', 'reset']),
    close() {
      let canClose = this.$refs.personalProfileForm.canClose();
      if(canClose) {
        this.$bvModal.hide("PersonalProfileModal");
        this.$store.commit('personalProfile/setPersonalProfileFees', []);
        this.$store.dispatch('personalProfile/updatePersonalProfileDocType', null);
        this.submitButtonBusy = false;
      }
    },
    closeAndReset() {
      this.submitted = false;
      this.submitButtonBusy = false;
      this.$refs.personalProfileForm.closeAndReset();
      this.$bvModal.hide("PersonalProfileModal");
      this.$store.commit('personalProfile/setPersonalProfileFees', []);
      this.$store.dispatch('personalProfile/updatePersonalProfileDocType', null);
    },
    async onSave() {
      this.saveButtonBusy = true;
      await this.$refs.personalProfileForm.onSave();
      this.saveButtonBusy = false;
    },
    async onSubmit(){
      this.$refs.personalProfileForm.onSubmit();
    },
    async init() {
      await this.$refs.personalProfileForm.init();
    },
    submittedSuccessfully() {
      this.submitted = true;
    },
    toPayment() {
      this.toPaymentButtonBusy = true;
      this.$refs.personalProfileForm.toPayment();
    },
    handleSubmitButtonBusy(val) {
      this.submitButtonBusy = val;
    },
    calculateReadOnly(val){
      this.isReadOnly = (this.isDocReceived() || this.isPassedDeadline(val));
    },
    isDocReceived(){
      return !!this.filterSelectedDocument()?.[0].isDocReceived;
    },
    selectedDegreeProgramCode(){
      return this.filterSelectedDocument()?.[0].degreeProgramCode ?? null;
    },
    filterSelectedDocument(){
      if (this.selectedPersonalProfileDocType) {
        return this.documentSummary
            .filter(doc => doc.applicationType === this.procArea)
            .filter(doc => this.personalProfileDocTypeCodes?.includes(doc.miscDocType))
            .filter(doc => this.selectedPersonalProfileDocType?.includes(doc.miscDocType));
      }
    },
    isPassedDeadline(val){
      if (this.selectedPersonalProfileDocType) {
        let filtered = val.filter(i => this.selectedPersonalProfileDocType === i.docType);
        if (filtered.length){
          let deadlineStr = filtered[0]?.deadline;
          if (deadlineStr) {
            let deadline = new Date(deadlineStr);
            return moment().isAfter(deadline);
          }
        }
      }
      return false;
    },
  },
  computed: {
    ...mapGetters("personalProfile", ['isLoading', 'loadingError', 'savingError', 'isSaving','miscDocumentFee',
      'personalProfileFees', 'isPersonalProfilePaymentResponseDelay', 'selectedPersonalProfileDocType', 'personalProfileRubricMetadata']),
    ...mapGetters("applicant", ["documentSummary", "personalProfileDocTypeCodes", "personalProfileModalType"]),
    nextOrSubmitButton(){
      return this.miscDocumentFee > 0 ? 'Next' : 'Submit' ;
    },
    displayPayment(){
      return !!this.personalProfileFees?.toPayment;
    },
    procArea() {
      if (this.personalProfileModalType === "ADMS-PROFILE") {
        return AdmissionsConstants.PROCESSING_AREA_ADMISSIONS;
      } else if (this.personalProfileModalType === "EDUC-PROFILE") {
        return AdmissionsConstants.PROCESSING_AREA_EDUCATION;
      }
      return null;
    },
  },
  watch: {
    personalProfileRubricMetadata: {
      deep: true,
      immediate: true,
      handler(val) {
        this.calculateReadOnly(val);
      },
    },
  },
};
</script>
