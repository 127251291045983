<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div v-if="declinedOffers" class="h3 label-bold text-center" >
              Decline My Offer(s) - Confirm
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div class="text-justify">
              Please review the information below. Click the "Submit Decision" button to confirm your decision or click the "Change" button to modify your answer.
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div class="text-center">
              "I am declining my offer(s) of admission to the following program(s):"
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

      <b-card v-for="offer in declinedOffers" v-bind:key="offer.id" class="mt-2">
        <b-container>
          <b-card class="mb-1" no-body >
            <ProgramDetails :offer="offer"/>
          </b-card>
        </b-container>
      </b-card>
    <b-container v-if="acceptedPendingOffers.length > 0" class="mt-4 mb-0">
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div class="text-center">
              "I still want to be considered for the following program(s):"
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-card v-for="acceptedPendingOffer in acceptedPendingOffers" v-bind:key="acceptedPendingOffer.id" >
      <b-container >
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="acceptedPendingOffer"/>
        </b-card>
      </b-container>
    </b-card>

    <b-container v-if="declinedPendingOffers.length > 0" class="mt-4 mb-0">
      <b-row>
        <b-col>
          <b-form-group
              label-size="sm"
              label-align-sm="center"
          >
            <div class="text-center">
              "I no longer want to be considered for the following program(s):"
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-card v-for="declinedPendingOffer in declinedPendingOffers" v-bind:key="declinedPendingOffer.id">
      <b-container class="mt-2">
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="declinedPendingOffer"/>
        </b-card>
      </b-container>
    </b-card>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from "moment";
import {filterIntentionsWithOffers} from "@/api/filters";
import ProgramDetails from "@/components/ProgramDetails.vue";

export default {
  name: 'ConfirmDeclineOfferWidget',
  props: {
    offerStatuses: Array,
  },
  components: {
    ProgramDetails,
  },
  computed: {
    ...mapGetters("applicant", ['selectedIntentionGroup']),
    intentionsWithOffers() {
      return filterIntentionsWithOffers(this.selectedIntentionGroup);
    }
  },
  data() {
    return {
      declinedOffers: [],
      acceptedPendingOffers: [],
      declinedPendingOffers: [],
      error: null
    }
  },
  mounted() {
    this.sortOffers();
  },
  methods: {
    formatDate(value) {
      return moment(String(value)).format('MMMM DD, YYYY')
    },
    backToAcceptDecline() {
      this.$emit('backToAcceptDecline');
    },
    close() {
      this.$emit('close');
    },
    async decline() {
      this.$emit('updateIsProcessing', true);
      if (this.offerStatuses.length > 0) {
        var self=this;
        await this.$store.dispatch('applicant/getUpdateOfferStatusesPromise', {
          offerStatuses: this.offerStatuses,
        })
        .catch((err) => {
          self.error = err;
          this.$emit('updateIsProcessing', false);
        });
      }

      if (!this.error) {
        this.$emit('offerDeclined');
      }
      this.$emit('updateIsProcessing', false);
    },
    sortOffers() {
      if( this.intentionsWithOffers ) {
        this.offerStatuses.forEach(
            offerStatus =>{
              let offer = this.intentionsWithOffers.find( offer => offer.id == offerStatus.id );
              if(offerStatus.admissionOfferCode === 'PEND'){
                this.acceptedPendingOffers.push(offer);
              }
              else if(offerStatus.admissionOfferCode === 'CNCL'){
                this.declinedPendingOffers.push(offer);
              }
              else if(offerStatus.admissionOfferCode === 'DECL' || offerStatus.admissionOfferCode === 'LDCL'){
                this.declinedOffers.push(offer);
              }
            })
      }
    },
  }
}
</script>
<style lang="css" scoped>
.card-body {
  padding: 0px;
  /*padding: 0px!important;*/
}
.left-margin {
  margin-left: 20px;
}
.label-bold {
  font-weight:bold;
}

</style>