import api from "../../api/backend-api";

const getDefaultState = () => {
    return {       
        isLoading: false,
        payFeesPaymentRequest: {},
        acceptOfferPaymentRequest: {},
        supplementalFeeRequest: {},
        personalProfileFeeRequest: {},
        reAdmissionPaymentRequest: {},
    };
};

const state = getDefaultState();

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    getPayFeesPaymentRequestInfo() {
        return state.payFeesPaymentRequest;
    },
    getAcceptOfferPaymentRequestInfo() {
        return state.acceptOfferPaymentRequest;
    },
    getSupplementalFeeRequestInfo() {
        return state.supplementalFeeRequest;
    },
    getPersonalProfileFeeRequestInfo() {
        return state.personalProfileFeeRequest;
    },
    getReAdmissionPaymentRequest() {
        return state.reAdmissionPaymentRequest;
    },
};

const actions = {
    resetState({ commit }) {
        commit('SET_DEFAULT');
    },
    payFees({ commit }, feesAmt) {
        return new Promise((resolve, reject) => {
            if (feesAmt) {
                commit('SET_IS_LOADING', true);
                api.payFees()
                    .then(response => {                        
                        commit("SET_PAYFEES_PAYMENT_REQUEST", response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        console.log('Error caught in payFees: ' + JSON.stringify(error));
                        reject(error);
                    })
                    .finally(() => {
                        commit('SET_IS_LOADING', false);
                    });
            }
        });
    },
    acceptOrDeclineOffers({ commit }, submission) {        
        return new Promise((resolve, reject) => {                                   
            let offerStatuses = submission.offerStatuses;
            commit('SET_IS_LOADING', true);
            api.acceptOrDeclineOffers(offerStatuses)
                .then(response => {
                    commit("SET_ACCEPTOFFER_PAYMENT_REQUEST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    console.log('Error caught in acceptOrDeclineOffers: ' + JSON.stringify(error));
                    reject(error);
                })
                .finally(() => {
                    commit('SET_IS_LOADING', false);
                });            
        });
    },
    supplementalFee({ commit }, submission) {
        return new Promise((resolve, reject) => {
            commit('SET_IS_LOADING', true);
            api.supplementalFee(submission)
                .then(response => {
                    commit("SET_SUPPLEMENTAL_PAYMENT_REQUEST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    console.log('Error caught in supplementalFee: ' + JSON.stringify(error));
                    reject(error);
                })
                .finally(() => {
                    commit('SET_IS_LOADING', false);
                });
        });
    },
    personalProfileFee({ commit }, submission) {
        return new Promise((resolve, reject) => {
            commit('SET_IS_LOADING', true);
            api.personalProfileFee(submission)
                .then(response => {
                    commit("SET_PERSONALPROFILE_PAYMENT_REQUEST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    console.log('Error caught in personalProfileFee: ' + JSON.stringify(error));
                    reject(error);
                })
                .finally(() => {
                    commit('SET_IS_LOADING', false);
                });
        });
    },
    reAdmissionPayment({ commit }, submission) {
        return new Promise((resolve, reject) => {
            commit('SET_IS_LOADING', true);
            api.reAdmissionPayment(submission)
                .then(response => {                    
                    commit("SET_READMISSION_PAYMENT_REQUEST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    console.log('Error caught in personalProfileFee: ' + JSON.stringify(error));
                    reject(error);
                })
                .finally(() => {
                    commit('SET_IS_LOADING', false);
                });
        });
    },
};

const mutations = {
    SET_DEFAULT(state) {
        Object.assign(state, getDefaultState());
    },
    SET_IS_LOADING(state, payload) {
        state.isLoading = payload;
    },
    SET_PAYFEES_PAYMENT_REQUEST(state, payload) {
        state.payFeesPaymentRequest = payload;
    }, 
    SET_ACCEPTOFFER_PAYMENT_REQUEST(state, payload) {
        state.acceptOfferPaymentRequest = payload;
    },
    SET_SUPPLEMENTAL_PAYMENT_REQUEST(state, payload) {
        state.supplementalFeeRequest = payload;
    },
    SET_PERSONALPROFILE_PAYMENT_REQUEST(state, payload) {
        state.personalProfileFeeRequest = payload;
    },
    SET_READMISSION_PAYMENT_REQUEST(state, payload) {
        state.reAdmissionPaymentRequest = payload;
    },
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};