<template>
  <div>
    <div class="text-center my-2" v-if="intentionsWithOffers.length ==0 ">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div v-else>
    <div v-for="(offer, index) in intentionsWithOffers" v-bind:key="offer.id">
      <b-card class="mb-2" v-if="offer.intentionDecisionStatus !== 'NODE'" >
        <template #header>
          <h6>{{getHeader(offer)}}</h6>
        </template>
          <b-card-body>
            <b-card-text>
              <span v-if="offer.admissionOfferCode === 'DECL' || offer.admissionOfferCode === 'LDCL'">
                You have declined your offer to this program.
              </span>
              <span v-else-if="offer.admissionOfferCode === 'ACPT'">
                You have accepted your offer to this program.
              </span>
              <span v-else-if="offer.admissionOfferCode === 'NRSP'">
                <span v-if="displayOfferedMessage(offer)">
                  You have been offered admission to this program. Since it is past the program deadline, you are no longer able to accept this offer.
                </span>
                <span v-else>
                  You have been offered admission to this program. To accept your offer of admission, please click the 'Change' button below.
                </span>
              </span>
              </b-card-text>
            <b-card-text v-if="(offer.offerDeadline)" >
              Deadline to Accept Admission Offer: {{formatDate(offer.offerDeadline)}}
            </b-card-text>
            <b-card-text v-else >
              Deadline to Accept Admission Offer: Not Specified
            </b-card-text>
          </b-card-body>
      </b-card>
    </div>
    </div>
    <p>
      If you applied to another degree, check the status of your application in the "Your application" section.
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import {filterIntentionsWithOffers, isOfferExpired} from "@/api/filters";
export default {
  name: 'OfferListWidget',
  computed: {
    ...mapGetters("applicant", ['selectedIntentionGroup']),
    intentionsWithOffers() {
      let intentionsUnsorted =  filterIntentionsWithOffers(this.selectedIntentionGroup);
      return intentionsUnsorted.sort(function(a, b) {
        if(a.decisionStatus === b.decidecisionStatus){
          return 0;
        } else if(a.decisionStatus !== "NODE"){
          return 1;
        } else {
          return -1;
        }
      });
    },
  },

  methods: {
    displayOfferedMessage(intention) {
      return isOfferExpired(intention);
    },
    formatDate(value) {
      return moment(String(value)).format('MMMM DD, YYYY')
    },
    hasRespondedToOffer() {
      return this.intentionsWithOffers?.some(offer =>
         offer.admissionOfferCode === 'ACPT' || offer.admissionOfferCode === 'DECL' || offer.admissionOfferCode === 'LDCL'
      );
    },
    stopProcessing() {
      this.$emit('updateIsProcessing', false);
      if(!this.hasRespondedToOffer()) {
        this.showOfferDetails();
      }
    },
    getHeader(offer){
      this.stopProcessing();
      const code = offer.specialization1Code ? "(" + offer.specialization1Code + ") " : "";
      return offer.degreeProgram + code +  " - " + offer.campus
    },
    close() {
      this.$emit('close');
    },
    showOfferDetails() {
      this.$emit('showOfferDetails');
    },
  }
}
</script>
<style lang="css" scoped>

</style>