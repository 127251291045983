<template>
  <form :action="paymentUrl" method="POST" ref="paymentRequestForm">
    <input type="hidden" name="merchantId" :value="paymentRequestForm.merchantId"/> 
    <input type="hidden" name="merchantStoreId" :value="paymentRequestForm.merchantStoreId"/> 
    <input type="hidden" name="paymentRequestNumber" :value="paymentRequestForm.paymentReqNo"/> 
    <input type="hidden" name="paymentRequestAmount"  :value="getAmount"/> 
    <input type="hidden" name="proxyHash" :value="paymentRequestForm.proxyHash"/> 
  </form>
</template>

<script>
export default {
  name: "PaymentRequestForm",
  props: {
    paymentRequestForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentUrl: "#",  
    }
  },
  mounted() {    
    this.getUpayPaymentUrl();
  },
  computed: {
    getAmount() {
      return parseFloat(this.paymentRequestForm?.paymentReqAmt).toFixed(2);
    }
  },
  methods: {
    async getUpayPaymentUrl() {
      await this.$store.dispatch('applicant/getExternalLinkPromise', {
        linkType: 'UPAY'
      })
          .then((response) => {
            const url = response;
            if (url) {              
              this.paymentUrl = url;
            }
          });
    },
    submit() {        
      if(this.paymentRequestForm?.paymentReqNo) {              
        this.$refs.paymentRequestForm.submit();
      }
    },  
  }, 
}
</script>
