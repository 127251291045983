<template>
  <b-card class="mt-1">
    <b-card-title class="b-card-title mb-1">{{ title }}</b-card-title>
    <div>
      <b-table
        :items="docList"
        :fields="fields"
        table-variant="light"
        hover
        small
        thead-class="d-none"
        table-class="no-bottom-margin"
      >
        <template #cell(docName)="data">
          <div>
            {{ formatDocumentTitle(data.item) }}
          </div>
        </template>
        <template #cell(documentGuid)="data">
         <b-button variant="link" class="shadow-none" @click="showApplication(data.item.documentGuid)">View</b-button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import documentFormatting from "./DocumentFormatMixin";

export default {
  name: "DocumentsSupplementalApplicationCard",
  mixins: [documentFormatting],
  props: {
    title: {
      type: String,
      required: true,
    },
    supplementalDocList: {
      type: Array,
      required: true,
    },
    supplementalApplicationSummaries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "docName",
          label: "Name",
        },
        {
          key: "documentGuid",
          label: "View",
          tdClass: "view",
        },
      ],
      docList: [],
    };
  },
  mounted() {
    this.docList = this.supplementalDocList;
  },
  methods: {
    getSupplementalApplicationSummaryByGuid(docGuid) {
      return this.supplementalApplicationSummaries.find((supp) => supp.documentGuid === docGuid);
    },
    showApplication(documentGuid) {
      let suppAppSummary = this.getSupplementalApplicationSummaryByGuid(documentGuid);
      this.$store.dispatch("applicant/selectSupplementalSummary", suppAppSummary.applicationId);
      this.$bvModal.show("SupplementalApplicationSummaryModal");
    },
  },
};
</script>

<style scoped>
.b-card-title {
  font-size: large;
  font-weight: bold;
}
</style>