export const enums = {
    data() {
        return {
            ERROR_SOURCE: {
                Accept: "ACCEPT_OFFER",
                Person: "PERSON_EDIT",
                Document: "DOCUMENT_UPLOAD",
                OUAC: "OUAC",
                PEN: "PEN"
            },
        };
    },
};