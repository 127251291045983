<template>
  <b-card-header class="p-3" header-tag="header">
    <span class="label-bold">Program: </span> {{programDescription}} <br/>

    <template v-if="offer.credentialVersion">
      <span class="label-bold">Version: </span>  {{offer.credentialVersion}}<br/>
    </template>
    <template v-if="offer.specTranscriptSpecText1">
      <span class="label-bold">Specialization: </span>  {{offer.specTranscriptSpecText1}}<br/>
    </template>
    <template v-if="offer.specTranscriptSpecText2">
      <span class="label-bold">Additional Version/Specialization: </span>  {{offer.specTranscriptSpecText2}}<br/>
    </template>

    <span class="label-bold">Campus: </span>  {{offer.campus}} <br/>
    <span class="label-bold">Acceptance Deadline:</span> {{formattedDate(offer.offerDeadline)}}
  </b-card-header>
</template>

<script>

import moment from "moment";

export default {
  name: "ProgramDetails",
  props: ["offer"],
  data() {
    return {};
  },
  computed: {
    programDescription() {
      return this.offer.description || this.offer.categoryDescription || "Unclassified";
    },
  },
  methods: {
    formattedDate(d) {
      if (d) {
        return moment(String(d)).format('MMMM DD, YYYY');
      }
      return "Not Specified";
    },
  },
}
</script>

<style scoped>

</style>
