<template>
  <div>
  
    <b-card title="Application Details" :sub-title="`${applicant.firstName} ${applicant.lastName}`">

      <p>Applicant: {{ applicant.firstName }} {{ applicant.lastName }}</p>
      <p>Student number: {{ applicant.studentNumber }}</p>
      <b-list-group>
        <b-list-group-item v-for="(program, idx) in applicant.application.programChoices" :key="idx"
          variant="secondary">{{ program.id }} {{ program.description }} {{ program.status }} ({{ program.campus }})</b-list-group-item>
      </b-list-group>
      <br>Missing documents:
      <b-list-group>
        <b-list-group-item v-for="document in applicant.applicaton.documents.filter(doc => doc.uploadRequired)" :key="document"
          variant="secondary">{{ document.comment }}</b-list-group-item>
      </b-list-group>
    </b-card>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'applicant',
    computed: {
      ...mapGetters(['applicant'])
    }
}
</script>
