import Vue from 'vue'

export const namespaced = true;

const getDefaultState = () => {
    return {
        isLoading: false,
        isSaving: false,
        isDeleting: false,
        authorizations: [],
    };
};


const state = getDefaultState();

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    isSaving(state) {
        return state.isSaving;
    },
    isDeleting(state) {
        return state.isDeleting;
    },
    getAuthorizations() {
        return state.authorizations;
    },
};

const actions = {
    loadThirdPartyAuthorizations({ commit }) {
        commit('SET_IS_LOADING', true);
        Vue.prototype.$http.get('/applicant/person/contacts')
            .then(response => {
                commit('SET_AUTHORIZATIONS', response.data);
            })
            .finally(() => {
                commit('SET_IS_LOADING', false);
        });
    },
    saveThirdPartyAuthorization({commit, dispatch }, data) {
        commit('SET_IS_SAVING', true);
        Vue.prototype.$http.put('/applicant/person/contact', data)
            .then(() => {
                dispatch("loadThirdPartyAuthorizations");
            })
            .finally(() => {
                commit('SET_IS_SAVING', false);
        });
    },

    deleteThirdPartyAuthorization({commit, dispatch }, id) {
        commit('SET_IS_DELETING', true);
        Vue.prototype.$http.delete(`/applicant/person/assocUbcId/${id}`)
            .then(() => {
                dispatch("loadThirdPartyAuthorizations");
            })
            .finally(() => {
                commit('SET_IS_DELETING', false);
        });
    },
};

const mutations = {
    SET_DEFAULT(state) {
        Object.assign(state, getDefaultState());
    },
    SET_IS_LOADING(state, payload) {
        state.isLoading = payload;
    },
    SET_IS_SAVING(state, payload) {
        state.isSaving = payload;
    },
    SET_IS_DELETING(state, payload) {
        state.isDeleting = payload;
    },
    SET_AUTHORIZATIONS(state, payload) {
        state.authorizations = payload;
    },
};

export default {
    namespaced : true,
    name: "thirdPartyAuthorizations",
    state,
    getters,
    actions,
    mutations
};