import moment from "moment";
import {isPEN, isOUAC, filterUploadableDocs, isOfferExpired} from "../../api/filters.js";

export default function deriveActionItems(documentSummaries, supplementalSummaries, applications, intentions,
                                          miscDocumentRules, uploadStatuses) {
    return [].concat(
        documentsMissingAction(documentSummaries, applications, intentions, uploadStatuses),
        educationNumberAction(documentSummaries),
        supplementalAction(documentSummaries, supplementalSummaries),
        personalProfileAction(documentSummaries, miscDocumentRules),
        payFees(applications),
        offerAction(intentions),
        graduateStudiesOnlineApplication(intentions),
    );
}

function graduateStudiesOnlineApplication(intentions) {
    const actions = [];
    if (intentions.filter(i => i.decisionStatus === "ADMT" && i.admissionOfferCode === "ACPT" && displayExternalLinkBtnByProcArea(i.processingArea)).length > 0) {
        actions.push({key: "GRADUATE-STUDIES-ONLINE-APPLICATION"});
    }
    return actions;
}

function displayExternalLinkBtnByProcArea(processingArea) {
    return processingArea === "GRAD" || processingArea === "GRDO" || processingArea === "ENGR";     
}

function educationNumberAction(documents) {
    const actions = [];
    documents.filter(document => {
        return document.docRequired === "Required" && document.docType === "Miscellaneous Documents" && !document.isDocReceived;
    })
        .forEach(document => {
            if (isPEN(document)) {
                actions.push({key: "PEN"});
            } else if (isOUAC(document)) {
                actions.push({key: "OUAC"});
            }
        });
    return actions;
}

function payFees(applications) {
    const actions = [];
    // Received is not checked (aka not waived) AND (Fee - Amount Paid) > 0
    applications.filter(application => !application.applicationFeeMet)
        .forEach(application => {
            let fee = parseFloat(application.applicationFeeCategory);
            let paid = parseFloat(application.applicationFee);
            if (!isNaN(fee) && !isNaN(paid) && (fee - paid) > 0) {
                actions.push({key: "FEES"});
            }

        });
    return actions;
}

function documentsMissingAction(documents, applications, intentions, uploadStatuses) {
    let actions = [];
    let afterFilter = filterUploadableDocs(documents, applications, intentions, uploadStatuses);
    if (afterFilter?.length > 0){
        actions.push({key: "DOCUMENT"});
    }
    return actions;
}

function supplementalAction(documents, supplementalSummaries) {
    const actions = [];
    supplementalSummaries.forEach(supplemental => {documents
           .filter(document => isSupplementalDocument(document, supplemental) && isSupplementalOpen(supplemental) && document.docRequired === "Required")
           .every(document =>  actions.push({key: "SUPPLEMENTAL", name: supplemental.accessDescription, id: supplemental.applicationId}))
    });
    return actions;
}

function isSupplementalDocument(document, supplementalSummary) {
    return document.documentGuid == supplementalSummary.documentGuid && !document.isDocReceived  && !document.isDocArrived;
}

function isSupplementalOpen(supplementalSummary){
    let deadline = supplementalSummary.extendedDeadline ? supplementalSummary.extendedDeadline : supplementalSummary.deadline;
    return afterDateOrSame(supplementalSummary.openDate) && beforeDateOrSame(deadline);
}

export function offerAction(intentions) {
    return intentions.reduce((actions, i) => {
        if (i.hasOffer && (!isOfferExpired(i)) && i.admissionOfferCode !== 'DECL' && i.admissionOfferCode !== 'LDCL' && i.admissionOfferCode !== 'ACPT' && !actions.length){
          actions.push({key: "OFFER"});
        }
        return actions;
    }, []);
}

function afterDateOrSame(date) {
    return date === undefined || moment().startOf('day').isSameOrAfter(date);
}

function beforeDateOrSame(date){
    return date === undefined || moment().startOf('day').isSameOrBefore(date)
}

function personalProfileAction(documents, miscDocumentRules) {
    const actions = new Set();
    documents.filter(document => {
        return !(isPEN(document) || isOUAC(document)) && document.docType === 'Miscellaneous Documents';
    })
        .forEach(document => {
            if (missingPersonalProfile(document, miscDocumentRules, "ADMS")) {
                actions.add("ADMS-PROFILE");
            } else if (missingPersonalProfile(document, miscDocumentRules, "EDUC")) {
                actions.add("EDUC-PROFILE");
            }
        });

    return Array.from(actions, (element) => {
        return {key: element};
    });
}

function missingPersonalProfile(document, miscDocumentRules, admissProcArea) {
    return miscDocumentRules.find(rule => {
        return rule.documentType === document.miscDocType && rule.processingArea === admissProcArea && !document.isDocReceived;
    });
}
