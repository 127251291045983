<template>
  <page-load-overlay :show="showOverlay">
  <section>
    <h6>Previous UBC Registration</h6>
    <p>
      This application form is intended for current and previous UBC students
      submitting an application for re-admission to an undergraduate program or
      to change their current undergraduate program
    </p>
    <b-alert :show="isError" variant="danger">
        {{ error }}
      </b-alert>            
    <div>
      <strong> Please select: </strong>
      <b-row class="ml-2 mt-2">
        <b-col cols="7"
          >I am currently registered at UBC or have registered during the
          current academic session (ie. since Sept)
          <span class="text-danger">*</span>
        </b-col>
        <input
          type="radio"
          id="isCurrentlyRegistered"
          value="Yes"
          v-model="prevUbcRegistrationDetails.isCurrentlyRegistered"
          @change="onChangeCurrentlyRegistered()"
        />
        <label class="ml-2 mt-2">Yes</label>
      </b-row>
      <b-row class="ml-2 mt-2">
        <b-col cols="7"
          >I have been registered in previous UBC academic sessions, but I am
          not currently registered. <span class="text-danger">*</span>
        </b-col>
        <input
          type="radio"
          id="isPreviouslyRegistered"
          value="Yes"
          v-model="prevUbcRegistrationDetails.isPreviouslyRegistered"
          @change="onChangePreviouslyRegistered()"
        />
        <label class="ml-2 mt-2">Yes</label>
      </b-row>
      <b-card no-body class="mb-1 mt-3">
        <b-card-header v-b-toggle="'program-details'" block>
          <ChevronDown class="when-opened" aria-hidden="true" />
          <ChevronRight class="when-closed" aria-hidden="true" />
          <span>Most recent registered program</span>
        </b-card-header>
        <b-collapse :id="'program-details'" :visible="true">
          <b-card-body>
            <b-row class="listRow">
              <b-col cols="3">Campus <span class="text-danger">*</span> </b-col>
              <b-col cols="6">
                <b-form-select
                  :options="campusOptions"
                  v-model.trim="v$.prevUbcRegistrationDetails.campus.$model"
                  @change="onChangeCampus(prevUbcRegistrationDetails.campus)"
                  :class="{
                    'is-invalid':
                      v$.$dirty && v$.prevUbcRegistrationDetails.campus.$error,
                  }"
                ></b-form-select>
                <div
                  class="invalid-feedback"
                  v-for="error of v$.prevUbcRegistrationDetails.campus.$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </b-col>
            </b-row>
            <b-row class="listRow">
              <b-col cols="3"
                >Student type <span class="text-danger">*</span>
              </b-col>
              <b-col cols="6">
                <b-form-select
                  :options="studentTypeOptions"
                  v-model.trim="
                    v$.prevUbcRegistrationDetails.studentType.$model
                  "
                  @change="onChangeStudentType()"              
                  :class="{
                    'is-invalid':
                      v$.$dirty &&
                      v$.prevUbcRegistrationDetails.studentType.$error,
                  }"
                ></b-form-select>
                <div
                  class="invalid-feedback"
                  v-for="error of v$.prevUbcRegistrationDetails.studentType
                    .$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </b-col>
            </b-row>
            <b-row class="listRow" v-if="isStudentTypeUndergraduate">
              <b-col cols="3"
                >Program <span class="text-danger">*</span>
              </b-col>
              <b-col cols="6">
                <b-form-select
                  :options="ubcDegreeOptions"
                  v-model.trim="
                    v$.prevUbcRegistrationDetails.degreeChoiceCode.$model
                  "
                  :class="{
                    'is-invalid':
                      v$.$dirty &&
                      v$.prevUbcRegistrationDetails.degreeChoiceCode.$error,
                  }"
                ></b-form-select>
                <div
                  class="invalid-feedback"
                  v-for="error of v$.prevUbcRegistrationDetails.degreeChoiceCode
                    .$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </b-col>
            </b-row>
            <b-row class="listRow">
              <b-col cols="3"
                >Most recent session <span class="text-danger">*</span>
              </b-col>
              <b-col cols="6">
                <b-form-select
                  :options="mostRecentSessionOptions"
                  v-model.trim="
                    v$.prevUbcRegistrationDetails.mostRecentSession.$model
                  "
                  :class="{
                    'is-invalid':
                      v$.$dirty &&
                      v$.prevUbcRegistrationDetails.mostRecentSession.$error,
                  }"
                ></b-form-select>
                <div
                  class="invalid-feedback"
                  v-for="error of v$.prevUbcRegistrationDetails
                    .mostRecentSession.$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
                <b-form-input
                  placeholder="Please enter the session's year YYYY."
                  v-model.trim="
                    v$.prevUbcRegistrationDetails.mostRecentSessionYear.$model
                  "
                  :class="{
                    'is-invalid':
                      v$.prevUbcRegistrationDetails.mostRecentSessionYear
                        .$error,
                  }"
                ></b-form-input>
                <div
                  class="invalid-feedback"
                  v-for="error of v$.prevUbcRegistrationDetails
                    .mostRecentSessionYear.$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>    
  </section> 
  </page-load-overlay> 
</template>

<script>
import { mapGetters } from "vuex";
import PageLoadOverlay from './PageLoadOverlay.vue';
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  integer,
  helpers,
} from "@vuelidate/validators";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "ReAdmissionPreviousUbcRegistration",
  components: {
    ChevronRight,
    ChevronDown,
    PageLoadOverlay,
  },
  data() {
    return {
      error: "",
      prevUbcRegistrationDetails: {
        isCurrentlyRegistered: "",
        isPreviouslyRegistered: "",
        campus: "",
        studentType: "",
        mostRecentSession: "",
        mostRecentSessionYear: "",
        degreeChoiceCode: "",
      },
      campusOptions: [
        { value: "", text: "- Please Choose -" },
        { value: "UBC", text: "UBC Vancouver" },
        { value: "UBCO", text: "UBC Okanagan" },
      ],
      studentTypeOptions: [
        { value: "", text: "- Please Choose -" },
        { value: "Undergraduate program", text: "Undergraduate program" },
        {
          value: "Graduate or professional program",
          text: "Graduate or professional program",
        },
        { value: "Visiting program", text: "Visiting program" },
        { value: "Unclassified program", text: "Unclassified program" },
        { value: "Exchange program", text: "Exchange program" },
        { value: "Access studies program", text: "Access studies program" },
      ],
      mostRecentSessionOptions: [
        { value: "", text: "- Please Choose -" },
        { value: "FALL", text: "FALL" },
        { value: "SUMMER", text: "SUMMER" },
        { value: "WINTER", text: "WINTER" },
      ],
      ubcDegreeOptions: [{ value: "", text: "- Please Choose -" }],
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    prevUbcRegistrationDetails: {
      campus: { required },
      studentType: { required },
      degreeChoiceCode: { required: requiredIf(function () {
        return this.isStudentTypeUndergraduate;
      })
      },
      mostRecentSession: { required },
      mostRecentSessionYear: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
        integer: helpers.withMessage("Invalid Value", integer),
      },
    },
  },
  computed: {
    ...mapGetters("reAdmission", ["programSelection", "isBusy", "savingError", "previousUbcRegistration", "currentApplication"]),
     isError() {
      return this.error != "";
    },
    showOverlay() {
      return this.isBusy;
    },
    isStudentTypeUndergraduate() {      
      return this.prevUbcRegistrationDetails.studentType === 'Undergraduate program';
    }
  },
  created() {
    this.$store.dispatch("reAdmission/loadProgramSelectionData");
    this.$store.dispatch("reAdmission/loadCurrentApplication");
  },
  methods: {
    onChangeCurrentlyRegistered() {
      if (this.prevUbcRegistrationDetails.isCurrentlyRegistered === "Yes") {
        this.error = "";
        this.prevUbcRegistrationDetails.isPreviouslyRegistered = "No";
      }
    },
    onChangePreviouslyRegistered() {
      if (this.prevUbcRegistrationDetails.isPreviouslyRegistered === "Yes") {
        this.error = "";
        this.prevUbcRegistrationDetails.isCurrentlyRegistered = "No";
      }
    },    
    onChangeStudentType() {
      if (!this.isStudentTypeUndergraduate) {
        this.prevUbcRegistrationDetails.degreeChoiceCode = "";
      }
    },
    onChangeCampus(campus) {
      this.getDegreeListings(campus);
    },
    getDegreeListings(campus) {
      if (!this.programSelection.degreePrograms) {
        return [];
      }
      let all = this.programSelection.degreePrograms;
      let campusRows = all.filter(
        (it) => it.campusLocationCode.trim() === campus
      );
      if (campusRows.length === 0) {
        return [];
      }
      let result = [];
      result.push({value: "", text: "- Please Choose -"});
      for (const obj of campusRows) {                
        let row = {};
        row.value = obj.id;
        row.text = obj.officialCredentialName.trim();
        result.push(row);
      }
      let sortedResult = _.sortBy(result, "text");
      this.ubcDegreeOptions = sortedResult;
    },
    formValidation() {
      if (
        this.prevUbcRegistrationDetails.isCurrentlyRegistered === "" ||
        this.prevUbcRegistrationDetails.isPreviouslyRegistered === ""
      ) {
        this.error = "Please select one of the radio buttons.";
        return false;
      }
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      }
      return this.savePreviousUbcRegistration();
    },
    onClickPrevious() {
      return true;
    },
    savePreviousUbcRegistration() {
      this.$store.commit('reAdmission/setIsBusy', true);            
      this.$store
        .dispatch(
          "reAdmission/savePreviousUbcRegistration",
          this.prevUbcRegistrationDetails
        ).then(() => {
            this.$store.commit('reAdmission/setIsBusy', false);
            if (this.savingError){
              return false;
            }
            this.$emit("forceToNext");
            return true;
          });
    },
    save(){
      return true;
    },
  },
  watch: {
    previousUbcRegistration: {
      handler: function(newValue) {
        if (newValue) {          
          this.prevUbcRegistrationDetails.isCurrentlyRegistered = newValue.isCurrentlyRegistered ? 'Yes' : 'No';
          this.prevUbcRegistrationDetails.isPreviouslyRegistered = newValue.isPreviouslyRegistered ? 'Yes' : 'No';
          this.prevUbcRegistrationDetails.campus = newValue.campus;
          this.prevUbcRegistrationDetails.studentType = newValue.studentType;
          this.prevUbcRegistrationDetails.degreeChoiceCode = newValue.degreeChoiceCode;
          this.prevUbcRegistrationDetails.mostRecentSession = newValue.mostRecentSession;
          this.prevUbcRegistrationDetails.mostRecentSessionYear = newValue.mostRecentSessionYear;
          this.getDegreeListings(newValue.campus);
        }
      },
      deep: true,
      immediate: true,
    },
    programSelection: {
      handler: function(newValue) {
        this.getDegreeListings(this.prevUbcRegistrationDetails.campus);
      },
      deep: true,
      immediate: true,
    },
    currentApplication: {
      handler: function(newValue) {
        if (newValue) {
          // to avoid create application twice
          this.$store.dispatch("reAdmission/loadPreviousUbcRegistration");
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

>>> .listRow {
  margin-top: 10px;
  margin-bottom: 6px;
}
</style>