<template>
  <b-modal
      id="PENModal"
      title="Enter PEN"
      ref="addModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="close"
  >
    <b-container fluid>
      <b-form-group label="PEN#" label-cols="6">
        <b-form-input
            v-model.trim="v$.penNum.$model"
            :class="{ 'is-invalid': v$.penNum.$error }"
        ></b-form-input>
        <div
            class="invalid-feedback"
            v-for="error of v$.penNum.$errors"
            :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </b-form-group>
    </b-container>


    <template #modal-footer="selfIdentFooter">
      <b-alert
          class="center-alert"
          variant="success"
          :show="dismissCountDown"
          fade
          @dismiss-count-down="countDownChanged">
        Saved
      </b-alert>
      <b-alert :show="isError" variant="danger">
        <li v-for="(error, idx) in errors" :key="idx">
          {{ error }}
        </li>
      </b-alert>
      <b-button variant="outline-secondary" :disabled="isLoading" class="btn-size"
                @click="close">Close
      </b-button>
      <b-button :disabled="isLoading"
                @click="saveChanges"
                variant="primary"
                class="btn-size">

        <b-spinner small class="align-middle" v-if="isLoading"></b-spinner>
        <span v-else>Save</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {maxLength, minLength, required, helpers} from "@vuelidate/validators";
import {enums} from "./ErrorSourceMixin";
import ErrorMessageWidget from './ErrorMessageWidget.vue';

const allowedInputs = (value) => {
  if (value.length > 0) {
    return new RegExp("^[0-9]*$").test(value);
  }
  return true;
};

export default {
  name: 'PENModal',
  mixins: [enums],
  components: {
    ErrorMessageWidget
  },
  computed: {
    isError() {
      return this.errors?.length > 0;
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      error: null,
      errors: [],
      penNum: "",
      validationErrorMessage: "",
      isLoading: false,
      dismissSecs: 5,
      dismissCountDown: 0
    }
  },
  validations: {
    penNum: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
      allowedInputs: helpers.withMessage("Invalid Inputs.", allowedInputs),
    },
  },
  methods: {
    close() {
      this.reset();
      this.$bvModal.hide("PENModal");
    },
    reset() {
      this.errors = [];
      this.error = null;
      this.validationErrorMessage = "";
      this.v$.$reset();
      this.penNum = "";
      this.dismissCountDown = 0
    },

    async saveChanges() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.errors = [];
        this.isLoading = true;
        await this.$store.dispatch('applicant/getUpdatePenPromise', {
          penNumber: this.penNum
        })
            .then(() => {
              // Promise successful
              this.$store.dispatch('applicant/loadDocumentSummary'); //refresh document data so action is removed
              this.close();
            })
            .catch(err => {

              this.saveErrorMessage(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
      }
    },
    saveErrorMessage(error) {
      this.errors = [...error.response.data.details];

    },
    closeErrorMessage() {
      this.error = null;
      this.close();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
</script>