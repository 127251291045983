<template>
  <section>
    <h6>Additional Information</h6>

    <p></p>
    <p></p>
    <b-form-group label="Have you ever had a failed year or were required to withdraw from UBC" label-cols="6">
      <b-form-radio v-model="localHadFailedYear" name="radio" value="true">Yes</b-form-radio>
      <b-form-radio v-model="localHadFailedYear" name="radio" value="false">No</b-form-radio>
    </b-form-group>
    <p></p>
    <p>If you would like to add any extra details about the application you are about to submit,
      please do so in the space below. (1000 characters max.)</p>
    <b-form-textarea
        v-model.trim="localComment"
        v-on:keyup="liveCountDown"
        id="textarea-large"
        rows="5"
        no-resize
    ></b-form-textarea>
    <div>
      <p>Total Remaining: <span v-bind:class="{'text-danger': generateErr }">{{ totalRemainCount }}</span></p>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ReAdmissionAdditionalInformation",
  data() {
    return {
      charLimit: 1000,
      totalRemainCount: 1000,
      generateErr: false,
    };
  },
  props: {
    hasPersonalProfile:Boolean,
  },
  computed: {
    ...mapGetters("applicant", ['person']),
    ...mapGetters("reAdmission",['hadFailedYear','additionalInformation', 'comment', 'currentApplication']),
    localHadFailedYear: {
      get() {
        return this.hadFailedYear;
      },
      set(val) {
        this.$store.commit('reAdmission/hadFailedYear', val)
      }
    },
    localComment: {
      get() {
        return this.comment;
      },
      set(val) {
        this.$store.commit('reAdmission/setComment', val)
      }
    },
  },
  created() {
    this.liveCountDown();
    // todo: load and init store value backend once per session!
  },
  methods: {
    liveCountDown: function () {
        this.totalRemainCount = this.charLimit - (this.localComment ? this.localComment.length : 0);
        this.generateErr = this.totalRemainCount < 0;
    },
    formValidation() {
      return true;
    },
    save() {
      let additionalInfo = {
        hadFailedYear : this.localHadFailedYear,
        comment : this.localComment
      }
      this.$store.dispatch("reAdmission/saveAdditionalInformation", additionalInfo);
    },
    onClickPrevious() {
      return true;
    },
  },
}
</script>

<style scoped>


</style>

