<template>
  <b-modal
      id="OUACModal"
      title="Enter OUAC"
      ref="addModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="close"
  >
    <b-container fluid>
      <b-form-group label="OUAC#" label-cols="6">
        <b-form-input
            v-model.trim="v$.ouacNum.$model"
            :class="{ 'is-invalid': v$.ouacNum.$error }"
        ></b-form-input>
        <div
            class="invalid-feedback"
            v-for="error of v$.ouacNum.$errors"
            :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </b-form-group>
    </b-container>

    <template #modal-footer="selfIdentFooter">
      <b-alert
          class="center-alert"
          variant="success"
          :show="dismissCountDown"
          fade
          @dismiss-count-down="countDownChanged">
        Saved
      </b-alert>
      <b-button variant="outline-secondary" :disabled="isLoading"
                @click="close" class="btn-size">Close
      </b-button>

      <b-button @click="saveChanges" :disabled="isLoading"
                variant="primary" class="btn-size">
        <b-spinner small class="align-middle" v-if="isLoading"></b-spinner>
        <span v-else>Save</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {enums} from "./ErrorSourceMixin";
import ErrorMessageWidget from './ErrorMessageWidget.vue';
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, minLength, required} from "@vuelidate/validators";

const allowedInputs = (value) => {
  if (value.length > 0) {
    return new RegExp("^[0-9]*$").test(value);
  }
  return true;
};

export default {
  name: 'OUACModal',
  mixins: [enums],
  components: {
    ErrorMessageWidget
  },
  computed: {
    ...mapGetters("applicant", ['ouacNumber'])
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      error: null,
      ouacNum: String,
      showValidationErrorMessage: false,
      validationErrorMessage: "",
      isLoading: false,
      dismissSecs: 5,
      dismissCountDown: 0
    }
  },
  created() {
    try {
      if (this.ouacNumber != null)
        this.ouacNum = this.ouacNumber
      else
        this.ouacNum = null;
    } catch (error) {
      this.ouacNum = null;
    }
  },
  validations: {
    ouacNum: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(11),
      allowedInputs: helpers.withMessage("Invalid Inputs.", allowedInputs),
    },
  },
  watch: {
    ouacNumber: function (newOuacNumber) {
      try {
        if (newOuacNumber != null)
          this.ouacNum = newOuacNumber.toString();
        else
          this.ouacNum = null;
      } catch (error) {
        console.log("Error caught in watch: " + error);
      }

    },
    deep: true
  },
  methods: {
    close() {
      this.reset();
      this.$bvModal.hide("OUACModal");
    },
    reset() {
      this.error = null;
      this.showValidationErrorMessage = false;
      this.validationErrorMessage = "";
      this.v$.$reset();
      this.ouacNum = "";
    },

    async saveChanges() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isLoading = true;
        await this.$store.dispatch('applicant/getUpdateOuacPromise', {
          ouac: this.ouacNum
        })
            .then(() => {
              // Promise successful
              this.$store.dispatch('applicant/loadDocumentSummary'); //refresh document data so action is removed
              this.close();
            })
            .catch(err => {
              this.saveErrorMessage(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
      }
    },
    saveErrorMessage(error) {
      this.error = error;
    },
    closeErrorMessage() {
      this.error = null;
      this.close();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
};
</script>
