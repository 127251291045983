<template>
  <div class="sub-header">
    <span class="subHeader-title">
      Welcome to UBC's Applicant Service Centre
    </span>
    <div v-if="termsOfUseAccepted === true" class="subHeader-menu">
      <form class="form-inline" method="post" :action="logoutUrl" id="ascLogoutForm" @submit="sendGlobalLogout">
        <b-dropdown right text="Edit Profile" variant="primary" class="m-2" v-if="showEditProfile()">
          <b-dropdown-item class="dropdown-text" @click="$bvModal.show('EditContactSummary')">Contact Summary
          </b-dropdown-item>
          <b-dropdown-item class="dropdown-text" @click="showThirdPartyAuthForm">Third Party Authorizations
          </b-dropdown-item>
          <b-dropdown-item class="dropdown-text" @click="showAboriginalSelfIdentification">Indigenous
            Self-Identification
          </b-dropdown-item>
          <b-dropdown-item class="dropdown-text" @click="showReAdmission" :disabled="hasActiveADMSApplication">Re-admission/Change Program
          </b-dropdown-item>
        </b-dropdown>
        <input type="hidden" name="_csrf" :value="csrfToken"/>
        <b-button v-if="(personSummary) || (applicantNotFound)" type="submit" variant="primary">Log
          out
        </b-button>
      </form>
    </div>
    <div v-if="(personSummary != null) && (termsOfUseAccepted === true)">
      <div data-test="sub-header-applicant">
        Hello {{ personSummary.preferredName ? personSummary.preferredName : personSummary.firstName }}
      </div>
      <div v-if="personSummary.studentNumber" data-test="sub-header-student-no">
        Your student number is: {{ personSummary.studentNumber }}
      </div>
    </div>
    <EditContactSummary v-if="(person != null)"/>
    <ThirdPartyAuthFormModal ref="thirdPartyAuthModal"/>
    <AboriginalSelfIdentificationModal ref="aboriginalSelfIdentificationModal"/>
    <ReAdmissionModal :id="ReAdmissionModalId" ref="reAdmissionModal"/>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import EditContactSummary from './EditContactSummary.vue'
import AboriginalSelfIdentificationModal from './AboriginalSelfIdentificationModal.vue'
import ThirdPartyAuthFormModal from './ThirdPartyAuthFormModal.vue'
import ReAdmissionModal from './ReAdmissionModal.vue'
import {addLogoutEventListener} from "../store/modules/session";

export default {
  components: {
    EditContactSummary,
    ThirdPartyAuthFormModal,
    AboriginalSelfIdentificationModal,
    ReAdmissionModal,
  },
  mounted() {
    addLogoutEventListener('ascLogoutForm');
  },
  computed: {
    csrfToken() {
      let cookie = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`));
      if (cookie === null || cookie.length < 2) {
        return "";
      } else {
        return cookie[1];
      }
    },
    logoutUrl() {
      return process.env.BASE_URL + "logout";
    },
    ...mapGetters("applicant", ['applicantNotFound', 'intentions', 'person', 'personSummary', 'termsOfUseAccepted', 'countries']),
    ...mapGetters('session', {loggedIn: 'loggedIn'}),
    hasActiveADMSApplication() {
      return (this.intentions?.length && this.intentions.some(el => el.processingArea === 'ADMS'));
    },
  },
  data() {
    return {
      showEditPerson: false,
      ReAdmissionModalId: "SubHeaderReAdmissionModal",
    }
  },
  methods: {
    ...mapMutations("session", {notifyExpired: "notifyExpired", startTimer: "startTimer", stopTimer: "stopTimer"}),
    showThirdPartyAuthForm() {
      this.$refs.thirdPartyAuthModal.show();
    },
    showAboriginalSelfIdentification() {
      this.$refs.aboriginalSelfIdentificationModal.show();
    },
    showEditProfile() {
      return this.person && (this.intentions?.length > 0);
    },
    showReAdmission() {
      this.$bvModal.show(this.ReAdmissionModalId);
      this.$refs.reAdmissionModal.retrieveFormText();
    },
    sendGlobalLogout() {
      this.notifyExpired();
    }
  },
 }
</script>
<style lang="css" scoped>
.sub-header {
  width: "100%";
  text-align: left;
  margin-left: 20px;
}

.subHeader-title {
  font-size: 32px;
  font-weight: bold;
}

.subHeader-menu {
  float: right;
  margin-top: 10px;
  margin-right: 20px;
}

.dropdown-text {
  text-align: right;
}

.right-margin {
  margin-right: 10px;
}


</style>