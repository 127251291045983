<template>
  <b-card class="border-0">
    <!-- program details -->
    <div class="program-details">
      <b>Session:</b> {{ this.session }} <br>
      <template v-if="term"><b>Term:</b> {{ this.term }} <br></template>
      <template v-if="intention.credentialVersion"><b>Version:</b> {{ intention.credentialVersion }}
        <br></template>
      <template v-if="intention.specTranscriptSpecText1"><b>Specialization:</b> {{ intention.specTranscriptSpecText1 }} <br></template>
      <template v-if="intention.specTranscriptSpecText2"><b>Additional Version/Specialization:</b> {{ intention.specTranscriptSpecText2 }} <br></template>
    </div>

    <div v-if="intention.statusParagraph" v-html="intention.statusParagraph"></div>
    <b-card-body>
      <div v-if="intention.hasOffer">
        <div>
          <div>
            <b>Acceptance Status:</b> {{ offerStatus }}
            <b-button v-if="displayOfferUpdate" variant="outline-secondary" @click="showOfferModal()">
              Update
            </b-button>
          </div>
          <div v-if="displayOfferAcceptanceDeadlineDate">
            <b>Acceptance Deadline:</b> {{ offerAcceptanceDeadlineDate }}
          </div>
          <div v-if="displayAdmissionLetterLink()">
            <b>Letter of Admission: </b>
            <b-button variant="outline-secondary" @click="$bvModal.show('AdmissionLetterModal')" size="sm">
              View Letter
            </b-button>
          </div>
        </div>
        <div v-if="isTransferCreditLink  && isTCInSession">
          <b>Transfer Credit:</b> <a href="javascript:void(0)" @click="viewTransferCredit()">View transfer credit</a>
        </div>
      </div>
      <TransferCreditModal
          v-if="isTransferCreditModalVisible"
          @closeModal="hideTransferCredit"
          :transferCreditsData="transferCredits"
          :intentionSession = "intention.session"
      />
    </b-card-body>
  </b-card>
</template>
<script>
import {mapGetters} from 'vuex';
import ApplicationDetailsModal from './ApplicationDetailsModal.vue';
import TransferCreditModal from './TransferCreditModal.vue';
import AcceptOfferModal from './AcceptOfferModal.vue';
import {isOfferExpired} from "@/api/filters";
import moment from "moment";

export default {
  name: 'ApplicationStatusWidget',
  components: {
    ApplicationDetailsModal,
    TransferCreditModal,
    AcceptOfferModal
  },
  props: {
    intention: Object,
    intentionGroup: Array,
  },
  computed: {
    ...mapGetters("applicant", ["intentions"]),
    ...mapGetters("transferCredit", ['transferCredits']),
    isTransferCreditLink() {
      return this.intention?.decisionStatus === 'ADMT' &&
          this.transferCredits?.transferCredits?.length > 0 &&
          this.intention?.processingArea === 'ADMS';
    },
    isTCInSession() {
      let tcSession =[];
      if (this.transferCredits?.transferCredits?.length > 0) {
        this.transferCredits?.transferCredits.forEach((tc)=>tcSession.push(tc.sessionYear + "-" + tc.sessionCode));
      }
      return tcSession.includes(this.intention.session);
    },
    offerStatus() {
      if (this.intention?.decisionStatus === 'ADMT') {
        if (this.intention.admissionOfferCode === 'DECL' || this.intention.admissionOfferCode === 'LDCL') {
          return "Offer Declined";
        } else if (this.intention.admissionOfferCode === 'ACPT') {
          return "Offer Accepted";
        } else if (isOfferExpired(this.intention)) {
          return "Deadline Past"
        } else {
          return "No Response";
        }
      }
      return "";
    },
    offerAcceptanceDeadlineDate() {      
        return moment(String(this.intention?.offerDeadline)).format('MMMM DD, YYYY');      
    },
    displayOfferAcceptanceDeadlineDate() {
      return this.intention?.offerDeadline && !isOfferExpired(this.intention) && this.intention?.admissionOfferCode !== "ACPT" && this.intention?.admissionOfferCode !== "DECL" && this.intention?.admissionOfferCode !== "LDCL";
    },
    displayOfferUpdate() {
      return this.intention?.decisionStatus === 'ADMT' && (this.intention?.processingArea === 'ADMS' || this.intention?.processingArea === 'EDUC')
          && ((this.intention.admissionOfferCode === 'DECL' && !isOfferExpired(this.intention)) || this.intention.admissionOfferCode === 'ACPT');
    },
    term() {
      let term = "";
      const session = this.intention.session;
      const sessionArr = session.split("-");
      let sesCd = sessionArr[1];
      if (sesCd === 'S' && this.intention.term === 'A') {
        term = "Distance Education (May)";
      } else if (sesCd === 'S' && this.intention.term === 'B') {
        term = "Distance Education (July)";
      } else if (sesCd === 'W' && this.intention.term === 'A') {
        term = "Distance Education (September)";
      } else if (sesCd === 'W' && this.intention.term === 'B') {
        term = "Distance Education (November)";
      } else if (sesCd === 'W' && this.intention.term === 'C') {
        term = "Distance Education (January)";
      } else if (sesCd === 'W' && this.intention.term === 'D') {
        term = "Distance Education (March)";
      }
      return term;
    },
    session() {
      let session = this.intention.session;
      const sessionArr = session.split("-");
      let sesYr = sessionArr[0];
      let sesCd = sessionArr[1];
      let sess = sesYr;
      sess += (sesCd === "S") ? " Summer Session" : " Winter Session";
      return sess;
    },
  },
  data() {
    return {
      isTransferCreditModalVisible: false,
      }
  },
  mounted() {
    if (this.intention) {
      this.retrieveTransferCredits();
    }
  },
  methods: {
    showModal(modalId) {
      this.$bvModal.show(modalId);
    },
    displayAdmissionLetterLink() {
      return (this.intention) && (this.intention.processingArea === "ADMS") && (this.intention.offerLetterPath);
    },
    retrieveTransferCredits() {
      this.$store.dispatch("transferCredit/loadTransferCredits");
    },
    viewTransferCredit() {
      this.isTransferCreditModalVisible = true;
    },
    hideTransferCredit() {
      this.isTransferCreditModalVisible = false;
    },
    showOfferModal() {
      this.$store.dispatch("applicant/setSelectedIntentionGroup", this.intentionGroup);
      this.showModal('AcceptOfferModal');
    },
  },
}
</script>
<style scoped>

.program-details {
  padding-bottom: 25px;
}
</style>