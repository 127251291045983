<template>
  <b-modal
      id="ErrorMessageWidget"
      title="Looks like you've run into an error"
      centered
      no-close-on-backdrop
      no-close-on-esc
      scrollable
  >
    <div>
      <p>Here are some solutions that you might find useful:
      <ul>
        <li>Clear your browser history and cache and try again</li>
        <li>Use a different browser to access this action</li>
        <li>
          Use a different device (tablet, phone, laptop, computer, etc.) to
          attempt this action
        </li>
        <li>Wait 24 hours and try again</li>
      </ul>

      <p>
        If you have submitted an application but still encounter this message
        after waiting 24 hours please raise a ticket with
        <a href="https://www.askme.ubc.ca/form/s/" target="_blank"
        >Undergraduate Admissions</a
        >
        for further support
      </p>
      <div v-if="this.error!=null">
      <p>Error message is: <strong>{{ this.error }}</strong></p>
      </div>
    </div>
    <template #modal-footer>
      <b-button @click="$bvModal.hide('ErrorMessageWidget')" variant="primary">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import {enums} from "./ErrorSourceMixin";
import {mapGetters} from "vuex";

export default {
  mixins: [enums],
  props: {
    error: String,
  },
  computed: {
    ...mapGetters("session", {
      serverError: "error",
    }),
  },

  watch: {
    serverError(newValue) {
      if (newValue === true) {
        this.$bvModal.show("ErrorMessageWidget");
      }
    }
  },

};
</script>