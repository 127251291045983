<template>
  <b-modal
      size="lg"
      scrollable
      title="Re-Admission/Change of program"
      :id="id"
      class="modal-dialog modal-xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="onClose"
  >

    <template v-if="readmissionFeePaymentDelay">
      <p>
        The payment for re-admission fee is still processing. If you see this message for more than 24 hours, please contact ASC support.
      </p>
    </template>
    <template v-else>
      <div class="current-steps" v-if="this.currentStep  != this.numberOfSteps">
        <b>{{ this.currentStep + 1 }}</b> / {{ this.numberOfSteps }}
      </div>

      <div class="step-body">
        <component
          :is="stepList[this.currentStep]"
          :aboriginalSubheader=true
          :hasPersonalProfile="hasPersonalProfile"
          ref="currentComponent"
          @forceToNext="forceToNext()"
        ></component>
      </div>
    </template>

    <template #modal-footer>
      <b-button
          class="btn-size"
          variant="outline-secondary"
          @click="hideModal"
      >
        Close
      </b-button>

      <template v-if="!readmissionFeePaymentDelay">
        <b-button
          v-if="!isFirstStep"
          class="btn-size"
          @click="toPrevious"
          variant="outline-secondary">
          Previous
        </b-button>

        <b-button
          v-if="onPersonalProfileStep"
          variant="primary"
          class="btn-size"
          @click="save">
          Save
        </b-button>

        <b-button
          v-if="isLastStep"
          variant="primary"
          class="btn-size"
          @click="toPayment">
          To Payment
        </b-button>

        <b-button
          v-if="!isLastStep"
          variant="primary"
          class="btn-size"
          @click="toNext">
          {{ nextDirector }}
        </b-button>
      </template>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';

import ReAdmissionFormText from './ReAdmissionFormText.vue';
import ReAdmissionPreviousUbcRegistration from './ReAdmissionPreviousUbcRegistration.vue';
import ReAdmissionDetails from './ReAdmissionDetails.vue';
import ReAdmissionProgramSelection from './ReAdmissionProgramSelection.vue';
import AboriginalSelfIdentificationComponent from './AboriginalSelfIdentificationComponent.vue';
import ReAdmissionPreviousInstitutions from './ReAdmissionPreviousInstitutions.vue';
import ReAdmissionAdditionalInformation from './ReAdmissionAdditionalInformation.vue';
import ReAdmissionConfirmation from './ReAdmissionConfirmation.vue';
import ReAdmissionPersonalProfile from "./ReAdmissionPersonalProfile";
import ReAdmissionPaymentSummary from "./ReAdmissionPaymentSummary";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "ReAdmissionModal",
  components: {
    ReAdmissionFormText,
    ReAdmissionDetails,
    ReAdmissionPreviousUbcRegistration,    
    ReAdmissionProgramSelection,
    AboriginalSelfIdentificationComponent,
    ReAdmissionPreviousInstitutions,
    ReAdmissionAdditionalInformation,
    ReAdmissionConfirmation,
    ReAdmissionPersonalProfile,
    ReAdmissionPaymentSummary,
  },
   props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 0,
      personalProfileComponentName: "ReAdmissionPersonalProfile",
      defaultStepList: [
        'ReAdmissionFormText',
        'ReAdmissionDetails',
        'ReAdmissionPreviousUbcRegistration',        
        'AboriginalSelfIdentificationComponent',
        'ReAdmissionProgramSelection',
        'ReAdmissionPreviousInstitutions',
        'ReAdmissionAdditionalInformation',
      ],
      profileCheck: false,
    };
  },
  mounted() {
    this.$store.dispatch("reAdmission/loadReadmissionMiscDocumentRules");
  },
  computed: {
    ...mapGetters("reAdmission", ['formText', 'readmissionDocuments', 'readmissionMiscDocumentRules', 'readmissionFeePaymentDelay']),
    ...mapGetters("personalProfile", ['miscDocumentFee','personalProfileFees']),    
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return (this.currentStep + 1) === this.numberOfSteps;
    },
    onPersonalProfileStep() {
      return this.stepList.indexOf(this.personalProfileComponentName) === this.currentStep;
    },
    nextDirector() {
      if (this.isFirstStep) {
        return "Agree and continue";
      }
      if (this.onPersonalProfileStep){
        return "Submit";
      }
      return "Next";
    },
    numberOfSteps() {
      return this.stepList.length;
    },
    stepList() {
      let steps = _cloneDeep(this.defaultStepList);
      if(this.hasPersonalProfile) {
        steps.push(this.personalProfileComponentName);
      }
      // todo: confirm if we need this page if on success response mark as submitted?
      // steps.push("ReAdmissionConfirmation");
      steps.push("ReAdmissionPaymentSummary");
      return steps;
    },
    hasPersonalProfile() {
      return this.readmissionDocuments.some(doc => this.isPersonalProfile(doc));
    },
  },
  methods: {

    isPersonalProfile(doc) {
      return this.readmissionMiscDocumentRules.find(rule => {
        return rule.documentType === doc.miscDocument && rule.processingArea === doc.admissProcArea && !doc.receivedDate;
      });
    },
    onClose() {
      this.$emit('closeModal');
    },
    hideModal() {
      this.$bvModal.hide(this.id);
    },
    retrieveFormText() {
      this.$store.dispatch("reAdmission/loadFormText");
    },
    async toNext() {
      if (await this.$refs.currentComponent.formValidation()) {
        if (this.onPersonalProfileStep){
          await this.$refs.currentComponent.submit();
        }else{
          await this.$refs.currentComponent.save();
          this.currentStep = this.currentStep+1;
        }
      }
    },
    async save() {
      await this.$refs.currentComponent.save();
    },
    toPayment() {
      this.$refs.currentComponent.toPayment();
    },
    toPrevious() {
      let previousMethod = this.$refs.currentComponent.onClickPrevious;
      if (previousMethod != undefined) {
        this.$refs.currentComponent.onClickPrevious();
        this.currentStep--;
      } else {
        this.currentStep--;
      }
    },
    forceToNext() {
      this.currentStep++;
    },
  },
}
</script>

<style scoped>
>>> .current-steps {
  float: right;
}

</style>
