import store from '../store'
import Vue from "vue";

export default function setup() {
    Vue.prototype.$http.interceptors.response.use(function (config) {
        return config;
    }, function (err) {
        if (err.response.status == 500) {
            store.commit("session/setError");
        } else if (err.response.status === 401) {
            window.location.href = '/';
        } else if (err.response.status !== 401) {
            store.commit("session/resetTimer");
        }
        return Promise.reject(err);
    });
}