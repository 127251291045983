export const namespaced = true;

export const state = {
    isLoggedIn: false,
    isError:false,
}

export const getters = {
    loggedIn(state) {
        return state.isLoggedIn;
    },
    error(state){
        return state.isError;
    }
};

export const mutations = {
    setLoggedIn(state, value) {
        state.isLoggedIn = value;
    },

    resetTimer(state) {
        state.isLoggedIn = true;
    },
    notifyExpired(state) {
        state.isLoggedIn = false;
        localStorage.setItem("event-logout", 'logout-' + Math.random());
    },
    setError(state){
        state.isError=true;
    }
}

export const actions = {
    afterLogin({commit}) {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('postLogin') === "true") {
            commit('resetTimer');
            return true;
        }
        return false;
    },
};

export const addLogoutEventListener = function (logoutFormId) {
    window.addEventListener('storage', function (event) {
        if (event.key === "event-logout") {
            document.getElementById(logoutFormId).submit();
        }
    }, true);
}

