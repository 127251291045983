<template>
  <form :action="paymentUrl" method="POST" ref="paymentRequestToMultipleGLAccountForm">
    <input type="hidden" name="merchantId" :value="paymentRequestForm.merchantId"/> 
    <input type="hidden" name="merchantStoreId" :value="paymentRequestForm.merchantStoreId"/> 
    <input type="hidden" name="paymentRequestNumber" :value="paymentRequestForm.paymentReqNo"/> 
    <input type="hidden" name="paymentRequestAmount"  :value="getAmount"/> 
    <input type="hidden" name="proxyHash" :value="paymentRequestForm.proxyHash"/> 
    <div v-if="reAdmissionApplicationFeePayReq">
      <input type="hidden" name="acct01LedgerId" :value="reAdmissionApplicationFeePayReq.acctLedgerId"/> 
      <input type="hidden" name="acct01RevenueCategoryId" :value="reAdmissionApplicationFeePayReq.acctRevenueCategoryId"/> 
      <input type="hidden" name="acct01FundId" :value="reAdmissionApplicationFeePayReq.acctFundId"/> 
      <input type="hidden" name="acct01FunctionId" :value="reAdmissionApplicationFeePayReq.acctFunctionId"/> 
      <input type="hidden" name="acct01CostCenterId" :value="reAdmissionApplicationFeePayReq.acctCostCenterId"/> 
      <input type="hidden" name="acct01ProgramId" :value="reAdmissionApplicationFeePayReq.acctProgramId"/> 
      <input type="hidden" name="acct01PaymentAmount" :value="getReAdmissionApplicationFeeAmount"/> 
    </div>
    <div v-if="personalProfileFeePayReq">
      <input type="hidden" name="acct02LedgerId" :value="personalProfileFeePayReq.acctLedgerId"/> 
      <input type="hidden" name="acct02RevenueCategoryId" :value="personalProfileFeePayReq.acctRevenueCategoryId"/> 
      <input type="hidden" name="acct02FundId" :value="personalProfileFeePayReq.acctFundId"/> 
      <input type="hidden" name="acct02FunctionId" :value="personalProfileFeePayReq.acctFunctionId"/> 
      <input type="hidden" name="acct02CostCenterId" :value="personalProfileFeePayReq.acctCostCenterId"/> 
      <input type="hidden" name="acct02ProgramId" :value="personalProfileFeePayReq.acctProgramId"/> 
      <input type="hidden" name="acct02PaymentAmount" :value="getPersonalProfileFeeAmount"/>       
    </div>
    <div v-if="reAdmissionProgramFeePayReq && !personalProfileFeePayReq">
      <input type="hidden" name="acct02LedgerId" :value="reAdmissionProgramFeePayReq.acctLedgerId"/>
      <input type="hidden" name="acct02RevenueCategoryId" :value="reAdmissionProgramFeePayReq.acctRevenueCategoryId"/>
      <input type="hidden" name="acct02FundId" :value="reAdmissionProgramFeePayReq.acctFundId"/>
      <input type="hidden" name="acct02FunctionId" :value="reAdmissionProgramFeePayReq.acctFunctionId"/>
      <input type="hidden" name="acct02CostCenterId" :value="reAdmissionProgramFeePayReq.acctCostCenterId"/>
      <input type="hidden" name="acct02ProgramId" :value="reAdmissionProgramFeePayReq.acctProgramId"/>
      <input type="hidden" name="acct02PaymentAmount" :value="getReAdmissionProgramFeeAmount"/>
    </div>
    <div v-if="reAdmissionProgramFeePayReq && personalProfileFeePayReq">
      <input type="hidden" name="acct03LedgerId" :value="reAdmissionProgramFeePayReq.acctLedgerId"/>
      <input type="hidden" name="acct03RevenueCategoryId" :value="reAdmissionProgramFeePayReq.acctRevenueCategoryId"/>
      <input type="hidden" name="acct03FundId" :value="reAdmissionProgramFeePayReq.acctFundId"/>
      <input type="hidden" name="acct03FunctionId" :value="reAdmissionProgramFeePayReq.acctFunctionId"/>
      <input type="hidden" name="acct03CostCenterId" :value="reAdmissionProgramFeePayReq.acctCostCenterId"/>
      <input type="hidden" name="acct03ProgramId" :value="reAdmissionProgramFeePayReq.acctProgramId"/>
      <input type="hidden" name="acct03PaymentAmount" :value="getReAdmissionProgramFeeAmount"/>
    </div>
  </form>
</template>

<script>
export default {
  name: "PaymentRequestToMultipleGLAccountForm",
    props: {
      paymentRequestForm: {
        type: Object,
        required: true,
      },
      reAdmissionApplicationFeePayReq: {
        type: Object
      },
      personalProfileFeePayReq: {
        type: Object
      },
      reAdmissionProgramFeePayReq: {
        type: Object
      },
      paymentUrl: {
        type: String,
        required: true,
      }
  },
  computed: {
    getAmount() {      
      return parseFloat(this.paymentRequestForm?.paymentReqAmt).toFixed(2);
    },
    getReAdmissionApplicationFeeAmount() {      
      return parseFloat(this.reAdmissionApplicationFeePayReq?.paymentReqAmt).toFixed(2);
    },
    getPersonalProfileFeeAmount() {      
      return parseFloat(this.personalProfileFeePayReq?.paymentReqAmt).toFixed(2);
    },
    getReAdmissionProgramFeeAmount() {
      return parseFloat(this.reAdmissionProgramFeePayReq?.paymentReqAmt).toFixed(2);
    }
  },
  methods: {
    submit() {
      if(this.paymentRequestForm?.paymentReqNo) {
        this.$refs.paymentRequestToMultipleGLAccountForm.submit();
      }
    },  
  }, 
}
</script>
