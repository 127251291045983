class PersonalProfileData {
    #submit
    #procArea
    #applEnteredDate
    #responses

    constructor(submit, procArea, applEnteredDate, responses) {
        this.#submit = submit;
        this.#procArea = procArea;
        this.#applEnteredDate = applEnteredDate;
        this.#responses = responses;
    }

    toQueryParams(selectedDegreeProgramCode) {
        return {
            submit: this.#submit,
            processingArea: this.#procArea,
            applEnteredDate: this.#applEnteredDate,
            selectedPersonalProfileDocType: selectedDegreeProgramCode,
            answers: this.#transformResponses(),
        };
    }
    #transformResponses() {
        const result = [];
        const questionIdToResponses = [];
        for (const key in this.#responses) {
            const splitKey = key.split("::");
            const questionId = splitKey[0];
            const answerOrdinal = splitKey[1];
            const answerFieldId = splitKey[2];
            if (!questionIdToResponses[questionId]) {
                questionIdToResponses[questionId] = []
            }
            questionIdToResponses[questionId].push({
                'answerFieldId': answerFieldId,
                'ordinal': answerOrdinal,
                'response': this.#responses[key],
            });
        }

        for (const questionId in questionIdToResponses) {
            result.push({
                'questionId': questionId,
                'responses': questionIdToResponses[questionId],
            });
        }
        return result;
    }
}

export default PersonalProfileData;
