<template>
  <b-card class="mt-1">
    <b-card-title class="b-card-title mb-1"
    >{{ title }}
    </b-card-title
    >
    <div v-if="docList == null || docList.length == 0">
      <b-card-sub-title class="b-card-sub-title mt-2">There is no received document</b-card-sub-title>
    </div>
    <div v-else>
      <b-table
          :items="docList"
          :fields="fields"
          table-variant="light"
          hover
          small
          thead-class="d-none"
          table-class="no-bottom-margin"
      >
        <template #cell(docName)="data">
          <div>
            {{ formatDocumentTitle(data.item) }}
          </div>
        </template>
        <template #cell(uploadedDocument)="data">
          <div>
            {{ formatDocStatus(data.item) }}
          </div>
        </template>
        <template #cell(documentGuid)="data">
          <div v-if="data.item.transcriptUpload == 'RECD' && data.item.uploadedDocument">
            <a :href="formatDocumentLink(data.item.documentGuid)"
               target="_blank"
            >View</a
            >
          </div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import documentFormatting from "./DocumentFormatMixin";

export default {
  name: "DocumentsUndergraduateEducationCard", mixins: [documentFormatting], props: {
    title: {
      type: String,
      required: true,
    },
    procArea: {
      type: String,
      required: true,
    },
  }, computed: {
    ...mapGetters("applicant", ["documentSummary"]),
    docList(){
      let docs = [];
      for (const doc of this.documentSummary) {
        if ((doc.isDocArrived  || doc.isDocReceived ) && doc.applicationType === this.procArea && (doc.docType === "Transcript" || doc.docType === "Test Scores")) {
          docs.push(doc);
        }
      };
      return docs;
    },
  }, data() {
    return {
      isVisible: true,
      fields: [
        {
          key: "docName",
          label: "Name",
        },
        {
          key: "uploadedDocument",
          label: "Status",
          tdClass: 'status'
        },
        {
          key: "documentGuid",
          label: "View",
          tdClass: 'view'
        },
      ],
    };
  },

 methods: {
    formatDocumentLink(documentGuid) {
      return process.env.BASE_URL + "/api/document/" + documentGuid;
    },
  },
};
</script>

<style scoped>
.b-card-title {
  font-size: large;
  font-weight: bold;
}

.b-card-sub-title {
  font-weight: normal;
}

a {
  text-decoration: underline;
}
</style>