<template>
  <div id="app" style="overflow: auto; height: 100%">
    <IdleTimeOutModal v-if="!isPublicPage"/>
    <ErrorMessageWidget />
    <app-header/>
    <router-view />
    <page-footer/>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import PageFooter from "./components/PageFooter.vue"
import IdleTimeOutModal from "./components/IdleTimeOutModal.vue";
import ErrorMessageWidget from "./components/ErrorMessageWidget.vue";

export default {
  name: 'app',

  components: {
    AppHeader,
    PageFooter,
    IdleTimeOutModal,
    ErrorMessageWidget
  },
  computed: {
    isPublicPage() {
        return this.$route.meta.isPublicPage;
    },
  }
}
</script>
