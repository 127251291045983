<template>
  <div>
    <SubHeader />
    <div style="margin-left:20px;" v-if="isLoading">
      Please wait while we load your information...
    </div>
    <div v-else>
      <div v-if="personSummary == null && loggedIn === false" class="main-page-container">
        <div class="top-bottom-margin" data-test="not-logged-in">
          <b-container class="main-page-container" fluid>
            <b-row class="row-lg-12 row-md-12 row-sm-12" align-h="center">
              <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-8" order="1" align-v="start">
                <div class="left-padding">
                  <h4>If you have applied to an undergraduate program at UBC, in the Applicant Service Centre you can:</h4>
                  <ul>
                    <li>check the status of your application to UBC</li>
                    <li>see your next steps required to complete your application</li>
                    <li>upload any documents required by UBC</li>
                    <li>download your letter of admission</li>
                    <li>accept or decline your offer of admission to UBC</li>
                  </ul>
                  <h4>If you have applied to a graduate or professional program at UBC, in the Applicant Service Centre you can:</h4>
                  <ul>
                    <li>accept or decline your offer of admission to UBC</li>
                  </ul>
                </div>
                <div style="text-align:left;margin-top:10px;margin-left:20px;margin-bottom:20px">
                  <b-button v-if="(personSummary === null) && (loggedIn === false)" class="extra-wide"
                            variant="primary"
                            @click="login">Log In
                  </b-button>
                </div>
              </b-col>
              <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-4" order="2" align-v="start">
                <HaveQuestionsWidget/>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div v-else data-test="logged-in">
        <div v-if="termsOfUseAccepted === true || personSummary?.hasADMRecord === false">
          <div v-if="((intentions) && (intentions.length > 0))">
            <b-container class="main-page-container" fluid>
              <b-row class="row-lg-12" align-h="center">
                <b-col class="col-lg-6" order-lg="1" order="1" align-v="start">
                  <ApplicationStatusCard/>
                </b-col>
                <b-col class="col-lg-6" order-lg="2" order="2" align-v="start">
                  <ActionSummaryCard/>
                  <ExternalLinksCard v-if="hasUndergraduateAdmissions"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div v-else>
            <b-container class="main-page-container" fluid>
              <b-row class="row-lg-12" align-h="center">
                <b-col class="col-lg-6" align-v="start">
                  <p class="mt-3">You do not have an active application in the ASC.</p>
                  <p class="main-page-container-heading mt-4">Already applied to UBC?</p>                
                  <div>                                    
                    <span class="display-style">This application is for UBC applicants to check the status of their inflight application.</span>
                    <span class="display-style">If you recently applied to UBC through EducationPlannerBC, it can take some time for your application to appear in UBC's Applicant Service Centre.</span>
                    <span class="display-style">If you are still unable to view your application status after 48 hours, please <a href="mailto:admissions.tech@ubc.ca">contact UBC's Undergraduate Admissions Office</a></span>                  
                    <span class="display-style mt-5">If you have applied to Graduate Studies or one of the other admissions offices on campus, please contact the department to which you are applying to find out the best way to view your application's status.</span>
                  </div>                  
                </b-col>
                <b-col class="col-lg-6" align-v="start">
                  <b-card-group deck>
                    <b-card align="center" class="mb-2" v-if="this.loggedIn && !this.applicantNotFound">
                      <b-card-body >
                        <p v-if="readmissionFeePaymentDelay">
                          The payment for re-admission fee is still processing. If you see this message for more than 24 hours, please contact ASC support.
                        </p>
                        <p>
                          Applying to return to your program or change to a new
                          program?
                        </p>
                        <b-button
                          class="action-card-button"
                          variant="primary"
                          :disabled="readmissionFeePaymentDelay"
                          @click="showReAdmissionModal"                          
                        >
                          Re-admission/Change Program
                        </b-button>
                      </b-card-body>
                    </b-card>
                  </b-card-group>                  
                  <ReAdmissionModal :id="ReAdmissionModalId" ref="reAdmissionModalMainPage"/>
                  <ExternalLinksCard/>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div v-else style="margin-left:40px;margin-right:40px;margin-bottom:50px;">
          <div v-if="personSummary?.hasADMRecord === true">
            <b-card class="mt-4">
              <b-card-body style="margin:20px;">
                <form method="post" :action="logoutUrl" id="MainPageContainer-ascLogoutForm" @submit="sendGlobalLogout">
                  <span class="mt-4 mb-4 termsOfUseHeading">Acceptable Use</span>
                  <p v-html="termsOfUseMessage"></p>
                  <div class="mt-2 mr-2">
                    <input type="checkbox" v-model="acceptTermsOfUse" class="left-margin"/>
                    <label class="ml-2"> I agree</label>
                    <b-button :disabled="!acceptTermsOfUse" variant="primary" class="left-margin" @click="updateTermsOfUse">Accept
                    </b-button>
                    <b-button class="left-margin" variant="secondary" type="submit">Reject</b-button>
                    <input type="hidden" name="_csrf" :value="csrfToken"/>
                  </div>
                </form>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div><PaymentReceiptScreen ref="paymentReceiptScreen" :payment="payment"/></div>
  </div>
</template>
<script>
import ApplicationStatusCard from './ApplicationStatusCard.vue'
import ActionSummaryCard from './YourNextStepsComponent.vue'
import ExternalLinksCard from './ExternalLinksCard.vue';
import HaveQuestionsWidget from './HaveQuestionsWidget.vue';
import LinkCardWidget from './LinkCardWidget.vue';
import PaymentReceiptScreen from './PaymentReceiptScreen.vue';
import ReAdmissionModal from "./ReAdmissionModal.vue";
import SubHeader from './SubHeader.vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Vue from 'vue'
import {LayoutPlugin, TablePlugin} from 'bootstrap-vue';

Vue.use(LayoutPlugin)
Vue.use(TablePlugin)

export default {
  name: "MainPageContainer",
  components: {
    SubHeader,
    ApplicationStatusCard,
    ActionSummaryCard,
    ExternalLinksCard,
    HaveQuestionsWidget,
    LinkCardWidget,
    PaymentReceiptScreen,
    ReAdmissionModal
  },
  mounted() {
    this.afterLogin().then((result) => {
      if (result) {
        this.loadData();
        this.showPaymentReceiptScreen();
        this.interval = setInterval(() =>{
          this.loadPaymentDelayLists()},3000);
      }
    });
  },
  computed: {
    ...mapGetters("reAdmission", ['readmissionFeePaymentDelay']),
    ...mapGetters( "applicant",['applicantNotFound', 'hasUndergraduateAdmissions', 'intentions', 'isLoading', 'personSummary', 'showOfferPostPayment', 'termsOfUseAccepted', 'acceptedOfferFeesPaymentResponseDelay', 'onReloadIntentionsCloseModal']),
    ...mapGetters("session", {
      loggedIn: "loggedIn",
    }),
    csrfToken() {
      let cookie = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`));
      if (cookie === null || cookie.length < 2) {
        return "";
      } else {
        return cookie[1];
      }
    },
    logoutUrl() {
      this.$store.commit('applicant/setLoading', false);
      return process.env.BASE_URL + "logout";
    },
    outstandingPaymentCount() {
      return this.acceptedOfferFeesPaymentResponseDelay?.length;
    },
  },
  data() {
    return {
      ReAdmissionModalId: "MainPageReAdmissionModal",
      acceptTermsOfUse: false,
      termsOfUseMessage: null,
      payment: null,
      interval: null,
      loadingIntervalCount: 0,
    }
  },
  methods: {
    ...mapActions('session', {afterLogin: 'afterLogin',}),
    ...mapMutations("session", {notifyExpired: "notifyExpired"}),
    async loadData() {
      let initTasks = [];
      await this.$store.commit('applicant/setLoading', true);
      initTasks.push(this.$store.dispatch('applicant/loadReferenceData'));
      initTasks.push(this.$store.dispatch('applicant/loadApplicantSummary'));

      if (!((this.termsOfUseAccepted) && (this.termsOfUseAccepted === true))) {
        initTasks.push(this.getTermsOfUseMessage());
      }

      await Promise.all(initTasks);
      this.$store.commit('applicant/setLoading', false);
    },
    loadPaymentDelayLists() {
      if (this.loadingIntervalCount < 10) {
        this.$store.dispatch("applicant/loadDepositPaymentResponseDelayLists");
        this.loadingIntervalCount++;        
      }else{
        clearInterval(this.interval);        
      }      
    },
    getTermsOfUseMessage() {
      return this.$store.dispatch('applicant/getTermsOfUseMessagePromise')
          .then((response) => {
            this.termsOfUseMessage = response;
          })
          .catch((error) => {
            console.log("Error caught in MainPageContainer getTermsOfUseMessage: " + error);
          });
    },
    login() {
      window.location.href = process.env.BASE_URL + "/api/authenticate/login";
    },
    sendGlobalLogout() {
      this.notifyExpired();
    },
    async updateTermsOfUse() {
      if ((this.loggedIn) && (this.applicantNotFound)) {
        this.$store.commit('applicant/setTermsOfUseAccepted', true);
        return;
      }
      this.$store.dispatch('applicant/getUpdateTermsAndConditionsPromise')
          .then((response) => {
          })
          .catch((error) => {
            console.log("Error caught in MainPageContainer updateTermsOfUse: " + error);
          });
    },
    showPaymentReceiptScreen () {
      let urlParams = new URLSearchParams(location.search);
      let paymentFilter = '';
      if( urlParams.has('payment') ){
        paymentFilter = urlParams.get('payment');
      }
      if (paymentFilter === "success" || paymentFilter === "error" || paymentFilter === "cancel") {
        this.payment = paymentFilter;
        this.$bvModal.show("PaymentReceiptScreen");        
        this.$store.dispatch("reAdmission/loadCurrentApplicationForMainPage");
      }
    },
    showReAdmissionModal() {
      this.$bvModal.show(this.ReAdmissionModalId);      
      this.$refs.reAdmissionModalMainPage.retrieveFormText();      
      if (this.personSummary?.ubcId) {        
        this.$store.dispatch("reAdmission/loadCurrentApplicationForMainPage");
      }               
    },
  },
  watch: {
    outstandingPaymentCount: function (newValue, oldValue) {
      // Was delayed but no longer is, lets reload intention data
      if(oldValue >= 1 && (oldValue > newValue)) {
        this.$store.dispatch("applicant/loadIntentions");
      }
    },
    intentions: function () {
      // Close Accept offer modals as we may be in a weird state after intention load
      if (this.onReloadIntentionsCloseModal) {
        this.$bvModal.hide("AcceptOrDeclineOfferModal");
        this.$bvModal.hide("AcceptOfferModal");
        this.$bvModal.hide("DirectAcceptOfferModal");
      }
    },
    applicantNotFound: function (newApplicantNotFound) {
      this.$store.commit('applicant/setLoading', false);
    },
    termsOfUseAccepted: function (newTermsOfUseAccepted) {
      if ((newTermsOfUseAccepted) && (newTermsOfUseAccepted === true)) {
        return;
      }
      this.getTermsOfUseMessage();
    },
    deep: true
  }
};
</script>
<style lang="css" scoped>
.main-page-container {
  width: 100%;
  margin-left: 0px;
  margin-top: 10px;
  margin-right: 0px !important;
  margin-bottom: 30px;
}

.top--bottom-margin {
  margin-top: 30px;
  margin-bottom: 50px;
}

.left-padding {
  padding-left: 20px;
}

.custom-bcard-body-margin {
  margin: 20px;
}

.bottom-margin {
  margin-bottom: 20px;
}

.left-margin {
  margin-left: 10px;
}

.extra-wide {
  width: 150px;
}

.main-page-container-heading {
  font-size:24px;
  font-weight:bold;
}

.display-style {
  display:block;
}

.termsOfUseHeading {
  font-size:32px;
  font-weight:bold;
}

>>> .action-card p {
  font-size: 14px;
  height: 50px;
}
>>> .action-card .card-body {
  padding: 10px;
}

.card-deck {
  margin-bottom: 10px;
}
</style>