<template>
  <PersonalProfileForm
      :isReadmission="true"
      :isReadOnly="false"
      ref="personalProfileForm"
      @forceToNext="forceToNext()"
      />
</template>

<script>
import PersonalProfileForm from './PersonalProfileForm.vue';


export default {
  name: "ReAdmissionPersonalProfile",
  components: {
    PersonalProfileForm
  },
  mounted() {
    this.$store.dispatch("applicant/selectPersonalProfileModalType", "ADMS-PROFILE");
    this.$refs.personalProfileForm.init();
  },
  methods: {
    async formValidation() {
      return await this.$refs['personalProfileForm'].isValid();
    },
    async save() {
      return await this.$refs['personalProfileForm'].onSave();
    },
    async submit(){
      return await this.$refs['personalProfileForm'].onSubmit();
    },
    toPayment() {
      this.$refs['personalProfileForm'].toPayment();
    },
    name() {
      return "PersonalProfile";
    },
    forceToNext() {
      this.$emit("forceToNext");
    },
  }
}
</script>

