<template>
  <div>
    <b-modal
        id="EditContactSummary"
        title="Contact Summary"
        centered
        scrollable
        no-close-on-backdrop
        no-close-on-esc
        cancel-title="Close"
        cancel-variant="outline-secondary"
        size="lg"
        @shown="loadLatestPerson"
        @hidden="hide"
        @ok="handleOk"
        :hide-header-close="isLoading"
        :busy="isLoading"
        :dialog-class="{ busy: isLoading }"
    >
      <template v-slot:modal-ok>
        <b-spinner small class="align-middle" v-if="isLoading"></b-spinner>
        <span v-else>Save</span>
      </template>
      <b-container fluid>
        <b-form-group label="Legal given name" label-cols="6">
          <b-form-input
              v-model.trim="personToEdit.personName.legalFirstName"

              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Legal family name" label-cols="6">
          <b-form-input
              v-model.trim="personToEdit.personName.surname"

              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Preferred name" label-cols="6">
          <b-form-input
              v-model.trim="v$.personToEdit.personName.preferredName.$model"

              :class="{
              'is-invalid': v$.personToEdit.personName.preferredName.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personName.preferredName.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group label="Date of birth" label-cols="6">
          <b-form-input
              v-model.trim="birthdate"

              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email address" label-cols="6">
          <b-form-input
              v-model.trim="personToEdit.personEmail"

              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols="6">
          <template v-slot:label>
            Address Line 1<span class="text-danger">*</span>
          </template>
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.streetLine1.$model"

              :class="{
              'is-invalid': v$.personToEdit.personAddress.streetLine1.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.streetLine1.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group label="Address Line 2" label-cols="6">
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.streetLine2.$model"

              :class="{
              'is-invalid': v$.personToEdit.personAddress.streetLine2.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.streetLine2.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group label-cols="6">
          <template v-slot:label>
            City<span class="text-danger">*</span>
          </template>
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.city.$model"

              :class="{
              'is-invalid': v$.personToEdit.personAddress.city.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.city.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group
            v-if="
            selectedCountry != null &&
            selectedCountry.provinces &&
            provStateList &&
            provStateList.length > 0
          "
            label-cols="6"
        >
          <template v-slot:label>
            <span v-if="selectedCountry.countryCode == 'CANA'"
            >Province/Territory</span
            >
            <span v-if="selectedCountry.countryCode == 'USA'">State</span>
            <span class="text-danger">*</span>
          </template>
          <b-form-select
              v-model="v$.selectedProvince.$model"

              :class="{
              'is-invalid': v$.selectedProvince.$error,
            }"
          >
            <b-form-select-option
                v-for="provState in provStateList"
                v-bind:value="provState"
                v-bind:key="provState.provinceCode"
            >
              {{ provState.description }}
            </b-form-select-option
            >
          </b-form-select>
          <div
              class="invalid-feedback"
              v-for="error of v$.selectedProvince.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group label="Country" label-cols="6">
          <template v-slot:label>
            Country<span class="text-danger">*</span></template
          >
          <b-form-select
              v-model="v$.selectedCountry.$model"
              @change="countryChanged()"

              :class="{
              'is-invalid': v$.selectedCountry.$error,
            }"
          >
            <b-form-select-option
                v-for="country in countries"
                v-bind:value="country"
                v-bind:key="country.countryCode"
            >
              {{ country.description }}
            </b-form-select-option
            >
          </b-form-select>
          <div
              class="invalid-feedback"
              v-for="error of v$.selectedCountry.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group label-cols="6">
          <template v-slot:label>
            <span
                v-if="
                selectedCountry &&
                selectedCountry.countryCode &&
                selectedCountry.countryCode == 'CANA'
              "
            >Postal code</span
            >
            <span
                v-else-if="
                selectedCountry &&
                selectedCountry.countryCode &&
                selectedCountry.countryCode == 'USA'
              "
            >Zip code</span
            >
            <span v-else>Postal/Zip code</span>
            <span class="text-danger">*</span>
          </template>
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.postalCode.$model"

              :class="{
              'is-invalid': v$.personToEdit.personAddress.postalCode.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.postalCode.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group
            label="Primary phone number"
            label-cols="6"
        >
          <b-form-select
              v-model="v$.personToEdit.personAddress.countryCodePrimary.$model"
              :options="countries"
              value-field="phoneCode"
              text-field="countryPhone"
              size="sm"
          ></b-form-select>
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.telephoneDay.$model"

              :class="{
              'is-invalid': v$.personToEdit.personAddress.telephoneDay.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.telephoneDay.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group
            label="International phone number"
            label-cols="6"
        >
          <b-form-select
              v-model="v$.personToEdit.personAddress.countryCodeIntlDay.$model"
              :options="countries"
              value-field="phoneCode"
              text-field= "countryPhone"
              size="sm"
          ></b-form-select>
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.internationalPhoneDay.$model"

              :class="{
              'is-invalid':
                v$.personToEdit.personAddress.internationalPhoneDay.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.internationalPhoneDay
              .$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group label="Cellular" label-cols="6">
          <b-form-select
              v-model="v$.personToEdit.personAddress.countryCodeCell.$model"
              :options="countries"
              value-field="phoneCode"
              text-field="countryPhone"
              size="sm"
          ></b-form-select>
          <b-form-input
              v-model.trim="v$.personToEdit.personAddress.cellphone.$model"

              :class="{
              'is-invalid': v$.personToEdit.personAddress.cellphone.$error,
            }"
          ></b-form-input>
          <div
              class="invalid-feedback"
              v-for="error of v$.personToEdit.personAddress.cellphone.$errors"
              :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <b-form-group
            v-if="person.personBiographic.ouacIdentifier != null"
            label="OUAC #"
            label-cols="6"
        >
          <b-form-input
              v-model.trim="person.personBiographic.ouacIdentifier"

              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
            v-if="person.personBiographic.bcPersonalEducationNumber != null"
            label="PEN #"
            label-cols="6"
        >
          <b-form-input
              v-model.trim="person.personBiographic.bcPersonalEducationNumber"

              disabled
          ></b-form-input>
        </b-form-group>
      </b-container>
      <b-alert :show="isError" variant="danger">
        <li v-for="(error, idx) in errors" :key="idx">
          {{ error }}
        </li>
      </b-alert>
      <b-alert
          variant="success"
          :show="dismissCountDown"
          dismissible
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
      >
        Contact summary updated successfully
      </b-alert>
      <div ref="info">
        If you wish to update information in your profile that cannot be edited
        here, please contact us:
      </div>
      <ContactQA/>
    </b-modal>
    <ErrorMessageWidget
        :error="error"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {enums} from "./ErrorSourceMixin";
import ErrorMessageWidget from "./ErrorMessageWidget.vue";
import _cloneDeep from "lodash/cloneDeep";
import useVuelidate from "@vuelidate/core";
import ContactQA from './ContactQA.vue';

import {helpers, integer, maxLength, minLength, required, requiredIf,} from "@vuelidate/validators";

const allowedInputs = (value) => {
  if (value.length > 0) {
    return new RegExp(/^[a-zA-Z\s.'-]*$/).test(value);
  }
  return true;
};

export default {
  name: "EditContactSummary",
  mixins: [enums],
  components: {
    ErrorMessageWidget,
    ContactQA
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      personToEdit: {},
      birthdate: "",
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      provStateList: [],
      selectedCountry: null,
      selectedProvince: null,
      success: false,
      isLoading: false,
      errors: [],
      error: null,
      dismissSecs: 5,
      dismissCountDown: 0,
    };
  },
  validations: {
    personToEdit: {
      personName: {
        preferredName: {
          maxLength: maxLength(50),
          allowedInputs: helpers.withMessage("Invalid Inputs", allowedInputs),
        },
      },
      personAddress: {
        streetLine1: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(255),
        },
        streetLine2: {
          maxLength: maxLength(255),
        },
        city: {
          required,
          maxLength: maxLength(50),
        },
        postalCode: {
          required,
        },
        telephoneDay: {
          minLength: minLength(10),
          maxLength: maxLength(10),
          integer: helpers.withMessage(
              "Invalid Inputs",
              integer
          ),
        },
        cellphone: {
          minLength: minLength(10),
          maxLength: maxLength(10),
          integer: helpers.withMessage(
              "Invalid Inputs",
              integer
          ),
        },
        internationalPhoneDay: {
          maxLength: maxLength(30),
        },
        countryCodePrimary: {
          maxLength: maxLength(5),
        },
        countryCodeCell: {
          maxLength: maxLength(5),
        },
        countryCodeIntlDay:{
          maxLength: maxLength(5),
        },
      },
    },
    selectedCountry: {
      required,
    },
    selectedProvince: {
      required: requiredIf(function () {
        return (
            this.selectedCountry.countryCode == "CANA" ||
            this.selectedCountry.countryCode == "USA"
        );
      }),
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("applicant", ['person', 'countries']),
    isError() {
      return this.errors?.length > 0;
    },
  },
  created() {
    this.loadPerson(this.person);
  },
  watch: {
    countries() {
      this.loadPerson(this.person);
    }
  },
  methods: {
    init(){
      for (const country of this.countries) {
        if (!country.phoneCode) {
          country.phoneCode = "";
          country.countryPhone = country.description;
        } else {
          country.countryPhone = country.description + "-" + country.phoneCode;
        }
      }
    },
    hide() {
      this.reset();
      this.$bvModal.hide('EditContactSummary');
    },
    loadPerson(person) {
      this.personToEdit = _cloneDeep(person);

      if (person.personAddress) {
        this.loadCountryProvState(person.personAddress);
      }

      if (person.personBiographic) {
        if (person.personBiographic.dateOfBirth) {
          try {
            const d = person.personBiographic.dateOfBirth.split("-");
            this.birthdate = this.month[d[1] - 1] + " " + d[2] + ", " + d[0];
          } catch (error) {
            console.log(
                "Error converting dateOfBirth: " +
                person.personBiographic.dateOfBirth
            );
            console.log("Error is: " + error);
          }
        }
      }
    },
    loadCountryProvState(address) {
      if (address) {
        if (
            address.countryCode &&
            this.countries &&
            this.countries.length > 0
        ) {
          // Search the countries collection for a matching country code
          var matchingCountry;
          for (let country of this.countries) {
            if (address.countryCode.startsWith(country.countryCode)) {
              matchingCountry = country;
              break;
            }
          }

          if (!matchingCountry) {
            this.selectedCountry = address.countryCode;
            this.selectedProvince = address.provinceCode;
            return;
          }
          this.selectedCountry = matchingCountry;
          if (this.selectedCountry.provinces) {
            this.provStateList = this.selectedCountry.provinces;
          }
          if (
              address.provinceCode &&
              this.provStateList &&
              this.provStateList.length > 0
          ) {
            this.provStateList = matchingCountry.provinces;
            // Search the provState collection of the matching country
            for (let provState of this.provStateList) {
              if (address.provinceCode == provState.provinceCode) {
                this.selectedProvince = provState;
                break;
              }
            }
          }
        }
      }
    },
    loadLatestPerson() {
      this.loadPerson(this.person);
    },
    reset() {
      this.errors = [];
      this.v$.$reset();
      this.error = null;
      this.dismissCountDown = 0
    },
    countryChanged() {
      this.selectedProvince = null;
      if (this.selectedCountry != null && this.selectedCountry.provinces) {
        this.provStateList = this.selectedCountry.provinces;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger Save Changes
      this.saveChanges();
    },
    async saveChanges() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.reset();
        this.isLoading = true;

        if (
            this.selectedCountry.countryCode == "CANA" ||
            this.selectedCountry.countryCode == "USA"
        )
          this.personToEdit.personAddress.provinceCode =
              this.selectedProvince.provinceCode;

        this.personToEdit.personAddress.countryCode =
            this.selectedCountry.countryCode;

        await this.$store
            .dispatch("applicant/getUpdatePersonPromise", {
              personToEdit: this.personToEdit,
            })
            .then((response) => {
              this.showSuccessAlert();
              this.$refs.info.scrollIntoView({behavior: "smooth"});
            })
            .catch((error) => {
              this.showErrorMessage(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showErrorMessage(error) {
      if (error.response && error.response.data.status === 400) {
        // This means it's a data error
        if (error.response.data.message === "Validation error") {
          // This means it's a validation error
          this.errors = [...error.response.data.details];
          this.$refs.info.scrollIntoView({behavior: "smooth"});
        }
      } else {
        // If it's a communication / server error go to the error widget
        this.error = error.message;
        this.$bvModal.show('ErrorMessageWidget');
      }
    },
  },
};
</script>
<style scoped>
.busy {
  cursor: wait;
}
</style>