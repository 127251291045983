<template>
  <div>
    <ul>
      <li><a href="https://you.ubc.ca/contact-us/" target="_blank">Undergraduate admissions</a> for either UBC Okanagan or UBC Vancouver</li>
      <li>UBC Okanagan <a href="https://gradstudies.ok.ubc.ca/about/contact/" target="_blank">Graduate Studies</a> or <a href="https://education.ok.ubc.ca/about/contact/" target="_blank">School of Education</a></li>
      <li>UBC Vancouver <a href="https://www.grad.ubc.ca/contact-us" target="_blank">Graduate Studies</a> or <a href="https://teach.educ.ubc.ca/contact/hello/" target="_blank">Faculty of Education</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ContactQA"
}
</script>
