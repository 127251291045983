<template>

  <b-modal
      id="ManageDocumentsModal"
      title="Manage Your Documents"
      centered
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      size="lg"
  >
    <b-container v-if="!documentList.length">
      <span>You're not required to submit any documents</span>
    </b-container>
    <b-container v-else-if="showUnsubmittedDocsWarning">
      <UnsubmittedDocumentWidget
          :documentList="getUnsubmittedDocs(documentList)"
          @close="close"
          @closeUnsubmittedDocsWarning="closeUnsubmittedDocsWarning"/>
    </b-container>
    <b-container v-else>
      <b-row>
        <p>Please upload and submit the following documents to complete your application to UBC.
          Documents submitted are considered unofficial and if admitted an official transcript
          must be received to confirm your offer of admission.</p>
      </b-row>
      <b-row class="mb-3">
        <span class="w-100">Accepted file formats: DOC, JPEG, PDF or TIFF</span>
        <span>Maximum file size: 2MB</span>
      </b-row>

      <table class="document-table">
        <tbody>
        <tr class="document-table-row" v-for="(doc, index) in documentList" v-bind:key="doc.id">
          <td class="document-table-cell">
            <div>
              <span class="document-type">{{ formatDocumentTitle(doc) }}</span>
              <span class="document-type-divider">&nbsp;-&nbsp;</span>
              <span v-bind:class="{
                      'document-status-green' : (doc.uploadAllowed === false),
                      'document-status-red' : ((typeof(doc.uploadedDocument) === 'undefined')  || (doc.uploadAllowed === true)),
                      }">
                      {{ formatDocumentStatus(doc) }}
                    </span>
            </div>
            <div v-if="doc.dueDate">
              <span class="document-deadline"><b>Submission Deadline:</b> {{ formatSubmissionDeadline(doc) }}</span>
            </div>
            <div class="document-comment">
              {{ doc.comment }}
            </div>

            <div v-if="showErrorMessageWidget && index===selectedIndex">
              <ErrorMessageWidget
                  :error="error"
                  :source="ERROR_SOURCE.Document"
                  @closeErrorMessage="closeErrorMessage"/>
            </div>
            <div v-else>

              <div v-if="(doc.documentGuid) && (doc.uploadedDocument)" class="document-link">
                <div v-if="(doc.uploadedDocument.documentFileStatus != 'FINALISED') && showAllButtons(doc,index)"
                     style="width:100%">You have uploaded this document but not yet submitted it:
                </div>
                <div v-if="(doc.uploadedDocument.documentFileStatus === 'FINALISED') && showAllButtons(doc,index)"
                     style="width:100%">You have submitted this document:
                </div>
                <div class="document-link">
                  <ul>
                    <li><a :href="baseURL() + `api/document/${doc.documentGuid}`"
                           target="_blank">{{ doc.uploadedDocument.fileName }}</a></li>
                  </ul>
                </div>
              </div>

              <div class="mt-2" v-if="showAllButtons(doc,index)">
                <div style="text-align: left;" v-if="doc.uploadAllowed">
                  <b-button
                      class="mx-1"
                      variant="primary"
                      v-b-tooltip.hover title="Upload the requested document"
                      @click="showUpload(doc.documentGuid, index)"
                      v-if="showUploadButton(doc, index)">
                    Upload
                  </b-button>

                  <b-button
                      class="custom-left-margin mx-1"
                      variant="danger"
                      v-b-tooltip.hover title="Remove this uploaded document"
                      @click="showDelete(doc.documentGuid, index)"
                      v-if="showDeleteFinalizeButtons(doc)">
                    Remove
                  </b-button>
                  <b-button
                      class="custom-left-margin mx-1"
                      variant="primary"
                      v-b-tooltip.hover title="Submit this uploaded document"
                      @click="showFinalize(doc.documentGuid, index)"
                      v-if="showDeleteFinalizeButtons(doc)">
                    Submit
                  </b-button>
                </div>
              </div>
            </div>

            <FileUploadWidget
                v-show="showUploadWidget(index)"
                uploadingMessage="Uploading File - Please wait..."
                :docGuid="doc.documentGuid"
                @closeUpload="closeUpload"
                @closeUploadWithError="closeUploadWithError"/>

            <div v-show="showDeleteWidget(index)">
              <p>Delete this uploaded document?</p>
              <b-button class="custom-left-margin mx-1" variant="outline-secondary" @click="closeDelete()">No</b-button>
              <b-button class="mx-1" variant="danger" @click="startDelete(index)">
                <b-spinner small class="align-middle" v-if="showDeleteMessage"></b-spinner>
                <span v-else>Yes</span>
              </b-button>
            </div>

            <div v-show="(showDeleteMessage === true) && (selectedIndex === index)">
              <p>Removing the uploaded document - please wait</p>
            </div>

            <div v-show="(showFinalizeMessage === true) && (selectedIndex === index)">
              <p>Submitting the uploaded document - please wait</p>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

    </b-container>

    <template #modal-footer="documentFooter">
      <b-button variant="outline-secondary mx-1" @click="close">Close</b-button>
      <b-button variant="primary mx-1" v-if="showUnsubmittedDocsWarning === true" @click="closeUnsubmittedDocsWarning">Go
        Back and Submit Now
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {enums} from "./ErrorSourceMixin";
import documentFormatting from "./DocumentFormatMixin";
import ErrorMessageWidget from './ErrorMessageWidget.vue';
import FileUploadWidget from './FileUploadWidget.vue'
import UnsubmittedDocumentWidget from './UnsubmittedDocumentWidget.vue';
import moment from "moment";
import {filterUploadableDocs} from "../api/filters.js";

export default {
  name: 'ManageDocumentsModal',
  mixins: [enums, documentFormatting],
  components: {
    FileUploadWidget,
    ErrorMessageWidget,
    UnsubmittedDocumentWidget
  },
  computed: {
    ...mapGetters("applicant", ['documentSummary', 'personalProfileDocTypeCodes', 'intentions', 'applications', 'uploadStatuses']),
  },
  data() {
    return {
      dGuid: null,
      columns: ["Doc Type", "Actions"],
      documentList: [],
      error: null,
      selectedIndex: null,
      showDeleteFlag: false,
      showDeleteMessage: false,
      showErrorMessageWidget: false,
      showFinalizeMessage: false,
      showUnsubmittedDocsWarning: false,
      showUploadFlag: false,
      uploadMessage: "",
      refreshDocumentData: false,
      uploadAllowedIntentionsList: [],
    }
  },
  mounted() {
    this.documentList = this.sortAndFilterUploadableDocs(this.documentSummary);
  },
  watch: {
    documentSummary: function (newDocumentSummary) {
      this.documentList = this.sortAndFilterUploadableDocs(newDocumentSummary);
    },
    personalProfileDocTypeCodes: function (newPersonalProfileDocTypeCodes) {
      this.documentList = this.sortAndFilterUploadableDocs(this.documentList);
    },
    deep: true,
  },
  methods: {
    close() {
      if ((!this.showUnsubmittedDocsWarning) && (this.unsubmittedDocuments(this.documentList))) {
        this.showUnsubmittedDocsWarning = true;
        return;
      }
      this.file1 = null;
      this.showUploadFlag = false;
      this.showDeleteFlag = false;
      this.showDeleteMessage = false;
      this.showFinalizeMessage = false;
      this.showErrorMessageWidget = false;
      this.showUnsubmittedDocsWarning = false;
      if (this.refreshDocumentData) {
        this.$store.dispatch('applicant/loadDocumentSummary'); //refresh document data so document shows
      }
      this.$bvModal.hide("ManageDocumentsModal");
    },
    closeDelete() {
      this.showDeleteMessage = false;
      this.showDeleteFlag = false;
    },
    closeErrorMessage() {
      this.showErrorMessageWidget = false;
      this.error = null;
    },
    closeUnsubmittedDocsWarning() {
      this.showUnsubmittedDocsWarning = false;
    },
    closeUpload() {
      this.showUploadFlag = false;
    },
    closeUploadWithError(error) {
      this.showUploadFlag = false;
      this.error = error;
      this.showErrorMessageWidget = true;
    },
    baseURL() {
      return (process.env.BASE_URL);
    },
    formatSubmissionDeadline(doc) {
      return moment(doc.dueDate).format('MMMM DD');
    },
    getUnsubmittedDocs(docList) {
      if (!(docList)) {
        return [];
      }

      return docList.filter(doc => doc.uploadedDocument && (doc.uploadAllowed || doc.uploadedDocument?.DocumentFileStatus === 'UPLOADED'));
    },
    showAllButtons(doc, index) {
      if (index === this.selectedIndex && (this.showUploadFlag || this.showDeleteFlag)) {
        return false;
      }
      return doc.uploadAllowed;
    },
    showDelete(docGuid, index) {
      this.dGuid = docGuid;
      this.selectedIndex = index;
      this.showDeleteFlag = true;
      this.showDeleteMessage = false;
      this.showFinalizeMessage = false;
      this.showUploadFlag = false;
    },
    showDeleteFinalizeButtons(doc) {
      return doc.uploadAllowed && doc.uploadedDocument;
    },
    showDeleteWidget(index) {
      if (this.showDeleteFlag && (index == this.selectedIndex) && (!this.showDeleteMessage)) {
        return true;
      }
      return false;
    },
    showFinalize(docGuid, index) {
      this.dGuid = docGuid;
      this.selectedIndex = index;
      this.showDeleteFlag = false;
      this.showDeleteMessage = false;
      this.showUploadFlag = false;
      if (!this.showFinalizeMessage) {
        this.startFinalize(index);
      }
    },
    showUpload(docGuid, index) {
      this.dGuid = docGuid;
      this.selectedIndex = index;
      this.showUploadFlag = true;
      this.showDeleteFlag = false;
      this.showDeleteMessage = false;
      this.showFinalizeMessage = false;
      this.showErrorMessageWidget = false;
    },
    showUploadButton(doc, index) {
      return doc.uploadAllowed;
    },
    showUploadWidget(index) {
      return index === this.selectedIndex && this.showUploadFlag;
    },
    sortAndFilterUploadableDocs(docsToSort) {
      return filterUploadableDocs(docsToSort, this.applications, this.intentions, this.uploadStatuses);
    },
    async startDelete(index) {
      this.selectedIndex = index;
      // Display delete message
      this.showDeleteMessage = true;
      // Retrieve promise
      let self = this;
      this.$store.dispatch('applicant/getDeleteDocumentPromise', {
        documentGuid: this.documentList[index].documentGuid
      })
          .then(() => {
            // Promise successful
            // Remove the uploadedDocument from the document in the local collection
            if (this.documentList[index] != null) {
              this.documentList[index].uploadedDocument = undefined;
            }

            // Hide the delete dialog and message
            self.showDeleteMessage = false;
            self.showDeleteFlag = false;
          })
          .catch(err => {
            console.log("Error caught in startDelete: " + err);
            // Hide the delete dialog and message
            self.showDeleteMessage = false;
            self.showDeleteFlag = false;
            self.showErrorMessageWidget = true;
            self.error = err;
          })
          .finally(() => {
            this.isCommunicating = false;
          });
    },
    async startFinalize(index) {
      this.selectedIndex = index;
      // Display finalize message
      this.showFinalizeMessage = true;
      var self = this;
      this.$store.dispatch('applicant/getFinaliseDocumentPromise', {
        documentGuid: this.documentList[index].documentGuid
      })
          .then(() => {
            // Promise successful
            // Update the document's uploadedDocument status in the local collection
            this.refreshDocumentData = true;
            if ((this.documentList[index] != null) && (this.documentList[index].uploadedDocument != null)) {
              this.documentList[index].uploadAllowed = false;
              this.documentList[index].uploadedDocument.documentFileStatus = "FINALISED";
            }
            // Hide the dialog and message
            self.showFinalizeMessage = false;
          })
          .catch(err => {
            console.log("Error caught in startFinalize(): " + err);
            // Hide the dialog and message
            self.showFinalizeMessage = false;
            self.showErrorMessageWidget = true;
            self.error = err;
          });
    },
    unsubmittedDocuments(docList) {
      const unsubmittedDocs = this.getUnsubmittedDocs(docList);
      return unsubmittedDocs?.length > 0;

    }
  }
};
</script>
<style lang="css" scoped>

</style>