import {email, regex, required} from "vee-validate/dist/rules";
import {extend} from "vee-validate";

extend("required", {
    ...required,
    message: "Value is required"
});

extend('required_if_multiple', {
    validate: (value, values) => {
        let empty = true;
        Object.keys(values).forEach(key => {
            if (['', null, undefined].indexOf(values[key]) === -1) {
                empty = false;
            }
        });
        return {
            required: !empty,
            valid: empty || (!empty && ['', null, undefined].indexOf(value) === -1)
        };
    },
    computesRequired: true,
    message: "Value is required"
});

extend("email", {
    ...email,
    message: "Value must be a valid email"
});

extend("phone", {
    ...regex,
    message: "Value must be a valid phone number"
});

extend("maxwordcount", {
    validate: (value = "", {length}) => {
        return value.split(' ')
            .filter(function (n) {
                return n !== ''
            })
            .length < length;
    },
    params: ['length'],
    message: "Value must have at most {length} words"
});

extend("maxcharscount", {
    validate: (value = "", {maxCharLimit}) => {
        return value.length <= maxCharLimit;
    },
    params: ['maxCharLimit'],
    message: "Value must have at most {maxCharLimit} characters"
});
