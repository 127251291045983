<template>
  <b-card no-body class="mt-2">
    <b-card-header v-b-toggle.collapse-document-received block>
      <b-card-title>
        <ChevronDown v-show="isVisible" aria-hidden="true" />
        <ChevronRight v-show="!isVisible" aria-hidden="true" />
        Documents</b-card-title
      >
    </b-card-header>
    <b-collapse visible id="collapse-document-received" v-model="isVisible">
      <b-card-body>
        <DocumentsUndergraduateEducationCard
          v-if="showDocumentsADMS"
          :title="titleForADMS"
          :procArea="procAreaForADMS"
        />
        <DocumentsUndergraduateEducationCard
          v-if="showDocumentsEDUC"
          :title="titleForEDUC"
          :procArea="procAreaForEDUC"
        />
        <DocumentsSupplementalApplicationCard
          v-if="supplementalDocList.length"
          :title="titleForSUPP"
          :supplementalDocList="supplementalDocList"
          :supplementalApplicationSummaries="supplementalApplicationSummaries"
        />
        <DocumentsPersonalProfileCard  
          v-if="personalProfileDocList.length"        
          :title="titleForPP"
          :personalProfileDocList="personalProfileDocList"
        />        
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import DocumentsUndergraduateEducationCard from "./DocumentsUndergraduateEducationCard.vue";
import DocumentsSupplementalApplicationCard from "./DocumentsSupplementalApplicationCard.vue";
import DocumentsPersonalProfileCard from "./DocumentsPersonalProfileCard.vue";
import moment from "moment";

export default {
  name: "DocumentsCard",
  components: {
    ChevronRight,
    ChevronDown,
    DocumentsUndergraduateEducationCard,
    DocumentsSupplementalApplicationCard,
    DocumentsPersonalProfileCard,
  },
  computed: {
    ...mapGetters("applicant", ["supplementalApplicationSummaries", "documentSummary", "personalProfileDocTypeCodes", "intentions"]),
    showDocumentsADMS() {
        return this.intentions.some((val) => val.processingArea === "ADMS");
    },
    showDocumentsEDUC() {
        return this.intentions.some((val) => val.processingArea === "EDUC");
    },
  },
  data() {
    return {
      isVisible: true,
      titleForADMS: "Undergraduate Admission - Documents Received",
      titleForEDUC: "Education - Documents Received",
      titleForSUPP: "Supplemental Applications and Fees",
      titleForPP: "Personal Profile",
      procAreaForADMS: "ADMS",
      procAreaForEDUC: "EDUC",
      supplementalDocList: [],
      personalProfileDocList: [],
    };
  },
  mounted() { 
    this.personalProfileDocList = this.filterOutNonPPDocuments(
      this.personalProfileDocTypeCodes,
      this.documentSummary
    );
    this.supplementalDocList = this.filterOutNonSupplementalDocuments(
      this.supplementalApplicationSummaries,
      this.documentSummary
    );
  },
  watch: {
    supplementalApplicationSummaries: function (
      newSupplementalApplicationSummaries
    ) {
      try {
        this.supplementalDocList = this.filterOutNonSupplementalDocuments(
          newSupplementalApplicationSummaries,
          this.documentSummary
        );
      } catch (error) {
        console.log(
          "Error caught in DocumentsCard > supplementalApplicationSummaries watch: " +
            error
        );
      }
    },
    documentSummary: function (newDocumentSummary) {
      try {
        this.supplementalDocList = this.filterOutNonSupplementalDocuments(
          this.supplementalApplicationSummaries,
          newDocumentSummary
        );
        this.personalProfileDocList = this.filterOutNonPPDocuments(
          this.personalProfileDocTypeCodes,
          newDocumentSummary
        );
      } catch (error) {
        console.log(
          "Error caught in DocumentsCard > documentSummary watch: " + error
        );
      }
    },
    personalProfileDocTypeCodes: function (newPersonalProfileDocTypeCodes) {
      try {       
        this.personalProfileDocList = this.filterOutNonPPDocuments(
          newPersonalProfileDocTypeCodes,
          this.documentSummary
        );
      } catch (error) {
        console.log(
          "Error caught in DocumentsCard > personalProfileDocTypeCodes watch: " + error
        );
      }
    },
    deep: true,
    immediate: true,
  },
  methods: {
    filterOutNonSupplementalDocuments(suppDocs, docs) {
      return docs
        .filter((doc) => {
          return suppDocs?.some((suppDoc) => {            
            return suppDoc.documentGuid === doc.documentGuid && this.isSupplementalOpen(suppDoc);            
          });
        });
    },
    isSupplementalOpen(supplementalSummary){    
      return (supplementalSummary.openDate === undefined || moment().isAfter(supplementalSummary.openDate));
    },
    filterOutNonPPDocuments(ppDocTypes, docs) {
      return docs
        .filter((doc) => {
          return ppDocTypes?.some((ppDocTypes) => {
            return ppDocTypes === doc.miscDocType;
          });
        });
    },
  },
};
</script>
<style scoped>
.card-title {
  margin-bottom: 0;
}
.b-card-title {
  font-size: large;
  font-weight: bold;
}
.b-card-sub-title {
  font-weight: normal;
}
>>> .table > tbody > tr > td.view {
  width: 5%;
}
>>> .table > tbody > tr > td.status {
  width: 12%;
}
>>> .table > tbody > tr > td.view > button {
  padding: 0%;
  line-height: 0%;
  text-decoration: underline;
}
</style>