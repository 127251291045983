export default {
    methods: {
        formatDocumentTitle: function (doc) {
            if (doc?.docName) {
                return doc.docName;
            } else if (doc?.educationDocName) {
                return doc.educationDocName + ' ' + doc.transcriptStatus + ' ' + doc.docType;;
            } else if (doc?.institutionName) {
                return doc.institutionName + ' ' + doc.transcriptStatus + ' ' + doc.docType;
            } else if (doc?.testName) {
                return doc.testName + ' ' + doc.docType;
            } else if(doc?.docType) {
                return doc.docType;
            }
            return "";
        },       
        formatDocumentStatus: function (doc) {
            if (!doc?.uploadedDocument?.documentFileStatus) {
                return doc.docRequired;
            } else if (doc.uploadAllowed === false || doc.uploadedDocument.documentFileStatus == "FINALISED") {
                return "Submitted";
            } else if (doc.uploadAllowed === true || doc.uploadedDocument.documentFileStatus == "UPLOADED") {
                return "Not Submitted";
            } else {
                return doc.uploadedDocument.documentFileStatus;
            }
        },
        formatDocStatus: function (doc) {
            if(doc?.officialTranslation) {
                return "Translation";
            } else {
                return doc.officialStatus;
            }
        },
        formatDocTitle: function (doc) {
            if (doc?.docName) {
                return doc.docName;
            } else if (doc?.educationDocName) {
                return doc.educationDocName;
            } else if (doc?.institutionName) {
                return doc.institutionName;
            } else if (doc?.testName) {
                return doc.testName;
            } else if(doc?.docType) {
                return doc.docType;
            }
            return "";
        }, 
    }
};