<template>
  <b-container fluid="true">
    <page-load-overlay :show="showOverlay">
      <h6>Biographic Information</h6>
      <p>
        Please answer all of the below questions, submit your application, and
        pay your application fee prior to the application deadline.
      </p>

      <div>
        <p>
          Your name details will be used in official and non-official UBC
          communications. For information on how your name will be used please
          visit https://equity.ubc.ca/resources/gender-diversity/names/
        </p>
        <p></p>
        <b-alert :show="isError" variant="danger" dismissible>
          The following Errors have occurred: <br />
          {{ this.savingError }}
        </b-alert>
        <b-form-group label="Preferred name" label-cols="4">
          <b-form-input v-model.trim="formData.preferredName"></b-form-input>
        </b-form-group>
        <b-form-group label-for="input-first-name" label-cols="4">
          <template v-slot:label>
            First name <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model.trim="formData.legalFirstName"
            :disabled="isFirstNameDisabled"
            id="input-first-name"
            :class="{
              'is-invalid': v$.$dirty && v$.formData.legalFirstName.$error,
            }"
          ></b-form-input>
          <div
            class="invalid-feedback"
            v-for="error of v$.formData.legalFirstName.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>

        <b-form-group label-for="input-last-name" label-cols="4">
          <template v-slot:label>
            Last name <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model.trim="formData.surname"
            :disabled="isLastNameDisabled"
            id="input-last-name"
            :class="{
              'is-invalid': v$.$dirty && v$.formData.surname.$error,
            }"
          ></b-form-input>
          <div
            class="invalid-feedback"
            v-for="error of v$.formData.surname.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>

        <b-form-group label-for="input-dob" label-cols="4">
          <template v-slot:label>
            Date of birth <span class="text-danger">*</span>
          </template>
          <b-form-datepicker
            v-model.trim="formData.dateOfBirth"
            :disabled="isDateOfBirthDisabled"
            reset-button
            id="input-dob"
            :class="{
              'is-invalid': v$.$dirty && v$.formData.dateOfBirth.$error,
            }"
          ></b-form-datepicker>
          <div
            class="invalid-feedback"
            v-for="error of v$.formData.visaTypeCode.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>

        <b-form-group label-for="input-visa-code" label-cols="4">
          <template v-slot:label>
            Visa status <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="allVisas"
            text-field="description"
            value-field="visaCode"
            v-model="formData.visaTypeCode"
            :disabled="isVisaTypeDisabled"
            id="input-visa-code"
            :class="{
              'is-invalid': v$.$dirty && v$.formData.visaTypeCode.$error,
            }"
          >
          </b-form-select>
          <div
            class="invalid-feedback"
            v-for="error of v$.formData.visaTypeCode.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>

        <b-form-group label-for="input-citizenship" label-cols="4">
          <template v-slot:label>
            Citizenship <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="countries"
            text-field="description"
            value-field="countryCode"
            v-model="formData.countryOfCitizenship"
            :disabled="isCitizenshipDisabled"
            id="input-citizenship"
            :class="{
              'is-invalid':
                v$.$dirty && v$.formData.countryOfCitizenship.$error,
            }"
          >
          </b-form-select>
          <div
            class="invalid-feedback"
            v-for="error of v$.formData.countryOfCitizenship.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>

        <b-form-group label-for="input-email" label-cols="4">
          <template v-slot:label>
            Email <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model.trim="formData.email"
            :disabled="isEmailDisabled"
            id="input-email"
            :class="{
              'is-invalid': v$.$dirty && v$.formData.email.$error,
            }"
          ></b-form-input>
          <div
            class="invalid-feedback"
            v-for="error of v$.formData.email.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </b-form-group>
        <p>
          If there have been changes to your biographic information, disability
          status, or youth in care status since your last attendance at UBC,
          please contact the admissions office with your student number via the
          online form at https://account.you.ubc.ca/s/ask-ubc
        </p>
      </div>
    </page-load-overlay>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import _cloneDeep from "lodash/cloneDeep";
import useVuelidate from "@vuelidate/core";
import PageLoadOverlay from "@/components/PageLoadOverlay";
import { required } from "@vuelidate/validators";

export default {
  name: "ReAdmissionDetails",
  data() {
    return {
      personToEdit: null,
      formData: null,
    };
  },
  components: {
    PageLoadOverlay,
  },
  computed: {
    ...mapGetters("applicant", ["person", "countries", "allVisas"]),
    ...mapGetters("reAdmission", ["savingError", "isBusy"]),
    isFirstNameDisabled() {
      return !!this.personToEdit?.personName?.legalFirstName;
    },
    isLastNameDisabled() {
      return !!this.personToEdit?.personName?.surname;
    },
    isDateOfBirthDisabled() {
      return !!this.personToEdit?.personBiographic?.dateOfBirth;
    },
    isCitizenshipDisabled() {
      return !!this.personToEdit?.personCitizenship?.countryOfCitizenship;
    },
    isVisaTypeDisabled() {
      return !!this.personToEdit?.personCitizenship?.visaTypeCode;
    },
    isEmailDisabled() {
      return !!this.personToEdit?.personEmail;
    },
    showOverlay() {
      return this.isBusy;
    },
    isError() {
      return this.savingError != "";
    },
  },
  validations: {
    formData: {
      legalFirstName: { required },
      surname: { required },
      visaTypeCode: { required },
      countryOfCitizenship: { required },
      dateOfBirth: { required },
      email: { required },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    formValidation() {
      this.v$.$touch();
      if (this.v$.$dirty && this.v$.$error) {
        return false;
      }
      this.$store
        .dispatch("reAdmission/saveBiographicalDetails", this.formData)
        .then(() => {
          if (!this.savingError) {
            this.$emit("forceToNext");
            this.$store.dispatch("applicant/loadPerson");            
          }
        });
    },
    onClickPrevious() {
      return true;
    },
  },
  watch: {
    person: {
      handler: function (newValue) {
        this.personToEdit = _cloneDeep(newValue);
        this.formData = {
          preferredName: this.personToEdit?.personName?.preferredName ?? "",
          legalFirstName: this.personToEdit?.personName?.legalFirstName ?? "",
          surname: this.personToEdit?.personName?.surname ?? "",
          dateOfBirth: this.personToEdit?.personBiographic?.dateOfBirth ?? "",
          visaTypeCode:
            this.personToEdit?.personCitizenship?.visaTypeCode ?? "",
          countryOfCitizenship:
            this.personToEdit?.personCitizenship?.countryOfCitizenship ?? "",
          email: this.personToEdit?.personEmail ?? "",
        };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>