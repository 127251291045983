<template>
  <b-card>
    <b-card-header>
        <h4>Contact us with your questions about:</h4>
    </b-card-header>
    <b-card-body class="custom-bcard-body-margin">
      <ContactQA/>
    </b-card-body>
  </b-card>
</template>
<script>
import ContactQA from './ContactQA.vue';
export default {
  components: {
    ContactQA
  }
}
</script>
<style lang="css" scoped>
.card-body {
  padding: 0;
}
.custom-bcard-body-margin {
  margin: 20px;
}
</style>