<template>
  <section>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle="'institution-' + index" block>
        <ChevronDown class="when-opened" aria-hidden="true"/>
        <ChevronRight class="when-closed" aria-hidden="true"/>
        <span>Institution #{{index + 1}}</span>
      </b-card-header>
      <b-collapse :id="'institution-' + index" :visible="true">
        <b-card-body>
          <b-row class="listRow" align-v="center">
            <b-col cols="5">Country <span class="text-danger">*</span> </b-col>
            <b-col cols="7">
              <b-form-select
                  :options="countryOptions"
                  :value="institution.country"
                  @input="(value) => inputHandler(value, 'country')"
                  :class="{ 'is-invalid': v$.$dirty && v$.institution.country.$error }"
              ></b-form-select>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.institution.country.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="listRow" align-v="center" v-show="showProvinceDropdown()">
            <b-col cols="5">Province/State </b-col>
            <b-col cols="7">
              <b-form-select
                  :options="provStateList"
                  :value="institution.province"
                  @input="(value) => inputHandler(value, 'province')"
              ></b-form-select>
            </b-col>
          </b-row>

          <b-row align-v="center" v-show="institution.country">
            <b-col cols="5">Name of Institution <span class="text-danger">*</span></b-col>
            <b-col cols="7">
              <multiselect
                  :value="institution.institution"
                  :options="institutions"
                  :searchable="true"
                  :multiple="false"
                  :id="index"
                  :loading="isLoading"
                  :close-on-select="true"
                  :internal-search="false"
                  :options-limit="300"
                  class="multiselect my-3"
                  track-by="institutionDescription"
                  label="institutionDescription"
                  @search-change="searchInstitutions"
                  @input="(value) => inputHandler(value, 'institution')"
                  :class="{ 'invalid': v$.$dirty && v$.institution.institution.$error }"
                  placeholder="Type to search">
                <template slot="singleLabel" slot-scope="{ option }">{{ option.institutionDescription }} ({{ option.institutionCode }})</template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
              <div
                  class="invalid-multiselect"
                  v-for="error of v$.institution.institution.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="listRow" align-v="center">
            <b-col cols="5">From Date <span class="text-danger">*</span></b-col>
            <b-col cols="7">
              <b-form-input
                  :value="institution.startDate"
                  placeholder="YYYY-MM"
                  @input="(value) => inputHandler(value, 'startDate')"
                  :class="{ 'is-invalid': v$.$dirty && v$.institution.startDate.$error }"
              ></b-form-input>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.institution.startDate.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="listRow" align-v="center">
            <b-col cols="5">To Date <span class="text-danger">*</span> </b-col>
            <b-col cols="7">
              <b-form-input
                  :value="institution.endDate"
                  placeholder="YYYY-MM"
                  @input="(value) => inputHandler(value, 'endDate')"
                  :class="{ 'is-invalid': v$.$dirty && v$.institution.endDate.$error }"
              ></b-form-input>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.institution.endDate.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="listRow" align-v="center">
            <b-col cols="5">Did you complete these courses on a Letter of Permission from UBC? <span class="text-danger">*</span></b-col>
            <b-col cols="7">
              <b-form-radio-group
                  :id="'radio1-' + index"
                  :checked="institution.isOnLetterOfPermission"
                  :options="radioOptions"
                  :name="'onPermission-' + index"
                  @input="(value) => inputHandler(value, 'isOnLetterOfPermission')"
                  :class="{ 'is-invalid': v$.$dirty && v$.institution.isOnLetterOfPermission.$error }"
              ></b-form-radio-group>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.institution.isOnLetterOfPermission.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>

          <b-row class="listRow" align-v="center">
            <b-col cols="5">Were you required to withdraw from this institution? <span class="text-danger">*</span></b-col>
            <b-col cols="7">
              <b-form-radio-group
                  :id="'radio2-' + index"
                  :checked="institution.isRequiredToWithdraw"
                  :options="radioOptions"
                  :name="'withdraw-' + index"
                  @input="(value) => inputHandler(value, 'isRequiredToWithdraw')"
                  :class="{ 'is-invalid': v$.$dirty && v$.institution.isRequiredToWithdraw.$error }"
              ></b-form-radio-group>
              <div
                  class="invalid-feedback"
                  v-for="error of v$.institution.isRequiredToWithdraw.$errors"
                  :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </b-col>
          </b-row>
          <b-button
              variant="primary"
              class="btn-size"
              @click="removeInstitution()">
            Remove this institution
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import Multiselect from 'vue-multiselect';
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";

const mustBeYearMonth = helpers.regex(/^\d{4}-(0[1-9]|1[0-2])$/);
const invalidYearMonth = "Please enter date in the correct format (YYYY-MM)";

export default {
  name: "ReAdmissionPreviousInstitutionForm",
  props: ["value", "index"],
  components: {
    ChevronRight,
    ChevronDown,
    Multiselect,
  },
  data() {
    return {
      provStateList: [],
      institution: this.value,
      institutions: [],
      isLoading: false,
      radioOptions: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ]
    };
  },
  computed: {
    ...mapGetters("applicant", ['countries']),
    countryOptions() {
      return this.getCountryOptions();
    },
  },
  beforeMount() {
    this.restoreFormOptions();
  },
  validations: {
    institution: {
      country: {required},
      institution: {required},
      startDate: {
        required,
        mustBeYearMonth: helpers.withMessage(
            invalidYearMonth,
            mustBeYearMonth
        ),
      },
      endDate: {
        required,
        mustBeYearMonth: helpers.withMessage(
            invalidYearMonth,
            mustBeYearMonth
        ),
      },
      isOnLetterOfPermission: {required},
      isRequiredToWithdraw: {required},
    }
  },
  setup: () => ({v$: useVuelidate()}),
  watch: {
    value: {
      handler: function(newValue) {
        this.institution = newValue;
      },
      deep: true,
    }
  },
  methods: {
    removeInstitution(){
        this.$emit("remove", this.institution.id);
    },
    inputHandler(value, key) {
      if (key === 'country') {
        this.institutions = [];
        this.setProvinceOptions(value);
      }else if (key === 'province'){
        this.institutions = [];
      }
      this.$emit("input", {id: this.institution.id, key: key, val: value});
    },
    getCountryOptions(){
      let result = [];
      result.push({value: "", text: "- Please Choose -"});
      for (const obj of this.countries) {
        result.push({value: obj.countryCode.trim(), text: obj.description.trim()});
      }
      return result;
    },
    setProvinceOptions(countryCode) {
      if (!countryCode){
        return;
      }
      let matchingCountry;
      for (let country of this.countries) {
        if (countryCode.startsWith(country.countryCode)) {
          matchingCountry = country;
          break;
        }
      }
      if (!matchingCountry){
        return;
      }
      this.provStateList = [];
      if (matchingCountry.provinces) {
        let result = [];
        for (const obj of matchingCountry.provinces) {
          result.push({ value: obj.provinceCode, text: obj.description.trim()});
        }
        this.provStateList = result;
      }
    },
    restoreFormOptions(){
      this.setProvinceOptions(this.institution.country);
      this.institutions = [];
    },
    searchInstitutions(query) {
      if (query.trim() === ''){
        return;
      }
      this.isLoading = true;
      this.institutions = [];
      let selectedCountry = this.institution.country;
      let selectedProvince = this.institution.province;
      this.$http.get("/readmission/searchInstitutions", {
        params: {
          "country": selectedCountry,
          "province": selectedProvince,
          "query": query
        }
      }).then(response => {
        this.institutions = this.cleanSearchResults(response.data);
      }).finally(() => {
        this.isLoading =  false;
      });
    },
    cleanSearchResults(rawData){
      let result = [];
      for (const obj of rawData) {
        if (obj.country == this.institution.country) {
          result.push({
            institutionCode: obj.institutionCode,
            institutionDescription: obj.institutionDescription,
          });
        }
      }
      return result;
    },
    showProvinceDropdown() {
      return (this.provStateList.length > 1);
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
>>> .btnGroup {
  margin-top: 20px;
  margin-bottom: 6px;
}

>>> .listRow {
  margin-top: 6px;
  margin-bottom: 6px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.multiselect.invalid {
  border: 1px solid #dc3545 !important;
}
.invalid-multiselect {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>