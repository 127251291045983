<template>
  <div class="application-details-backdrop" @click.self="close">
    <div class="application-details-modal">
      <span style="font-size:32px;font-weight:bold;margin-left:20px;">Submitted Application Details</span>
			<div style="float:right;margin-top:10px;margin-right:20px;">
				<b-button class="custom-bottom-margin"  variant="outline-secondary" @click="close">Close</b-button>
			</div>
			<table class="application-details-table">
				<tr class="application-details-table-row" v-for="answer in personalProfileAnswers" v-bind:key="answer.question">
					<td style="overflow:auto;">
            <p>{{answer.question}}</p>
            <table>
            	<tr v-for="response in answer.responses" v-bind:key="response.repsonse">
								<td style="font-size:12px">{{response.nestedQuestionLabel}}</td>
            		<td style="font-size:12px">{{response.response}}</td>
            	</tr>
            </table>
					</td>
        </tr>
      </table>
      <div class="mt-3">
        <b-button  variant="outline-secondary" @click="close">Close</b-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
	  name: 'ApplicationDetailsModal',
	  props: {
	    personalProfileAnswers: Array
	  },
	  methods: {
	    close() {
	      this.$emit('closeModal')
	    }
	  }
  };
</script>
<style lang="css" scoped>
.application-details-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 100;
}

.application-details-modal {
	height: 100%;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 2px 2px 20px 1px;
	border-radius: 2px;
	display: inline;
	position: relative !important;
	z-index: 1000;
	opacity: 1.0;
  overflow: auto;
	padding: 25px 10px 20px;
}
.application-details-table {
  width: 100%;
	margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid black;
	overflow: auto;
}
.application-details-table-row {
  border:1px solid black;
}
.custom-bottom-margin {
	margin-bottom:20px;
}

</style>
