<template>
  <div v-if="formText" v-html="formText"></div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ReAdmissionFormText",
  computed: {
    ...mapGetters("reAdmission", ['formText']),
  },
  methods: {
    formValidation() {
      return true;
    },
    save() {
      return true;
    },
  },
}
</script>

<style scoped>
>>> .infoMessage {
  border: medium solid #D8A838;
  background-color: #FFFFE0;
  padding: 10px;
  width: 90%;
  margin: 1em auto;
}

>>> .errorMessages {
  padding: 10px;
  width: 90%;
  margin: 1em auto;
}

>>> h4 {
  font-size: 1em;
  font-weight: bold;
}

</style>

