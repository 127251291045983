<template>
  <div>
    <b-card class="border-0" title="Your application">
      <b-card-sub-title>Review the status of your application and any steps that require your action</b-card-sub-title>
      <b-card-body body-class="custom-padding">
        <div v-for="intentionGroup in intentionGroups" >
          <h5>{{ getProcessingAreaDescription(intentionGroup[0].processingArea) }}</h5>
          <div class="accordion" role="tablist">
            <b-card v-for="(intention, index) in intentionGroup" class="mb-1" no-body v-bind:key="'intention' + intention.id">
              <b-card-header v-b-toggle="'accordion-' + intention.id" class="p-3" header-tag="header">
                <ChevronDown class="when-opened" aria-hidden="true"/>
                <ChevronRight class="when-closed" aria-hidden="true"/>
                <span>
                Choice {{ intention.choice }} {{ getIntentionDescription(intention) }} ({{ intention.campus }})
              </span>
              </b-card-header>
              <b-collapse
                  :id="'accordion-' + intention.id"
                  :visible="index === 0"
              >
                <b-card-body>
                  <div>
                    <ApplicationStatusWidget
                        :intention="intention"
                        :intentionGroup="intentionGroup"
                        />
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="accept_button" v-if="hasOffer(intentionGroup)">
            <b-button variant="primary" @click="showOfferModal(intentionGroup)">
              {{ showOfferButtonTitle(intentionGroup[0].processingArea) }}
            </b-button>
          </div>
        </div>
      </b-card-body>
      <AdmissionLetterModal />
      <AcceptOfferModal/>
      <DirectAcceptOfferModal/>
      <AcceptOrDeclineOfferModal/>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdmissionLetterModal from './AdmissionLetterModal.vue';
import ApplicationStatusWidget from './ApplicationStatusWidget.vue';
import Vue from 'vue'
import { CardPlugin } from 'bootstrap-vue'
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import {offerAction} from "@/store/modules/deriveActionItems";
import {isDirectAcceptOffer} from "@/api/filters";
import AcceptOfferModal from "@/components/AcceptOfferModal";
import DirectAcceptOfferModal from './DirectAcceptOfferModal.vue';
import AcceptOrDeclineOfferModal from './AcceptOrDeclineOfferModal.vue';

Vue.use(CardPlugin)

export default {
  name: "ApplicationStatusCard",
  components: {
    AdmissionLetterModal,
    ApplicationStatusWidget,
    ChevronRight,
    ChevronDown,
    AcceptOfferModal,
    DirectAcceptOfferModal,
    AcceptOrDeclineOfferModal,
  },
  computed: {
    ...mapGetters("applicant", ['intentions', 'allProcessingAreas']),
    // get the intentions grouped by proc area returned as an array as Vue cannot iterate over a map
    intentionGroups() {
      const grouped = _.groupBy(this.intentions, intention => intention.processingArea + intention.applEnteredDate);
      return Object.keys(grouped).map(function(key) {
        return grouped[key];
      });
    }
  },
  methods: {
    getProcessingAreaDescription(processingArea) {
      let item = this.allProcessingAreas.find(el => el.funcArea === 'ADMS' && el.procArea === processingArea);
      if (item !== undefined) {
        return item.descr;
      }
      return "";
    },
    getIntentionDescription(intention) {
      return intention.description ? intention.description : this.getIntentionDescriptionUsingCategory(intention);
    },
    getIntentionDescriptionUsingCategory(intention) {
      return intention?.categoryDescription ? intention.categoryDescription : "Unclassified";
    },
    hasOffer(intentions) {
      let offerActionArr = offerAction(intentions);
      return offerActionArr?.length;
    },
    showOfferModal(intentionGroup) {
      let processingArea = intentionGroup[0].processingArea;
      this.$store.dispatch("applicant/setSelectedIntentionGroup", intentionGroup);
      if (isDirectAcceptOffer(processingArea)) {
        // XAMS Direct Accept
        this.$bvModal.show("DirectAcceptOfferModal");
      }else if (processingArea === 'ADMS' || processingArea === 'EDUC'){
        // Undergraduate model
        this.$bvModal.show("AcceptOfferModal");
      }else{
        // XAMS Accept or Decline
        this.$bvModal.show("AcceptOrDeclineOfferModal");
      }
    },
    showOfferButtonTitle(processingArea) {
      return isDirectAcceptOffer(processingArea) ? "Accept" : "Accept or Decline Offer";
    },
  },
}
</script>

<style scoped>
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }

  >>> .accept_button {
    text-align: right;
  }
</style>