<template>
  <b-overlay :show="show" rounded="sm">
    <slot></slot>
  </b-overlay>
</template>

<script>
/**
 * Loading overlay for component initialization. Has a slot for content to be obscured until loading is done.
 */
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
