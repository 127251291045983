<template>
  <div class="w-75 p-3" v-html="this.documentBody"/>
</template>

<script>
import api from "@/api/backend-api";

export default {
  name: "apply",
  data() {
    return {
      documentBody: "",
    }
  },
  mounted() {
    this.getFormText();
  },
  methods: {
    getFormText() {
      api.getApplyFormText().then(response => {
        this.documentBody = response.data;
      });
    },
  },
};
</script>

<style scoped>

</style>