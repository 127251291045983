<template>
  <b-container>

    <b-row>
      <h3>Don't forget to submit your documents!</h3>
    </b-row>

    <b-row class="mb-3">
      <span>We noticed that not all of your uploaded documents have been submitted. Your application will be considered
        incomplete until all the required documents have been submitted.</span>
    </b-row>
    <b-row>
      <table class="document-table">
        <tbody>
        <tr class="document-table-row" v-for="doc in documentList" v-bind:key="doc.documentGuid">
          <td class="document-table-cell left-aligned">
            {{ formatDocumentTitle(doc) }}
          </td>
          <td class="document-table-cell center-aligned">
            <span v-bind:class="{
                'document-status-green' : (doc.uploadAllowed === false),
                'document-status-red' : ((typeof(doc.uploadedDocument) === 'undefined')  || (doc.uploadAllowed === true)),
              }">
              {{ formatDocumentStatus(doc) }}
            </span>
          </td>
        </tr>
      </tbody>
      </table>
    </b-row>
    <b-row>Do you want to go back and submit these documents now or wait until later?</b-row>
  </b-container>
</template>
<script>

import documentFormatting from "./DocumentFormatMixin";

export default {
  name: "UnsubmittedDocumentWidget",
  mixins: [documentFormatting],
  props: {
    documentList: Array
  },
  methods: {
    close() {
      this.$emit('close');
    },
    goBack() {
      this.$emit('closeUnsubmittedDocsWarning');
    }
  }
}
</script>
<style lang="css" scoped>
@import '../assets/manage-documents-modal.css';
</style>