import api from "../../api/backend-api";
import PersonalProfileCriteria from "@/api/PersonalProfileCriteria";

export const namespaced = true;

const state = {
  applicantNotFound: true,
  documentSummary: null,
  intentions: null,
  isLoading: false,
  offers: null,
  person: null,
  personalProfileAnswers: null,
  ubcId: null,
  personSummary: null,
  showOfferPostPayment: false,
  supplementalApplicationSummaries: null,
  termsOfUseAccepted: false,
  countries: null,
  personalProfileDocTypeCodes: null,
  supplementalDocCodes: null,
  allProcessingAreas: null,
  uploadStatuses: null,
  miscDocumentRules: null,
  applications: null,
  supplementalModalSummary: Object,
  personalProfileModalType: String,
  applicationFeesPaymentResponseDelay: false,
  acceptedOfferFeesPaymentResponseDelay: null,
  selectedIntentionGroup: null,
  onReloadIntentionsCloseModal: true,
  allVisas: null,
};

function getPersonalProfileAssociatedIntentions(){
  let finalIntentions = [];
  let applicationsWithPesonalProfile = [];

  let ppDocTypes = state.personalProfileDocTypeCodes;
  let docList = state.documentSummary.filter((doc) => {
    return ppDocTypes?.some((ppDocTypes) => {
      return ppDocTypes === doc.miscDocType;
    });
  });

  docList.forEach( (ppDoc) => {
    let applicationIdx = state.applications.findIndex(application => {
      return ppDoc.applicationId === application.id
    });
    if (applicationIdx !== -1) {
      applicationsWithPesonalProfile.push(state.applications[applicationIdx]);
    }
  });

  applicationsWithPesonalProfile.forEach( application => {

    let filteredIntentions = state.intentions.filter ((intention) => application.admId === intention.admId &&
        application.applEnteredDate === intention.applEnteredDate && application.admissProcArea === intention.processingArea) ;

    filteredIntentions.forEach( filteredIntentionObj => {
      let filteredIntention = {...state.miscDocumentRules.find(rule => {
        return filteredIntentionObj && rule.degreeProgramCd === filteredIntentionObj.degreeId;
      })};

      let isPPDocExists = docList.findIndex( ppDoc => {
        return filteredIntention && ppDoc.degreeProgramCode === filteredIntention.degreeProgramCd;
      });

      if(isPPDocExists !== -1) {
        filteredIntention.degreeId = filteredIntentionObj.degreeId;
        filteredIntention.applicationType = filteredIntentionObj.processingArea;
        filteredIntention.processingArea = filteredIntentionObj.processingArea;
        filteredIntention.applEnteredDate = application.applEnteredDate;
        filteredIntention.session = filteredIntentionObj.session;
        filteredIntention.applicationType = filteredIntentionObj.applicationType;

        if( finalIntentions.length ===0 ) {
          finalIntentions.push(filteredIntention);
        } else {
          let degreeProgramIdx = finalIntentions.findIndex( intention => {
            return intention.degreeProgramCd === filteredIntention.degreeProgramCd;
          });

          if(degreeProgramIdx === -1 )
          {
            finalIntentions.push(filteredIntention);
          }
        }
      }
    });

  } );
  return finalIntentions;
}

const getters = {
  applicantNotFound(state) {
    return state.applicantNotFound;
  },
  documentSummary(state) {
    return state.documentSummary;
  },
  documents(state) {
    return state.documents;
  },
  intentions(state) {
    return state.intentions;
  },
  isLoading(state) {
    return state.isLoading;
  },
  countries(state) {
    return state.countries;
  },
  supplementalDocCodes(state) {
    return state.supplementalDocCodes;
  },
  personalProfileDocTypeCodes(state) {
    return state.personalProfileDocTypeCodes;
  },
  applications(state) {
    return state.applications;
  },
  miscDocumentRules(state) {
    return state.miscDocumentRules;
  },
  ouacNumber(state) {
    if (!state) {
      return null;
    }
    if (!state.person) {
      return null;
    }
    if (!state.person.personBiographic) {
      return null;
    }

    return state.person.personBiographic.ouacIdentifier;
  },
  penNumber(state) {
    return state.person?.personBiographic?.bcPersonalEducationNumber;
  },
  ubcId(state) {
    return state.ubcId;
  },
  person(state) {
    return state.person;
  },
  personalProfileAnswers(state) {
    return state.personalProfileAnswers;
  },
  personSummary(state) {
    return state.personSummary;
  },
  showOfferPostPayment(state) {
    return state.showOfferPostPayment;
  },
  supplementalApplicationSummaries(state) {
    return state.supplementalApplicationSummaries;
  },
  termsOfUseAccepted(state) {
    return state.termsOfUseAccepted;
  },
  personalProfileCriteria(state) {
    return new PersonalProfileCriteria(
        getPersonalProfileAssociatedIntentions(),
      state.documentSummary,
      state.miscDocumentRules
    );
  },
  supplementalModalSummary(state) {
    return state.supplementalModalSummary;
  },
  personalProfileModalType(state) {
    return state.personalProfileModalType;
  },
  applicationFeesPaymentResponseDelay(state) {
    return state.applicationFeesPaymentResponseDelay;
  },
  acceptedOfferFeesPaymentResponseDelay(state) {
    return state.acceptedOfferFeesPaymentResponseDelay;
  },
  hasUndergraduateAdmissions(state) {
    let results = state.applications?.filter(
      (app) => app.admissProcArea === "ADMS" || app.admissProcArea === "EDUC"
    );
    return !!results?.length;
  },
  allProcessingAreas(state) {
    return state.allProcessingAreas;
  },
  uploadStatuses(state) {
    return state.uploadStatuses;
  },
  selectedIntentionGroup(state) {
    return state.selectedIntentionGroup;
  },
  onReloadIntentionsCloseModal(state) {
    return state.onReloadIntentionsCloseModal;
  },
  allVisas(state) {
    return state.allVisas.filter((visa) => {
      return visa.showOnApplication;
    });
  }
};

const actions = {
  loadApplicantSummary({ commit, dispatch }) {
    return api
      .getApplicantSummary()
      .then((response) => {
        commit("setApplicantSummary", response.data);
        commit("setApplicantNotFound", false);
        dispatch("loadPerson");
      })
      .catch((error) => {
        if (error && error === "Error: Request failed with status code 404") {
          commit("setApplicantNotFound", true);
        }
        dispatch("reset");
      });
  },
  loadDepositPaymentResponseDelayLists({ commit }) {
    return api
      .getDepositPaymentResponseDelayLists()
      .then((response) => {
        commit("setPaymentResponseDelayData", response.data);
      })
      .catch((error) => {
        console.log(
          "Error caught in loadDepositPaymentResponseDelayLists: " +
            JSON.stringify(error)
        );
      });
  },
  loadPerson({ commit }) {
    api
      .getPerson()
      .then((response) => {        
        if (response.data === '') {
          commit("setPerson", null);  
        } else {
          commit("setPerson", response.data);
        }
      })
      .catch((error) => {
        console.log("Error caught in loadPerson: " + error);
      });
  },
  loadDocumentSummary({ commit }) {
    api.getDocumentSummaries().then((response) => {
      commit("setDocumentSummary", response.data);
    });
  },
  loadIntentions({ commit }) {
    api.getIntentions().then((response) => {
      commit("setIntentions", response.data);
    });
  },
  loadReferenceData({ commit, dispatch }) {
    api
      .getReferenceData()
      .then((response) => {
        commit("setCountries", response.data.countries);
        commit(
          "setPersonalProfileDocTypeCodes",
          response.data.personalProfileDocTypeCodes
        );
        commit("setSupplementalDocCodes", response.data.supplementalDocCodes);
        commit("setAllProcessingAreas", response.data.allProcessingAreas);
        commit("setUploadStatuses", response.data.uploadStatuses);
        commit("setAllVisas", response.data.allVisas);
      })
      .catch((error) => {
        console.log("Error caught in loadReferenceData: " + error);
      });
  },
  getDeleteDocumentPromise({ commit }, submission) {
    var documentSummary = state.documentSummary;
    return new Promise((resolve, reject) => {
      let docGuid = submission.documentGuid;
      api
        .deleteDocument(docGuid)
        .then((response) => {
          for (var i = 0; i < documentSummary.length; i++) {
            if (documentSummary[i].documentGuid == docGuid) {
              documentSummary[i].uploadedDocument = null;
              break;
            }
          }
          commit("setDocumentSummary", documentSummary);
          resolve();
        })
        .catch((error) => {
          console.log(
            "Error caught in DeleteDocumentPromise: " + JSON.stringify(error)
          );
          reject(error);
        });
    });
  },
  getExternalLinkPromise({}, submission) {
    var linkType = submission.linkType;
    return new Promise((resolve, reject) => {
      api
        .getExternalLink(linkType)
        .then((response) => {
          if (response && response.data && response.data.url) {
            resolve(response.data.url);
          } else {
            resolve("");
          }
        })
        .catch((error) => {
          console.log(
            "Error caught in calling external link for linkType: " + linkType
          );
          console.log("Error is: " + JSON.stringify(error));
          reject(error);
        });
    });
  },
  getFinaliseDocumentPromise({ commit }, submission) {
    var documentSummary = state.documentSummary;
    return new Promise((resolve, reject) => {
      let docGuid = submission.documentGuid;
      api
        .finaliseDocument(docGuid)
        .then((response) => {
          for (var i = 0; i < documentSummary.length; i++) {
            if (documentSummary[i].documentGuid == docGuid) {
              if (documentSummary[i].uploadedDocument) {
                documentSummary[i].uploadAllowed = false;
                documentSummary[i].uploadedDocument.documentFileStatus =
                  "FINALISED";
                break;
              }
            }
          }
          commit("setDocumentSummary", documentSummary);
          resolve();
        })
        .catch((error) => {
          console.log(
            "Error caught in finaliseDocument: " + JSON.stringify(error)
          );
          reject(error);
        });
    });
  },
  getTermsOfUseMessagePromise() {
    return new Promise((resolve, reject) => {
      api
        .getTermsOfUseMessage()
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(
            "Error caught in getTermsOfUseMessagePromise: " +
              JSON.stringify(error)
          );
          reject(
            "We're experiencing technical difficulties - please try again later"
          );
        });
    });
  },
  getUpdateOfferStatusesPromise({ dispatch }, submission) {
    let offerStatuses = submission.offerStatuses;
    return new Promise((resolve, reject) => {
      api
        .updateOfferStatuses(offerStatuses)
        .then((response) => {
          dispatch("loadIntentions");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUploadFilePromise({ commit, dispatch }, submission) {
    return new Promise((resolve, reject) => {
      let docGuid = submission.documentGuid;
      let formData = submission.uploadedFile;
      let uploadedFile = formData.get("uploadedFile");
      let uploadedDoc = {
        documentGuid: docGuid,
        fileId: "TEMP",
        fileName: uploadedFile.name,
        documentFileStatus: "UPLOADED",
      };
      api
        .uploadDocument(docGuid, formData)
        .then((response) => {
          if (
            response.data &&
            response.data.uploadedDocument &&
            response.data.uploadedDocument.fileId
          ) {
            var docSummary = state.documentSummary;
            for (var i = 0; i < docSummary.length; i++) {
              if (docSummary[i].documentGuid == docGuid) {
                docSummary[i].uploadedDocument = response.data.uploadedDocument;
                docSummary[i].uploadAllowed = true;
                if (
                  typeof response.data.uploadedDocument.fileName == "undefined"
                ) {
                  docSummary[i].uploadedDocument.fileName = uploadedFile.name;
                }
                if (
                  typeof response.data.uploadedDocument.documentFileStatus ==
                  "undefined"
                ) {
                  docSummary[i].uploadedDocument.documentFileStatus =
                    "UPLOADED";
                }
              }
            }
            commit("setDocumentSummary", docSummary);
          } else {
            // Reload the applicant summary so we get the document list with updated fileId's.
            console.log(
              "Could not extract the file ID from the response, invoking loadApplicantSummary"
            );
            dispatch("loadApplicantSummary");
          }
          resolve(uploadedDoc);
        })
        .catch((error) => {
          console.log(
            "Error caught in applicant.uploadedDocument: " +
              JSON.stringify(error)
          );
          if (error.message) {
            console.log("Error.message: " + error.message);
          } else {
            console.log("Error.message is undefined");
          }
          if (error.response) {
            console.log("Error.response: " + error.response);
          } else {
            console.log("Error.response is undefined");
          }
          if (error.request) {
            console.log("Error.request: " + error.request);
          } else {
            console.log("Error.request is undefined");
          }
          reject(error);
        });
    });
  },

  getUpdatePersonPromise({ dispatch, commit }, submission) {
    return new Promise((resolve, reject) => {
      api
        .updatePerson(submission.personToEdit)
        .then((response) => {
          dispatch("loadPerson");
          resolve(response);
        })
        .catch((error) => {
          console.log(
            "API Put call for update person promise failed - error: " +
              JSON.stringify(error)
          );
          reject(error);
        });
    });
  },
  getUpdateOuacPromise({ commit }, submission) {
    let person = state.person;
    return new Promise((resolve, reject) => {
      let ouac = submission.ouac;
      api
        .updateOuacNumber(ouac)
        .then((response) => {
          if (person) {
            if (person.personBiographic) {
              person.personBiographic.ouacIdentifier = ouac;
            }
            commit("setPerson", person);
          }
          resolve();
        })
        .catch((error) => {
          console.log(
            "Error caught in UpdateOuacPromise: " + JSON.stringify(error)
          );
          reject(error);
        });
    });
  },
  getUpdatePenPromise({ dispatch, commit }, submission) {
    let person = state.person;
    return new Promise((resolve, reject) => {
      let penNumber = submission.penNumber;
      api
        .updatePenNumber(penNumber)
        .then((response) => {
          if (person) {
            if (person.personBiographic) {
              person.personBiographic.bcPersonalEducationNumber = penNumber;
            }
            commit("setPerson", person);
          }
          resolve();
        })
        .catch((error) => {
          console.log("Error caught in UpdatePenPromise: " + error);
          reject(error);
        });
    });
  },
  getUpdateTermsAndConditionsPromise({ commit }) {
    return new Promise((resolve, reject) => {
      // NOTE: The API call fails if the user has no student record
      // so set the termsOfUseAccepted to true regardless of
      // whether it gets persisted or not.
      commit("setTermsOfUseAccepted", true);
      api
        .setTermsOfUseAccepted()
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          console.log(
            "Error caught updating the Terms and Conditions: " + error
          );
          reject(error);
        });
    });
  },
  getUpdateAboriginalSelfIdentificationPromise({ commit }, submission) {
    let person = state.person;
    return new Promise((resolve, reject) => {
      let data = {
        aboriginal: submission.isAboriginal,
        firstNations: submission.firstNationStatus,
        inuit: submission.inuitStatus,
        metis: submission.metisStatus,
        firstNationsCommunity: submission.community,
      };
      api
        .UpdateAboriginalSelfIdentification(data)
        .then((response) => {
          if (person && person.personBiographic) {
            //To fix the Errror :-do not mutate vuex store state outside mutation handlers.
            commit("setAboriginalSelfIdentificationState", data);
          }
          resolve(response);
        })
        .catch((error) => {
          console.log(
            "API Put call for update AboriginalSelfIdentificationPromise failed - error: " +
              JSON.stringify(error)
          );
          reject(error);
        });
    });
  },
  reset({ commit }) {
    let applicantSummary = {};
    commit("setApplicantSummary", applicantSummary);
    commit("setPerson", null);
  },
  selectPersonalProfileModalType({ commit }, type) {
    commit("setPersonalProfileModalType", type);
  },
  selectSupplementalSummary({ commit }, id) {
    let supplementalApplicationSummary =
      state.supplementalApplicationSummaries.find(
        (supp) => supp.applicationId == id
      );
    commit("setSupplementalModalSummary", supplementalApplicationSummary);
  },
  setSelectedIntentionGroup({ commit }, val) {
    commit("setSelectedIntentionGroup", val);
  },
};

const mutations = {
  setApplicantNotFound(state, applicantNotFound) {
    state.applicantNotFound = applicantNotFound;
  },
  setApplicantSummary(state, applicantSummary) {
    state.applications = applicantSummary.applications;
    state.documentSummary = applicantSummary.documentSummary;
    state.intentions = applicantSummary.intentions;
    state.personalProfileAnswers = applicantSummary.personalProfileAnswers;
    state.personSummary = applicantSummary.personSummary;
    state.supplementalApplicationSummaries =
      applicantSummary.supplementalApplicationSummaries;
    state.termsOfUseAccepted = applicantSummary.termsOfUseAccepted;
    state.miscDocumentRules = applicantSummary.miscDocumentRules;
    state.ubcId = applicantSummary.personSummary?.ubcId;
    state.applicationFeesPaymentResponseDelay =
      applicantSummary.applicationFeesPaymentResponseDelay;
  },
  setPaymentResponseDelayData(state, data) {
    state.acceptedOfferFeesPaymentResponseDelay =
      data.acceptedOfferFeesPaymentResponseDelay;
  },
  setDocumentSummary(state, documentSummary) {
    state.documentSummary = documentSummary;
  },
  setIntentions(state, intentions) {
    state.intentions = intentions;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setSupplementalDocCodes(state, supplementalDocCodes) {
    state.supplementalDocCodes = supplementalDocCodes;
  },
  setPersonalProfileDocTypeCodes(state, personalProfileDocTypeCodes) {
    state.personalProfileDocTypeCodes = personalProfileDocTypeCodes;
  },
  setAboriginalSelfIdentificationState(state, aboriginalData) {
    if (state.person != null && state.person.personBiographic != null) {
      state.person.personBiographic.aboriginal = aboriginalData.aboriginal;
      state.person.personBiographic.firstNations = aboriginalData.firstNations;
      state.person.personBiographic.inuit = aboriginalData.inuit;
      state.person.personBiographic.metis = aboriginalData.metis;
      state.person.personBiographic.firstNationsCommunity =
        aboriginalData.firstNationsCommunity;
    }
  },
  setPerson(state, person) {
    state.person = person;
  },
  setShowOfferPostPayment(state, postPayment) {
    state.showOfferPostPayment = postPayment;
  },
  setTermsOfUseAccepted(state, termsOfUseAccepted) {
    state.termsOfUseAccepted = termsOfUseAccepted;
  },
  setSupplementalModalSummary(state, payload) {
    state.supplementalModalSummary = payload;
  },
  setPersonalProfileModalType(state, payload) {
    state.personalProfileModalType = payload;
  },
  setAllProcessingAreas(state, payload) {
    state.allProcessingAreas = payload;
  },
  setUploadStatuses(state, payload) {
    state.uploadStatuses = payload;
  },
  setSelectedIntentionGroup(state, payload) {
    state.selectedIntentionGroup = payload;
  },
  setOnReloadIntentionsCloseModal(state, payload) {
    state.onReloadIntentionsCloseModal = payload;
  },
  setAllVisas(state, payload) {
    state.allVisas = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
