<template>
  <div>
    <b-card>
      <b-card-title style="margin:20px;">
        Discover UBC
      </b-card-title>
      <b-card-body>
        <div>
          <b-card class="first-card">
            <b-card-header>
              <h4>Explore Housing</h4>
            </b-card-header>
            <b-card-body class="custom-bcard-body-margin">
              <a href="#" v-on:click.prevent="housingUrlAction">Explore housing options at UBC</a>
            </b-card-body>
          </b-card>
          <b-card class="next-card">
            <b-card-header>
              <h4>Financial Support</h4>
            </b-card-header>
            <b-card-body class="custom-bcard-body-margin">
              Learn about scholarships, awards, and other funding support
              <a target="_blank" href="https://you.ubc.ca/financial-planning/">https://you.ubc.ca/financial-planning/</a>
            </b-card-body>
          </b-card>
          <b-card class="next-card">
            <b-card-header>
              <h4>Take a Tour</h4>
            </b-card-header>
            <b-card-body class="custom-bcard-body-margin">
              Take a tour or attend an event
              <a target="_blank" href="https://you.ubc.ca/tours-events">https://you.ubc.ca/tours-events</a>
            </b-card-body>
          </b-card>
          <HaveQuestionsWidget class="next-card"/>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import HaveQuestionsWidget from './HaveQuestionsWidget.vue';
export default {
  name: "ExternalLinksCard",
  components: {
    HaveQuestionsWidget
  },
  methods: {
    async housingUrlAction() {
      await this.$store.dispatch('applicant/getExternalLinkPromise', {
        linkType: 'HOUSING'
      })
      .then((response) => {
        var housingUrl = response;
        if ((housingUrl) && (housingUrl.length > 0)) {
          window.open(housingUrl, '_blank');
        }
      })
      .catch((error) => {
        console.log("Error caught in ExternalLinksCard.housingUrlAction(): " + JSON.stringify(error));
      });
    }
  }
}
</script>
<style lang="css" scoped>
.card-body {
  padding: 0px!important;
}
.custom-bcard-body-margin {
  margin: 20px;
}
.first-card {
 margin: 20px;
}
.next-card {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
</style>