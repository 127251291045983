<template>
  <b-container fluid="true">
    <page-load-overlay :show="showOverlay">
      <h6>Program Selection</h6>
      <p>
        You may choose a maximum of two programs. Program choices may not be
        added or changed after the application deadline. Admission to a degree
        program does not guarantee entrance to a particular major or
        specialization.
      </p>
      <p>
        Individuals wishing to take UBC credit-based courses without pursuing a
        UBC degree, diploma, or certificate should visit
        https://student.ubc.ca/enrolment/courses/non-degree-studies
      </p>

      <b-alert :show="isError" variant="danger" dismissible>
        The following Errors have occurred: <br />
        <li v-for="(error, index) in this.savingError" :key="index">
          {{ error }}
        </li>
      </b-alert>
      <b-alert :show="validationError !== ''" variant="danger" dismissible>
        {{ validationError }}
      </b-alert>

      <ReAdmission-ProgramSelection-Form
        :value="choices[0]"
        index="0"
        @input="updateChoice($event)"
      />

      <template v-if="choices.length > 1">
        <ReAdmission-ProgramSelection-Form
          :value="choices[1]"
          index="1"
          @input="updateChoice($event)"
        />
      </template>

      <br />
      <b-button variant="primary" class="btn-size" @click="toggleSecondProgram">
        {{
          choices.length > 1 ? "Remove Second Program" : "Add Second Program"
        }}
      </b-button>
    </page-load-overlay>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import ReAdmissionProgramSelectionForm from "./ReAdmissionProgramSelectionForm.vue";
import PageLoadOverlay from "./PageLoadOverlay.vue";

export default {
  name: "ReAdmissionProgramSelection",
  components: {
    ReAdmissionProgramSelectionForm,
    PageLoadOverlay,
  },
  data() {
    return {
      validationError: "",
    };
  },
  computed: {
    ...mapGetters("reAdmission", ["choices", "savingError", "isBusy"]),
    isError() {
      return this.savingError != "";
    },
    showOverlay() {
      return this.isBusy;
    },
  },
  created() {
    this.initProgramSelectionData();
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    initProgramSelectionData() {
      this.$store.dispatch("reAdmission/loadProgramSelectionData");
    },
    toggleSecondProgram() {
      if (this.choices.length > 1) {
        this.$store.commit("reAdmission/removeChoice", 1);
      } else {
        let payload = {
          campus: "",
          degreeChoiceCode: "",
          degreeOptionChoiceCode: "",
          degreeOptionChoiceCode2: "",
          programVersion: "",
          session: "",
        };
        this.$store.commit("reAdmission/addNewChoice", payload);
      }
    },
    updateChoice(payload) {
      this.$store.commit("reAdmission/updateChoice", payload);
    },
    formValidation() {
      this.validationError = "";      
      this.v$.$touch();
      if (this.v$.$dirty && this.v$.$error) {
        return false;
      }
      if (this.choices.length > 1 && this.hasDuplicateChoices(this.choices)) {
        this.validationError =
          "Applicants are not able to select the same program for choice one and choice two. Please select a different program.";
        return false;
      }
      this.$store.commit("reAdmission/setIsBusy", true);

      this.$store
        .dispatch("reAdmission/saveReadmissionProgram", {
          choices: this.choices,
        })
        .then(() => {
          this.$store.commit("reAdmission/setIsBusy", false);
          if (!this.savingError) {
            this.$emit("forceToNext");
          }
        });
    },
    hasDuplicateChoices(choices) {
      return (
        choices[0].campus === choices[1].campus &&
        choices[0].degreeChoiceCode === choices[1].degreeChoiceCode &&
        choices[0].degreeOptionChoiceCode ===
          choices[1].degreeOptionChoiceCode &&
        choices[0].degreeOptionChoiceCode2 ===
          choices[1].degreeOptionChoiceCode2 &&
        choices[0].programVersion === choices[1].programVersion
      );
    },
  },
};
</script>

<style scoped>
</style>