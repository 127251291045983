<template>
  <b-modal
      v-model="showModal"
      title="Indigenous Self-Identification"
      centered
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      @hidden="close"
      size="lg"
  >
    <AboriginalSelfIdentificationComponent
        ref="AboriginalSelfIdentificationComponent"
        :aboriginalSubheader = false
    />
    <div v-if="showErrorMessageWidget">
      <ErrorMessageWidget
          :error="error"
          :source="ERROR_SOURCE.Person"
          @closeErrorMessage="closeErrorMessage" />
    </div>

    <template #modal-footer="selfIdentFooter">
      <b-alert
          class="center-alert"
          variant="success"
          :show="dismissCountDown"
          fade
          @dismiss-count-down="countDownChanged">
        Saved
      </b-alert>
      <b-button class="btn-size" variant="outline-secondary" @click="close" :disabled="isCommunicating">
        Close
      </b-button>
      <b-button variant="primary"
                class="btn-size" :disabled="isCommunicating" @click="update">
        <b-spinner small class="align-middle" v-if="isCommunicating"></b-spinner>
        <span v-else>Save</span>
      </b-button>

    </template>
  </b-modal>
</template>

<script>

import AboriginalSelfIdentificationComponent from "@/components/AboriginalSelfIdentificationComponent";


export default {
  name: 'AboriginalSelfIdentificationModal',
  components: { AboriginalSelfIdentificationComponent },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      isCommunicating: false,
      showModal: false,
      showErrorMessageWidget: false,
    }
  },
  methods: {
    close() {
      this.showModal = false;
      this.dismissCountDown = 0;
      this.$emit('closeModal');
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    show() {
      this.showModal = true;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    async update() {
      this.isCommunicating =  true;
      this.showMessage = true;

      this.$refs.AboriginalSelfIdentificationComponent.getDispatchPromise()
          .then((response) => {
            this.showAlert();
          })
          .catch((error) => {
            this.saveErrorMessage(error);
          }).finally(() => {
            this.isCommunicating =  false;
          });

    },

    saveErrorMessage(error) {
      // Need to know what kind of error this was
      // If it's a validation error add it to the array
      if (error.response.data.status == 400 && error.response.data.message == 'Validation error') {
        // This means it's a validation error
        for (var err of error.response.data.details) {
          this.validationErrors.push(err);
        }
      }
      if (this.validationErrors.length > 0) {
        return;
      }
      // If it's a communication / server error go to the error widget
      this.showErrorMessageWidget = true;
      this.error = error;
    },
    closeErrorMessage() {
      this.showErrorMessageWidget = false;
      this.error = null;
    }


  },
};
</script>
<style lang="css" scoped>
.center-alert {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}

</style>